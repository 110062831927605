import { Body, Header, Left, Right, Text } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";

import { useNavigation, useRoute } from "@react-navigation/native";

import { StyleConstants } from "../globalStyle";
import { isWeb } from "../globalUtils";
import { AppBackButton } from "./Buttons";

// Style for "Edit Event"
const styles = StyleSheet.create({
  title: {
    color: "#000000",
    fontSize: 21,
    fontWeight: "800",
    fontStyle: "normal",
    textAlign: "center",
    letterSpacing: isWeb ? "normal" : null,
    lineHeight: isWeb ? "normal" : null,
    fontFamily: StyleConstants.defaultTitleFont,
  },
});

export const onBackPressed = (navigation, route) => {
  switch (route.name) {
    case "EventEdit":
    case "CreateEvent":
      navigation.navigate("EventList");
      break;
    case "GuestList":

    case "GuestAdd":
    case "GuestImport":
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate("EventEdit", route.params);
      }
      break;
    case "GuestBook":
      navigation.navigate("EventEdit", route.params);
      break;
    case "UpdateEmail":
    case "BuyCredits":
    case "Subscribe":
    case "VideoWall":
    case "ForgotPassword":
    case "PasswordReset":
    case "ForgotPasswordLinkSent":
      // If signed in, then go to Profile
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate("Profile");
      }
      break;
    default:
      navigation.navigate("EventList");
  }
};

const AppHeader = ({
  title,
  hasLeftButton = true,
  leftButtonComponent,
  renderRightButton,
  style,
}) => {
  const navigation = useNavigation();
  const route = useRoute();

  const renderLeftButton = () => {
    return (
      leftButtonComponent ?? (
        <AppBackButton onPress={() => onBackPressed(navigation, route)} />
      )
    );
  };

  return (
    <Header
      transparent
      translucent
      style={[
        {
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        style,
      ]}
    >
      <Left
        style={{
          flex: 1,
        }}
      >
        {hasLeftButton && renderLeftButton()}
      </Left>
      {!!title && (
        <Body
          style={{
            flex: 1,
            alignItems: "center",
          }}
        >
          <Text style={styles.title}>{title}</Text>
        </Body>
      )}
      <Right
        style={{
          flex: 1,
        }}
      >
        {!!renderRightButton && renderRightButton()}
      </Right>
    </Header>
  );
};

export default AppHeader;
