import { useFormik } from "formik";
import React, { useImperativeHandle } from "react";
import { DeviceEventEmitter } from "react-native"
import { Toast } from "native-base"
import { useNavigation } from "@react-navigation/native";

import { inviteGuests } from "../http/events";
import { GuestInviteSchema } from "../screens/Schemas";
import AppInput from "../shared/AppInput";
import OnboardingButton from "../shared/OnboardingButton";

const AddGuestForm = ({ event }, ref) => {
  const navigation = useNavigation();

  const { slug } = event;

  const f = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: GuestInviteSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        // Create an event
        const res = await inviteGuests(event, [values]);
        // find id of added guest and push it back to list
        DeviceEventEmitter.emit("guest-add", { guest: {
          ...values,
          id: res?.guests?.find(e=>e.email===values.email)?.id
        } });
        navigation.navigate("GuestList", { slug });
      } catch (err) {
        actions.setSubmitting(false);
        Toast.show({
          text: String(err),
          position: 'top',
          type: 'danger',
          duration: 5000
        });
      }
    },
  });

  useImperativeHandle(ref, () => ({
    isReadyToSave: () => {
      return !!f.dirty && !f.isSubmitting;
    },
  }));

  return (
    <>
      <AppInput
        onChangeText={f.handleChange("name")}
        onBlur={f.handleBlur("name")}
        value={f.values.name}
        placeholder="Guest's Name *"
        onSubmitOnEnter={() => f.handleSubmit()}
        autoFocus
        errorMessage={f.errors.name && f.touched.name ? f.errors.name : null}
      />

      <AppInput
        onChangeText={f.handleChange("email")}
        onBlur={f.handleBlur("email")}
        value={f.values.email}
        placeholder="Email *"
        autoCorrect={false}
        spellCheck={false}
        onSubmitOnEnter={() => f.handleSubmit()}
        autoCapitalize="none"
        keyboardType="email-address"
        errorMessage={f.errors.email && f.touched.email ? f.errors.email : null}
      />

      <OnboardingButton
        onPress={f.handleSubmit}
        disabled={f.isSubmitting}
        text={"Send an Invitation"}
      />
    </>
  );
};

export default React.forwardRef(AddGuestForm);
