import * as Clipboard from "expo-clipboard";
import { Formik, Form } from "formik";
import { Left, ListItem, Right, Text, Toast } from "native-base";
import React, { useEffect } from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { updateEventBranding } from "../http/events";
import { PrivacySchema } from "../screens/Schemas";
import AppInput from "./AppInput";
import { TextLink } from "./TextLink";
import AppRadio from "./AppRadio";
import AppSwitch from "./AppSwitch";
import AutoSave from "./AutoSave";
import FormSubtitle from "./FormSubtitle";
import OnboardingButton from "../shared/OnboardingButton";
import { confirmAction } from "./confirmAction";
import { LogoPreviewAndUploader } from "./UpdateProfileForm";

const EventBrandingForm = ({ event }) => {
  const {
    slug,
    broadcaster,
    description,
    logo_path,
    company_name,
    company_url,
    custom_date_display,
  } = event;

  const navigation = useNavigation();

  return (
    <Formik
      initialValues={{
        description,
        logo_path,
        company_name,
        company_url,
        custom_date_display,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await updateEventBranding({ slug, ...values });
        } catch (err) {
          console.error(err);
          throw new Error("Could not update branding settings");
        }
        actions.setSubmitting(false);
      }}
      validationSchema={PrivacySchema}
    >
      {(f) => (
        <View style={{ paddingVertical: 40 }}>
          <AutoSave {...f} />
          <AppInput
            textarea
            multiline
            rowSpan={5}
            onChangeText={f.handleChange("description")}
            onBlur={f.handleBlur("description")}
            value={f.values.description}
            placeholder="Event Description (supports markdown)"
            inputStyle={{
              paddingTop: 10,
            }}
            style={{}}
            placeholderTextColor="#787878"
            errorMessage={
              f.errors.description && f.touched.description
                ? f.errors.description
                : null
            }
          />

          <AppInput
            onChangeText={f.handleChange("custom_date_display")}
            onBlur={f.handleBlur("custom_date_display")}
            value={f.values.custom_date_display}
            placeholder="Custom Display for Event Date (i.e. 7pm ET, 4pm PT)"
            onSubmitOnEnter={() => f.handleSubmit()}
            errorMessage={
              f.errors.custom_date_display && f.touched.custom_date_display
                ? f.errors.custom_date_display
                : null
            }
          />

          <AppInput
            onChangeText={f.handleChange("company_name")}
            onBlur={f.handleBlur("company_name")}
            value={f.values.company_name}
            placeholder="Company Name"
            onSubmitOnEnter={() => f.handleSubmit()}
            errorMessage={
              f.errors.company_name && f.touched.company_name
                ? f.errors.company_name
                : null
            }
          />

          <AppInput
            onChangeText={f.handleChange("company_url")}
            onBlur={f.handleBlur("company_url")}
            value={f.values.company_url}
            placeholder="Website (optional)"
            onSubmitOnEnter={() => f.handleSubmit()}
            errorMessage={
              f.errors.company_url && f.touched.company_url
                ? f.errors.company_url
                : null
            }
          />

          <View style={{ marginVertical: 40 }}>
            <LogoPreviewAndUploader
              logo_path={f.values.logo_path}
              path={`${broadcaster}/${slug}`}
              disclaimer="Logo will show on this Event Page Only. Please upload PNG or JPG file. The file will be trimmed and resized automatically. Horizontal logos work best. If using white on transparent logo, you might need to change the color of the background or the logo in your file."
              onComplete={async (logo_path) => {
                await f.setFieldValue("logo_path", logo_path);
              }}
              onDelete={async () => {
                console.log("removed");
                await f.setFieldValue("logo_path", null);
              }}
            />
          </View>
        </View>
      )}
    </Formik>
  );
};

export default EventBrandingForm;
