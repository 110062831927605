// @flow

import { PLATFORM } from "./../variables/commonColor";
import variable from "./../variables/platform";

export default (variables /* : * */ = variable) => {
  const platform = variables.platform;

  const segmentTheme = {
    height: 40,
    borderColor: variables.segmentBorderColorMain,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: variables.segmentBackgroundColor,
    borderRadius: 13,
    paddingHorizontal: 3,
    // flex: 1,
    "NativeBase.Button": {
      flex: 1,
      alignSelf: "center",
      borderRadius: 12,
      paddingTop: 3,
      paddingBottom: 3,

      height: 34,
      backgroundColor: "transparent",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderColor: variables.segmentBorderColor,
      elevation: 0,
      ".active": {
        backgroundColor: variables.segmentActiveBackgroundColor,
        "NativeBase.Text": {
          color: variables.segmentActiveTextColor,
          textAlign: "center",
        },
        "NativeBase.Icon": {
          color: variables.segmentActiveTextColor,
        },
      },
      // ".first": {
      //   borderTopLeftRadius: platform === PLATFORM.IOS ? 5 : undefined,
      //   borderBottomLeftRadius: platform === PLATFORM.IOS ? 5 : undefined,
      //   borderLeftWidth: 1,
      // },
      // ".last": {
      //   borderTopRightRadius: platform === PLATFORM.IOS ? 5 : undefined,
      //   borderBottomRightRadius: platform === PLATFORM.IOS ? 5 : undefined,
      // },
      "NativeBase.Text": {
        color: variables.segmentTextColor,
        fontSize: 14,
        textAlign: "center",
        flex: 1,
        padding: 0,
        // paddingHorizontal: 38,
      },
      "NativeBase.Icon": {
        fontSize: 22,
        paddingTop: 0,
        color: variables.segmentTextColor,
      },
    },
  };

  return segmentTheme;
};
