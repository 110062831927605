import { Button, Container, Text } from "native-base";
import React, { useState } from "react";
import { ActivityIndicator, DeviceEventEmitter, View } from "react-native";

import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import DashboardContent from "../../shared/DashboardContent";
import UpdateProfileForm from "../../shared/UpdateProfileForm";

const UpdateEmail = ({ navigation, route }) => {
  const [loading, setloading] = useState(false);
  const [readyToSave, setreadyToSave] = useState(false);

  const {
    email,
    account_type,
    first_name,
    last_name,
    broadcaster_role,
    role_other,
    company_name,
    logo_path,
    company_url,
    no_branding,
    refetchProfile,
    profileLoaded,
    broadcaster
  } = useAuth();

  console.log(account_type);
  console.log(first_name);

  return (
    <Container>
      <AppHeader />
      <DashboardContent>
        {loading && <ActivityIndicator />}
        {!loading && (
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              marginHorizontal: 44,
              maxWidth: 600,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <View style={{ marginHorizontal: 16 }}>
              {profileLoaded && (
                <UpdateProfileForm
                  profile={{
                    email,
                    account_type,
                    first_name,
                    last_name,
                    broadcaster_role,
                    role_other,
                    company_name,
                    company_url,
                    no_branding,
                  }}
                  logo_path={logo_path}
                  broadcaster={broadcaster}
                  refetchProfile={refetchProfile}
                />
              )}
            </View>
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default UpdateEmail;
