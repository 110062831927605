import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Radio, Text } from "native-base";
import { StyleConstants } from "../globalStyle";
import { Ionicons, FontAwesome5 } from "@expo/vector-icons";

const style = StyleSheet.create({
  radio: {
    flexDirection: "row",
    width: "100%",
    paddingVertical: 11,
    height: 52,
    borderRadius: 16,
  },
  radioLabel: {
    flex: 1,
    flexGrow: 1,
    marginLeft: 21,
    padding: 2,
  },
  radioLabelWithIcon: {
    flex: 1,
    flexGrow: 1,
    marginLeft: 21,
    padding: 2,
    paddingTop: 7,
  },
  radioElement: {
    flex: 0,
    width: 21,
    marginRight: 11,
  },
});

const AppRadio = ({ label, selected, onSelect, icon, highlight }) => (
  <Pressable
    style={[
      style.radio,
      highlight && selected
        ? { borderColor: "black", borderWidth: 0.5, backgroundColor: "white" }
        : undefined,
    ]}
    onPress={onSelect}
  >
    {!icon && (
      <Radio
        color={StyleConstants.defaultBrandGrayLight}
        selected={selected}
        style={[style.radioElement, ...(highlight ? [{ marginLeft: 20 }] : [])]}
        onPress={onSelect}
      />
    )}

    {icon && <Ionicons name={icon} size={30} style={{ marginLeft: 20 }} />}
    <Text style={icon ? style.radioLabelWithIcon : style.radioLabel}>
      {label}
    </Text>
  </Pressable>
);

export default AppRadio;
