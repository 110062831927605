import { Text } from "native-base";
import React from "react";
import { Pressable, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  appSubtitle: {
    margin: 2,
    paddingTop: 8,
    paddingBottom: 56,
    textAlign: "left",
  },
});

const AppSubtitle = ({ style, text, onPress }) => {
  return onPress ? (
    <Pressable onPress={onPress}>
      <Text style={[styles.appSubtitle, style]}>{text}</Text>
    </Pressable>
  ) : (
    <Text style={[styles.appSubtitle, style]}>{text}</Text>
  );
};

export default AppSubtitle;
