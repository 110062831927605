import { H1, H2, H3, H4, H5 } from "native-base";
import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { StyleConstants } from "../../components/globalStyle";
import variables from "../../native-base-theme/variables/platform";

const styles = StyleSheet.create({
  titleTextStyles: {
    fontFamily: StyleConstants.defaultTitleFont,
    fontWeight: "800",
    color: "#2E3F52",
  },
});

const AppTitle = ({
  text,
  h1,
  h2,
  h3,
  h4,
  h5,
  onPress,
  style,
  containerStyle,
}) => {
  const WrapOn = ({ style }) => {
    if (h1) {
      return <H1 style={style}>{text}</H1>;
    } else if (h2) {
      return <H2 style={style}>{text}</H2>;
    } else if (h3) {
      return <H3 style={style}>{text}</H3>;
    } else if (h4) {
      return (
        <H3
          style={[
            {
              color: variables.textColor,
              fontSize: variables.fontSizeH4,
              lineHeight: variables.lineHeightH4,
            },
            style,
          ]}
        >
          {text}
        </H3>
      );
    } else if (h5) {
      return (
        <H3
          style={[
            {
              color: variables.textColor,
              fontSize: variables.fontSizeH5,
              lineHeight: variables.lineHeightH5,
            },
            style,
          ]}
        >
          {text}
        </H3>
      );
    } else {
      return <H1 style={style}>{text}</H1>;
    }
  };
  return onPress ? (
    <Pressable style={containerStyle} onPress={onPress}>
      <WrapOn style={[styles.titleTextStyles, style]} />
    </Pressable>
  ) : (
    <WrapOn style={[styles.titleTextStyles, style]} />
  );
};

export default AppTitle;
