import * as React from "react"
import Svg, { Circle, Ellipse } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={291}
      height={231}
      viewBox="0 0 291 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle
        cx={226.64}
        cy={155.64}
        r={45}
        transform="rotate(45 226.64 155.64)"
        fill="#F84123"
      />
      <Ellipse
        cx={42.2055}
        cy={16.1651}
        rx={42.2055}
        ry={16.1651}
        transform="matrix(-.82504 .56508 -.66954 -.74277 91.289 183.014)"
        fill="#CFE1F4"
      />
      <Ellipse
        cx={125.365}
        cy={68.5866}
        rx={11.5424}
        ry={14.8317}
        transform="rotate(-150.623 125.365 68.587)"
        fill="#F4553B"
      />
      <Ellipse
        cx={254.556}
        cy={22.4878}
        rx={15.0464}
        ry={19.3342}
        transform="rotate(66.16 254.556 22.488)"
        fill="#CFE1F4"
      />
    </Svg>
  )
}

export default SvgComponent
