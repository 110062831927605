export default {
  REACT_APP_IDENTITY_POOL_ID: "us-east-1:3ea9d735-d975-4d9f-8900-872336248c62",
  REACT_APP_REGION: "us-east-1",
  REACT_APP_USERPOOL_ID: "us-east-1_WIhGpkAKb",
  REACT_APP_CLIENT_ID: "7ife0rosf451820rp4da6ms5it",
  REACT_APP_GRAPHQL_ENDPOINT:
    "https://ki442ck2azf2hkxbdlflllod64.appsync-api.us-east-1.amazonaws.com/graphql",
  REACT_APP_COVER_BUCKET: "eventliveprod-posters",
  REACT_APP_LOGO_BUCKET: "eventliveprod-logos",
  REACT_APP_FILEUPLOAD_BUCKET: "eventliveprod-fileupload",
  REACT_APP_VOD_BUCKET: "eventliveprod-vod",
  REACT_APP_COGNITO_DOMAIN: "eventlive.auth.us-east-1.amazoncognito.com",
  REACT_APP_REDIRECT_URL: "https://localhost:3000/", 
  REACT_APP_GOOGLE_MAPS_KEY: "AIzaSyCA_6J_QqMopvHj_rvYQwwjP9glP6FY2VA",
  PRIVACY_URL:
    "https://www.privacypolicies.com/privacy/view/2db494ffab28ddf23255164573accb4f",
  TERMS_URL: "https://eventlive.pro/terms",
};
