import React from "react";
import {
  Modal,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { isWeb, minDesktopWidth } from "../globalUtils";
import { CloseIcon } from "./Buttons";

const AppModal = ({
  visible,
  onRequestClose,
  children,
  maxWidth = 440,
  maxHeight = 550,
  viewStyle,
  presentationStyle,
}) => {
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: isWeb && !isMobileUX ? "center" : "flex-end",
      alignItems: "center",
      flexGrow: 1,
      alignSelf: "center",
      width: responsiveWidth,
      backgroundColor: "rgba(151,151,151,0.45)",
    },
    modalView: {
      flex: 1,
      maxHeight,
      width: responsiveWidth,
      maxWidth,
      margin: 20,
      marginBottom: isWeb && !isMobileUX ? 35 : -14,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
  });

  return (
    <>
      <Modal
        animationType="slide"
        style={{ borderWidth: 0, borderColor: "none" }}
        transparent={true}
        visible={visible}
        onRequestClose={onRequestClose}
      >
        <TouchableOpacity
          style={styles.centeredView}
          onPress={onRequestClose}
          onStartShouldSetResponder={(event) => true}
        >
          <TouchableOpacity
            onPress={() => {}}
            activeOpacity={1}
            style={[styles.modalView, viewStyle]}
          >
            <Pressable
              onPress={() => {
                console.log("CLOSE");
                onRequestClose(!visible);
              }}
              style={{
                width: 32,
                position: "absolute",
                top: 4,
                right: 4,
                paddingTop: 12,
              }}
            >
              <CloseIcon />
            </Pressable>
            {children}
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>
    </>
  );
};

export default AppModal;
