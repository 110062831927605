// @flow

import { Platform } from "react-native";

import { PLATFORM } from "./../variables/commonColor";
import variable from "./../variables/platform";

export default (variables /* : * */ = variable) => {
  const radioTheme = {
    ".selected": {
      "NativeBase.IconNB": {
        color: "#F84123",
        // Platform.OS === PLATFORM.IOS
        //   ? variables.radioColor
        //   : variables.radioSelectedColorAndroid,
        lineHeight:
          Platform.OS === PLATFORM.IOS ? 25 : variables.radioBtnLineHeight,
        height: Platform.OS === PLATFORM.IOS ? 20 : undefined,
      },
    },
    "NativeBase.IconNB": {
      color: Platform.OS === PLATFORM.IOS ? "transparent" : undefined,
      lineHeight:
        Platform.OS === PLATFORM.IOS ? undefined : variables.radioBtnLineHeight,
      fontSize:
        Platform.OS === PLATFORM.IOS ? undefined : variables.radioBtnSize,
    },
  };

  return radioTheme;
};
