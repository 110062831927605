import { Text } from "native-base";
import React from "react";
import { Pressable, StyleSheet } from "react-native";


const styles = StyleSheet.create({
  link: {
    color: "#e94a23",
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "left",
    letterSpacing: -0.2,
    lineHeight: 15,
    marginTop: 20
  },
})

const Link = ({ style, children, onPress }) => {
  return (
    <Pressable onPress={onPress} style={style}>
      <Text style={styles.link}>
        {children}
      </Text>
    </Pressable>
  );
};

export default Link;
