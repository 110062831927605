import { Text, Root, Button, Card, Icon, CardItem, Body } from "native-base";
import React, { useEffect, useState, useCallback } from "react";
import {
  Image,
  View,
  Linking,
  Platform,
  StyleSheet,
  Pressable,
} from "react-native";

import { useAuth } from "../AuthContext";
import { TextLink } from "./TextLink";
import { useNavigation } from "@react-navigation/native";
import { formatEventDateOnly, isWeb } from "../globalUtils";
import { LargeUnlockButton } from "./Buttons";
import { useBuy } from "../BuyContext";
import AppTitle from "./AppTitle";
import FeaturesList from "./FeaturesList";
import reviews from "@/components/reviews.json";
import faqs from "@/components/faqs.json";
import analytics from "@/analytics";

const styles = StyleSheet.create({
  container: {
    padding: 10,
    alignItems: "center",
  },
  card: {
    borderRadius: 10,
    backgroundColor: "#f0f0f0",
    borderWidth: 0,
    elevation: 0,
  },
  cardItem: {
    justifyContent: "center",
    alignItems: "center",
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
  },
  star: {
    color: "gold",
    fontSize: 22,
    padding: 5,
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    paddingVertical: 10,
  },
  faqtitle: {
    fontWeight: "bold",
    textAlign: "left",
    paddingVertical: 10,
  },
  reviewText: {
    marginVertical: 10,
    textAlign: "center",
    fontSize: 22,
    lineHeight: 24,
  },
  author: {
    textAlign: "center",
    paddingVertical: 0,
    fontStyle: "italic",
  },
  date: {
    textAlign: "center",
    paddingVertical: 0,
  },
});

const ReviewView = () => {
  const currentReview = reviews[Math.floor(Math.random() * reviews.length)];

  return <Review {...currentReview} />;
};

export const Review = ({ rating = 5, title, text, author, date }) => (
  <View
    style={{
      backgroundColor: "rgba(239, 239, 239, 0.5)",
      borderRadius: 16,
      padding: 20,
      marginVertical: 20,
    }}
  >
    <View style={styles.ratingContainer}>
      {[...Array(rating)].map((_, i) => (
        <Icon key={i} name="star" type="FontAwesome" style={styles.star} />
      ))}
    </View>
    {/* <Text style={styles.title}>{title}</Text> */}
    <Text style={styles.reviewText}>{'"' + text + '"'}</Text>
    <Text style={styles.author}>
      {author}, {date}
    </Text>
    <Text style={styles.date}></Text>
  </View>
);

const PayWall = ({ event }) => {
  const { account_type, legacyPricing } = useAuth();
  const { purchase, getBuyNowText } = useBuy();

  const navigation = useNavigation();

  const {
    title,
    slug,
    days,
    days_paid,
    event_timezone,
    event_datetime_utc,
    is_published,
  } = event;

  const eventDate = formatEventDateOnly(event_datetime_utc, event_timezone);
  const daysToPay = is_published ? 0 : days - days_paid;

  useEffect(() => {
    if (is_published) {
      navigation.navigate("EventEdit", { event, slug: event.slug });
    }
  }, [is_published]);

  const productId = legacyPricing ? "specialevent50" : "specialeventnew";

  const buy = async () => {
    await purchase({
      productId,
      slug,
      title,
      quantity: daysToPay,
    });
  };

  return (
    <View
      style={{ marginHorizontal: 12, display: "flex", flexDirection: "column" }}
    >
      <AppTitle
        h2
        style={{ textAlign: "center", paddingTop: 8 }}
        text={
          account_type === "business"
            ? `Share the link!`
            : `Invite your guests today!`
        }
      />

      <Text style={{ textAlign: "center", paddingTop: 20 }}>
        Buy Now, Stream on {eventDate}
      </Text>

      <FeaturesList />

      <View>
        <LargeUnlockButton
          onPress={buy}
          style={{ backgroundColor: `#f4553b` }}
          text={getBuyNowText(productId, days, days_paid)}
          icon={Platform.OS === "web" ? `card-outline` : null}
        />

        {account_type === "business" && (
          <TextLink
            onPress={() => {
              navigation.navigate("BuyCredits");
            }}
            text="Buy multiple and Save"
            textStyle={{
              padding: 20,
              textAlign: "center",
              width: "100%",
            }}
          />
        )}
      </View>

      <ReviewView />

      <View>
        {faqs.map((faq, index) => (
          <FAQItem key={index} faq={faq} />
        ))}
      </View>
      <View style={{ paddingTop: 20, paddingBottom: 40 }}>
        <LargeUnlockButton
          onPress={buy}
          style={{ backgroundColor: `#f4553b` }}
          text={getBuyNowText(productId, days, days_paid)}
          icon={Platform.OS === "web" ? `card-outline` : null}
        />
      </View>
    </View>
  );
};

const FAQItem = ({ faq }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    analytics.track("FAQ View", { title: faq.title });
  };

  return (
    <View key={faq.title}>
      <Pressable onPress={toggleVisibility}>
        <Text style={styles.faqtitle}>{faq.title}</Text>
      </Pressable>
      {isVisible && <Text style={{ marginBottom: 20 }}>{faq.content}</Text>}
    </View>
  );
};

export default PayWall;
