declare global {
  interface Window {
    analytics: any;
    $crisp: any;
  }
}

const analytics = {
  track: (event: string, properties: any) => {
    window.analytics.track(event, properties);
    try {
      window.plausible(event, { props: properties });
    } catch (err) {}
  },
  page: (category: string) => {
    window.analytics.page(category);
  },
  identify: (id, attributes) => {
    window.analytics.identify(id, attributes);
  },
  identifyAnon: (attributes) => {
    window.analytics.identify(attributes);
  },
  reset: () => {
    window.analytics.reset();
  },
};

window.analytics.on("identify", function (event, properties, options) {
  try {
    if (properties.email) {
      window.$crisp.push(["set", "user:email", properties.email]);
    }

    if (properties.name) {
      window.$crisp.push(["set", "user:nickname", properties.name]);
    }

    const crispData = Object.keys(properties).map((key) => [
      String(key),
      properties[key],
    ]);
    window.$crisp.push(["set", "session:data", [crispData]]);
  } catch (err) {
    console.error(err);
  }
});

export default analytics;
