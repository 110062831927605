import React from "react";

import { Button, Left, ListItem, Right, Text } from "native-base";

import { DeleteGuestIcon } from "./Buttons";

const GuestListItem = ({ guest, onDelete }) => {
  return (
    <ListItem key={guest.id || guest.email}>
      <Left>
        <Button transparent onPress={onDelete} style={{ marginRight: 16 }}>
          <DeleteGuestIcon />
        </Button>
        <Text>
          {guest.name} {guest.email}
        </Text>
      </Left>
      <Right style={{ flex: 1 }}>
        {!!guest.status && (
          <Text
            ellipsizeMode={"head"}
            numberOfLines={1}
            style={{
              backgroundColor: guest.color,
              padding: 3,
              borderRadius: 5,
              color: "white",
              fontSize: 12,
            }}
          >
            {guest.status}
          </Text>
        )}
      </Right>
    </ListItem>
  );
};

export default GuestListItem;
