import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { Text, Icon } from "native-base";
import { minDesktopWidth } from "@/components/globalUtils";

const FeaturesList = () => {
  const features = [
    {
      icon: "sun",
      description: "All-Day HD broadcasting",
      benefits:
        "Pause and Resume stream to the same link. Immersive audience experience.",
    },
    {
      icon: "download",
      description: "Download and Replay for 1 Year",
      benefits:
        "Automatically saved in high resolution. Download any time after.",
    },
    {
      icon: "users",
      description: "Unlimited guests",
      benefits:
        "Bring in as many viewers as you like, without any restrictions.",
    },
    {
      icon: "bell",
      description: "Reminders for guests",
      benefits: "Timely reminders, so no one misses out.",
    },
    {
      icon: "dollar-sign",
      description: "One-time Payment, Money Back Guarantee",
      benefits:
        "Excellent customer support. Instant refund if you're not satisfied",
    },
  ];

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  return (
    <View style={styles.container}>
      {features.map((feature, index) => (
        <View key={index} style={styles.featureItem}>
          <Icon
            name={feature.icon}
            type="FontAwesome5"
            style={{
              color: "#F84123",
              marginRight: 10,
              width: 38,
              textAlign: "center",
            }}
          />
          <View style={styles.textContent}>
            <Text style={styles.featureText}>{feature.description}</Text>
            {!isMobileUX && (
              <Text style={styles.benefitText}>{feature.benefits}</Text>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
  },
  featureItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  textContent: {
    flex: 1,
    justifyContent: "center",
  },
  featureText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  benefitText: {
    marginTop: 4,
    fontSize: 14,
  },
});

export default FeaturesList;
