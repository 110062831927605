import { Container, Content, Text } from "native-base";
import React, { useRef, useState } from "react";
import { Dimensions, Image, Platform, Pressable, View } from "react-native";

import AppTitle from "@/components/shared/AppTitle";
import NewTestButton from "@/components/shared/NewTestButton";
import OnboardingButton from "@/components/shared/OnboardingButton";
import Shapes1 from "@/assets/Shapes-ready_to_plan.jsx";
import Shapes2 from "@/assets/Shapes-test_for_free.jsx";
import { Ionicons } from "@expo/vector-icons";

const LetsSchedule = ({ navigation, route }) => {
  return (
    <Container>
      <Content
        contentContainerStyle={{
          alignContent: "space-around",
          flexGrow: 1,
          flex: 1,
          alignSelf: "center",
          maxWidth: 1294,
          width: "100%",
          padding: 16,
        }}
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            alignContent: "flex-end",
            alignItems: "flex-end",
            flexGrow: 0,
          }}
        >
          <Ionicons
            name="person-outline"
            size={24}
            color="#3f5b7a"
            style={{ padding: 4 }}
            onPress={() => {
              navigation.navigate("Profile");
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            alignContent: "flex-end",
            backgroundColor: "#F8F5FD",
            borderRadius: 32,
            padding: 40,
          }}
        >
          <Shapes1
            style={{
              position: "absolute",
              width: "90%",
              height: "50%",
              margin: "auto",
            }}
          />
          <View
            style={{ position: "absolute", bottom: 24, right: 24, left: 24 }}
          >
            <AppTitle text={"Ready to plan your event?"} />
            <OnboardingButton
              onPress={() => {
                navigation.navigate("CreateEvent");
              }}
              text={"Schedule your event"}
            />
          </View>
        </View>
        <View
          style={{ flexGrow: 0, padding: 24, marginTop: 20, height: "35%" }}
        >
          <Shapes2
            style={{
              position: "absolute",
              width: "25%",
              height: "75%",
              right: 0,
            }}
          />
          <View
            style={{
              position: "absolute",
              bottom: 24,
              right: 24,
              left: 24,
            }}
          >
            <AppTitle text={"Test for free"} />
            {/* <Text>Try our streaming</Text> */}
            <NewTestButton
              style={{
                marginTop: 20,
                marginBottom: 10,

                height: 62,
              }}
            />
            {/* <OnboardingButton dark onPress={() => {}} text={"Test Streaming"} /> */}
          </View>
        </View>
      </Content>
    </Container>
  );
};

export default LetsSchedule;
