import { Text } from "native-base";
import React from "react";
import { Pressable } from "react-native";
import GlobalStyles from "../globalStyle";

export const TextLink = ({ text, onPress, style, textStyle }) => {
  return (
    <Pressable onPress={onPress} role="link" style={style}>
      <Text
        multiline={false}
        style={[
          GlobalStyles.textBrandedHeavy,
          { fontWeight: "bold" },
          textStyle,
        ]}
      >
        {text}
      </Text>
    </Pressable>
  );
};
