import * as React from "react";
import Svg, { Ellipse } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      width={103}
      height={137}
      viewBox="0 0 103 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Ellipse
        cx={42.2055}
        cy={16.1651}
        rx={42.2055}
        ry={16.1651}
        transform="matrix(-.95167 .3071 -.43066 -.90252 94.772 111.041)"
        fill="#CFE1F4"
      />
      <Ellipse cx={20.5} cy={103.453} rx={12.5} ry={10.896} fill="#F84123" />
      <Ellipse
        cx={24.8582}
        cy={11.3124}
        rx={24.8582}
        ry={11.3124}
        transform="matrix(.75386 .65703 -.75377 .65714 65.054 .681)"
        fill="#F84123"
      />
    </Svg>
  );
}

export default SvgComponent;
