import React, { useEffect, useState } from "react";
import AppleSignin from "react-apple-signin-auth";
import { Ionicons } from "@expo/vector-icons";

const SIWA = ({
  style,
  isSignIn = false,
  full = false,
  onSuccess = () => {},
}) => {
  const onReturn = async (response) => {
    console.log(response);
    onSuccess({
      identityToken: response.authorization.id_token,
      fullName: {
        givenName: response.user?.firstName,
        familyName: response.user?.lastName,
      },
    });
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        clientId: "pro.eventlive.services",
        //   redirectURI: 'https://broadcaster.eventlive.pro/',
        redirectURI:
          `https://${document.location.host}` ||
          "https://broadcaster.eventlive.pro",
        scope: "email name",
        state: "state",
        /** sha256 nonce before sending to apple to unify with native firebase behavior - https://github.com/invertase/react-native-apple-authentication/issues/28 */
        //   nonce: sha256('nonce'),
        /** We have to usePopup since we need clientSide authentication */
        usePopup: true,
      }}
      onError={(error) => console.error(error)}
      onSuccess={onReturn}
      uiType="light"
      buttonExtraChildren="Continue with Apple"
      noDefaultStyle={false}
      style={{ borderRadius: 16, height: 62 }}
      // style={{
      //   height: 62,
      //   borderWidth: 0,
      //   borderRadius: 5,
      //   backgroundColor: "#f8f8f8",
      //   ...style,
      // }}
      // render={(props) => (
      //   <button {...props}>
      //     <Ionicons
      //       name="md-logo-apple"
      //       size={24}
      //       style={{ alignSelf: "center" }}
      //     />
      //     {full && <span>Continue with Apple</span>}
      //   </button>
      // )}
    />
  );
};

export default SIWA;
