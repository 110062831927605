import { useEffect, useState } from 'react';
import moment from "moment-timezone";


export default function useNow() {
  const [now, setnow] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setnow(moment());
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return now;
}