import * as WebBrowser from "expo-web-browser";
import { Text } from "native-base";
import React from "react";
import { View } from "react-native";
import AppConfig from "../../AppConfig";
import GlobalStyles from "../globalStyle";

export const SignUpPrivacy = ({}) => {
  const openLink = async (url) => {
    await WebBrowser.openBrowserAsync(url);
  };

  const openTermsUrl = () => {
    openLink(AppConfig.TERMS_URL);
  };
  const openPrivacyUrl = () => {
    openLink(AppConfig.PRIVACY_URL);
  };

  return (
    <View style={GlobalStyles.disclaimerContainer}>
      <Text style={GlobalStyles.textSmall}>
        By continuing you agree to our{" "}
        <Text
          style={[
            GlobalStyles.textSmall,
            { fontWeight: "bold" }, //, textDecorationLine: "underline"
          ]}
          accessibilityRole="link"
          onPress={openPrivacyUrl}
        >
          privacy policy
        </Text>{" "}
        and{" "}
        <Text
          style={[GlobalStyles.textSmall, { fontWeight: "bold" }]}
          accessibilityRole="link"
          onPress={openTermsUrl}
        >
          terms
        </Text>
      </Text>
    </View>
  );
};
