import * as Sharing from "expo-sharing";
import React, { useState } from "react";
import { isWindows } from "react-device-detect";
import { Platform, PlatformColor, Share } from "react-native";

import { isWeb } from "../globalUtils";
import SocialIconsModal from "./SocialIconsModal";
import OnboardingButton from "./OnboardingButton";
import analytics from "../../analytics";
import useSharePress from "../hooks/useSharePress";

const ShareEventButton = ({ style, title, url, text = "Share", ...props }) => {
  // const [socialIconsVisible, setsocialIconsVisible] = useState(false);

  const shareSubject = `You're invited to watch ${title}`;
  const { sharePress, ShareModal } = useSharePress(shareSubject, url);

  // const sharePress = async () => {
  //   // Analytics track
  //   analytics.track("Share Link", {
  //     url,
  //     title,
  //   });

  //   if (isWeb && (isWindows || !(await Sharing.isAvailableAsync()))) {
  //     setsocialIconsVisible(true);
  //   } else {
  //     try {
  //       if (Platform.OS === "ios") {
  //         await Share.share(
  //           {
  //             title: url,
  //             url: url,
  //           },
  //           {
  //             subject: shareSubject,
  //             excludedActivityTypes: [
  //               "com.apple.mobilenotes.SharingExtension",
  //               "com.apple.reminders.RemindersEditorExtension",
  //               "UIActivityTypeAddToReadingList",
  //             ],
  //           }
  //         );
  //       } else {
  //         // Android
  //         await Share.share(
  //           {
  //             title: shareSubject,
  //             message: url,
  //           },
  //           {
  //             dialogTitle: "Share your event link",
  //           }
  //         );
  //       }
  //     } catch (error) {
  //       // alert(error.message);
  //     }
  //   }
  // };

  return (
    <>
      <ShareModal />
      <OnboardingButton
        style={style}
        text={text}
        icon={
          Platform.OS === "ios" ? "ios-share-outline" : "share-social-outline"
        }
        onPress={sharePress}
        {...props}
      />
    </>
  );
};

export default ShareEventButton;
