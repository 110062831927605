//Stub functions
export const checkMediaPermissions = async () => true;

export const openSettings = () => {};

export const checkAllStreamingPermissions = async () => true;

export const requestStreamingPermissions = async () => {};

export const openStreamWindow = async (event) => {};

export const getAllPermissions = async () => {};

export const setForStream = () => {};

export const showActionSheet = (message, onDeleteAction) => {
  const confirmDelete = window.confirm(message);
  if (confirmDelete) {
    onDeleteAction();
  }
};
