import { Container, Text, View, Toast, Button, Icon, Input } from "native-base";
import React, { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import AppInput from "@/components/shared/AppInput";
import AppTitle from "@/components/shared/AppTitle";
import OnboardingContent from "@/components/shared/OnboardingContent";
import analytics from "@/analytics";
import AppHeader from "@/components/shared/AppHeader";

const AskForReview = ({ navigation, route }) => {
  return <Container></Container>;
};

export default AskForReview;
