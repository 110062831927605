import { Button, Text } from "native-base";
import React from "react";

import { StyleConstants } from "../globalStyle";

import { Ionicons } from "@expo/vector-icons";

const OnboardingButton = ({ text, style, icon, loading, dark, ...props }) => {
  return (
    <Button
      rounded
      block
      style={{
        marginTop: 20,
        marginBottom: 10,
        height: 62,
        ...style,
      }}
      dark={dark}
      {...props}
    >
      {!!icon && !loading && (
        <Ionicons name={icon} size={24} color={dark ? "#3f5b7a" : "white"} />
      )}
      {loading && <ActivityIndicator color="#fff" />}
      <Text
        style={{ fontFamily: StyleConstants.defaultButtonFont }}
        numberOfLines={1}
      >
        {text}
      </Text>
    </Button>
  );
};

export default OnboardingButton;
