import React, { useCallback, useEffect, useState } from "react";
import { useWindowDimensions, TouchableOpacity } from "react-native";
import { useAuth } from "../../AuthContext";
import { minDesktopWidth, makeUrl, makeDownloadUrl } from "../../globalUtils";
import { openStreamWindow } from "../../Utils";
import { fetchEventByStreamKey } from "../../http/events";
import useSubscribeToEventUpdates from "../../hooks/useSubscribeToEventUpdates";
import { useFocusEffect } from "@react-navigation/native";

import { Container, Text, Content } from "native-base";
import AppHeader, { onBackPressed } from "../../shared/AppHeader";
import { Ionicons } from "@expo/vector-icons";

const GoLive = ({ route, navigation }) => {
  const { streamKey: stream_key } = route.params || {};

  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const [rtmpVisible, setRtmpVisible] = useState(false);

  const { isSignedOut, getOpenAppFromWebUrl } = useAuth();

  const [loadingGoLive, setLoadingGoLive] = useState(false);
  useFocusEffect(
    useCallback(() => {
      setLoadingGoLive(true);
      (async () => {
        const event = await fetchEventByStreamKey(stream_key);
        // TODO: handle error
        if (Platform.OS === "web") {
          if (isMobileUX) {
            // Mobile webapp => Redirect to magic link
            const redir = await getOpenAppFromWebUrl(`/stream/${stream_key}`);
            setLoadingGoLive(false);
            window.location.href = redir;
          } else {
            // Web Desktop
            setLoadingGoLive(false);
            setRtmpVisible(true);
          }
        } else {
          // if (loadingGoLive) return;
          setLoadingGoLive(true);
          await openStreamWindow(event);
          setLoadingGoLive(false);
        }
      })();
    }, [stream_key])
  );

  return (
    <Container>
      <AppHeader
        leftButtonComponent={
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                if (isSignedOut) {
                  navigation.navigate("SignUp");
                } else {
                  navigation.navigate("EventList");
                }
              }
            }}
          >
            <Ionicons name="ios-close-outline" size={30} color="#F84123" />
          </TouchableOpacity>
        }
        hasTitle={false}
        hasRightButton={false}
      />

      <Content
        contentContainerStyle={{
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 24,
        }}
      >
        {loadingGoLive && <Text>Starting stream...</Text>}
      </Content>
    </Container>
  );
};

export default GoLive;
