import "react-datepicker/dist/react-datepicker.css";

import "../../assets/datepicker.css";

import React from "react";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";

import GlobalStyles from "../globalStyle";
import AppInput from "./AppInput";

const AppDatePicker = React.memo(
  ({ itemProps = [], errorMessage, ...pickerProps }) => (
    <DatePicker
      showTimeSelect
      timeFormat="h:mm aa"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="MMMM d, yyyy h:mm aa"
      popperContainer={({ children }) => createPortal(children, document.body)}
      style={[
        GlobalStyles.appInput,
        {
          height: 50,
          borderWidth: 0,
          borderRadius: 14,
          backgroundColor: "transparent",
          marginRight: 16,
        },
      ]}
      customInput={
        <AppInput disabled={pickerProps.disabled} errorMessage={errorMessage} />
      }
      {...pickerProps}
    />
  )
);

export default AppDatePicker;
