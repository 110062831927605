/* eslint-disable react-hooks/rules-of-hooks */

import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Linking, Platform } from "react-native";
import uuidv4 from "uuid/v4";

import { Auth } from "aws-amplify";
import AsyncStorage from "@react-native-async-storage/async-storage";

import analytics from "../analytics";
import branch, { initBranch } from "../branchlib";
import { fetchProfile } from "./http/events";
import jwtDecode from "jwt-decode";
import Sentry from "../sentry";

const authContext = createContext();

const stringifyValues = (clientMetadata) =>
  Object.fromEntries(
    Object.entries(clientMetadata).map(([key, value]) => [
      key,
      value.toString(),
    ])
  );

function useProvideAuth(navigation, tokens) {
  const [branchdata, setbranchdata] = useState({});

  useEffect(() => {
    (async () => {
      const data = await initBranch();
      setbranchdata(data);
    })();
  }, []);

  // TODO: CONDITIONAL IMPORT
  // useEffect(() => {
  //   if (Platform.OS !== "web") {
  //     const { appleAuth } = require("@invertase/react-native-apple-authentication");
  //     return appleAuth.onCredentialRevoked(async () => {
  //       await signOut();
  //     });
  //   }
  // }, []);

  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            userEmail: action.userEmail,
            onboarding_done: action.onboarding_done,
            firstLoad: action.firstLoad,
            isLoading: false,
          };
        case "SET_FIRST_LOAD":
          return {
            ...prevState,
            firstLoad: action.firstLoad,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignedOut: false,
            userToken: action.token,
            isLoading: false,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignedOut: true,
            userToken: null,
            isLoading: false,
            custom_domain: null,
            broadcaster: null,
            // TODO: need to reset all profile fields here
          };
        case "SET_PROFILE":
          return {
            ...prevState,
            broadcaster: action.attributes["custom:broadcaster"],
            name: action.attributes.name,
            email: action.attributes.email,
            sub: action.attributes.sub,
          };
        case "SIGN_UP":
          return {
            ...prevState,
            onboarding_done: false,
          };
        case "ONBOARDING_DONE":
          return {
            ...prevState,
            onboarding_done: true,
          };
        case "ENRICH_PROFILE":
          return {
            ...prevState,
            ...action.payload,
            profileLoaded: true,
          };
      }
    },
    {
      broadcaster: "",
      isLoading: true,
      isSignedOut: false,
      userToken: null,
    }
  );

  const refetchProfile = async () => {
    const profile = await fetchProfile();
    const { events, ...payload } = profile;

    payload.publishedEvents = events.filter(
      (e) => !e.is_test && e.is_published
    ).length;

    payload.unpublishedEvents = events.filter(
      (e) => !e.is_test && !e.is_published
    ).length;

    payload.testEvents = events.filter((e) => e.is_test).length;

    analytics.identify(profile.username, payload);

    dispatch({ type: "ENRICH_PROFILE", payload });

    return payload;
  };

  React.useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      try {
        if (tokens.idToken) {
          // Perform windowSignIN
          signInWithTokens(tokens);
        } else {
          const userToken = await AsyncStorage.getItem("userToken");

          const onboarding_done =
            (await AsyncStorage.getItem("onboarding_done")) === "false"
              ? false
              : null;
          const userEmail = (await AsyncStorage.getItem("userEmail")) || "";
          if (!userToken) {
            throw new Error("Token not found");
          }

          const fl = await AsyncStorage.getItem("firstLoad");

          const firstLoad = fl !== "false" ? true : false;

          dispatch({
            type: "RESTORE_TOKEN",
            token: userToken,
            userEmail,
            onboarding_done,
            firstLoad,
          });

          const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
          });
          if (!attributes) {
            throw new Error("Sign In expired");
          }

          // IDENTIFY user as early as possible
          Sentry.setUser({ id: attributes["custom:broadcaster"] });
          // Get broadcaster
          dispatch({ type: "SET_PROFILE", attributes });

          await refetchProfile();
        }
      } catch (e) {
        console.error(e);
        dispatch({ type: "SIGN_OUT" });
      }
    };

    bootstrapAsync();
  }, [tokens.idToken]);

  const setFirstLoad = async (firstLoad) => {
    await AsyncStorage.setItem("firstLoad", firstLoad ? "true" : "false");
    dispatch({
      type: "SET_FIRST_LOAD",
      firstLoad,
    });
  };

  const checkUserExists = async (email) => {
    try {
      await AsyncStorage.setItem("userEmail", email.toLowerCase());
      await Auth.signIn(email.toLowerCase());

      return true;
    } catch (error) {
      if (error.code === "UserNotFoundException") {
        return false;
      }
      throw error;
    }
  };

  const signIn = async ({ email, password }) => {
    Auth.configure({
      authenticationFlowType: "USER_SRP_AUTH",
    });

    let user = await Auth.signIn(email.toLowerCase(), password, {
      platform: Platform.OS,
    });

    const { attributes, username: token } = user;

    if (!attributes) {
      throw new Error("Could not Sign In");
    }

    await AsyncStorage.setItem("userToken", token);
    await AsyncStorage.setItem("userEmail", email);

    dispatch({ type: "SIGN_IN", token });
    dispatch({ type: "SET_PROFILE", attributes });
    Sentry.setUser({ id: attributes["custom:broadcaster"] });

    const profile = await refetchProfile();

    await analytics.track("Sign In", {
      platform: Platform.OS,
    });

    return profile;
  };

  const signInWithSocial = async (result, clientMetadata) => {
    const decoded = jwtDecode(result.identityToken);

    // console.log(decoded);

    let res;
    try {
      res = await Auth.signIn(decoded.email.toLowerCase(), null);
      // console.log(res);

      let u;

      if (res.challengeName === "CUSTOM_CHALLENGE") {
        u = await Auth.sendCustomChallengeAnswer(
          res,
          JSON.stringify({
            idToken: result.identityToken,
            nonce: decoded.nonce, //? weird why do i need result.nonce?
          })
        );
      }

      // const authUser = await Auth.currentAuthenticatedUser();
      const { attributes, username: token } = u;

      await AsyncStorage.setItem("userToken", token);
      await AsyncStorage.setItem("userEmail", decoded.email);

      dispatch({ type: "SIGN_IN", token });
      dispatch({ type: "SET_PROFILE", attributes });

      const profile = await refetchProfile();

      await analytics.track("Sign In", {
        platform: Platform.OS,
        via: "social",
      });

      return profile;
    } catch (err) {
      console.log("error in sign in");
      console.error(err);
      if (err.toString().indexOf("UserNotFoundException") !== -1) {
        console.log("signing up");
        const profile = await signUp(
          {
            email: decoded.email,
            password: uuidv4(),
            firstname: result.fullName.givenName,
            lastname: result.fullName.familyName,
          },
          {
            method: "social",
            iss: decoded.iss,
            ...stringifyValues(clientMetadata),
          }
        );
        return profile;
      } else {
        throw err;
      }
    }
  };

  const signInWithTokens = async ({
    idToken,
    refreshToken,
    accessToken,
    username,
  }) => {
    const localSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({
        IdToken: idToken,
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: refreshToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: accessToken,
      }),
    });

    const localUser = new CognitoUser({
      Username: username,
      Pool: Auth.userPool,
      Storage: Auth.userPool.storage,
    });
    localUser.setSignInUserSession(localSession);
    Auth.currentCredentials = async () => localSession;

    try {
      await Auth.currentSession();
      console.warn(`mobile login`);
      await analytics.track("Sign In", {
        platform: Platform.OS,
        // via: "qr"
      });

      const { attributes } = await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      if (!attributes) {
        throw new Error("Sign In expired");
      }

      dispatch({ type: "SIGN_IN", token: username, fromApp: true });
      await AsyncStorage.setItem("userToken", username);
      await AsyncStorage.setItem("userEmail", attributes.email);
      Sentry.setUser({ id: attributes["custom:broadcaster"] });
      dispatch({ type: "SET_PROFILE", attributes });

      await refetchProfile();
    } catch (ex) {
      console.warn(`mobile login ${ex}`);
      dispatch({ type: "SIGN_OUT" });
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    analytics.track("Sign Out");
    analytics.reset();
    // Cache.setItem("events", []);
    await AsyncStorage.setItem("userToken", "");
    await AsyncStorage.setItem("onboarding_done", "false");
    await AsyncStorage.removeItem("onboardingData");
    await AsyncStorage.removeItem("reviewAsked");

    dispatch({ type: "SIGN_OUT" });
  };

  const signUp = async (
    { email, password, firstname, lastname },
    clientMetadata
  ) => {
    try {
      const username = uuidv4();

      let params = {
        username,
        password,
        attributes: {
          email: email.toLowerCase(),
          name: `${firstname} ${lastname}`,
          given_name: firstname,
          family_name: lastname,
          // 'custom:broadcaster': username,
        },
        validationData: [],
        clientMetadata: stringifyValues(clientMetadata),
      };

      const { user } = await Auth.signUp(params);

      // console.log("Sign up done");
      // console.log(user);
      dispatch({ type: "SIGN_UP" });

      if (!clientMetadata?.onboarding_done) {
        await AsyncStorage.setItem("onboarding_done", "false");
      }

      await analytics.track("Sign Up", {
        platform: Platform.OS,
        method: "email_password",
        email,
        first_name: firstname,
        last_name: lastname,
        ...clientMetadata, // signUpWithSocial will overwrite method
      });

      const profile = await signIn({ email, password });

      return profile;
    } catch (err) {
      // do something here\
      console.error(err);
      throw new Error(err && err.message);
    }
  };

  const finishOnboarding = async (values) => {
    await analytics.track("Onboarding", values);
    await AsyncStorage.setItem("onboarding_done", "true");
    // After a couple of seconds refetch the profile, to see if there are event credits
    setTimeout(refetchProfile, 2000);
    // dispatch({ type: "ONBOARDING_DONE" });
  };

  const setNewPassword = async (username, code, password) => {
    const uname = username.replace(" ", "+");
    await Auth.configure({
      authenticationFlowType: "USER_SRP_AUTH",
    });

    await Auth.forgotPasswordSubmit(uname, code, password);
    await Auth.signIn(uname, password);

    await signIn({ email: uname, password });
  };

  const openWebFromApp = async (path = "/") => {
    // this.setState({ openingSettings: true });
    const domain = `https://broadcaster.eventlive.pro`;

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) {
        console.error(err);
      } else {
        const url = `${domain}${path}?idToken=${session.idToken.jwtToken}&refreshToken=${session.refreshToken.token}&accessToken=${session.accessToken.jwtToken}&username=${session.accessToken.payload.username}`;

        Linking.openURL(url);
      }
    });
  };

  const getOpenAppFromWebUrl = async (path = "/") => {
    // this.setState({ openingSettings: true });
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    // get fresh session
    const session = await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        }
      );
    });

    const linkData = {
      data: {
        idToken: session.idToken.jwtToken,
        refreshToken: session.refreshToken.token,
        accessToken: session.accessToken.jwtToken,
        username: session.accessToken.payload.username,
        $deeplink_path: path,
        golive: true,
        $og_title: "Open in EventLive App",
        $og_description: "Get EventLive App to go Live",
        $canonical_url: `https://broadcaster.eventlive.pro${path}`,
        // $og_image_url: "http://lorempixel.com/400/400",
      },
    };

    const link = await new Promise((resolve, reject) => {
      branch.link(linkData, function (err, link) {
        if (err) {
          reject(err);
        } else {
          resolve(link);
        }
      });
    });

    return link;
  };

  const legacyPricing =
    state.account_type === "business" ||
    !!state.legacy_pricing ||
    state.registered_datetime < "2023-07-15";

  return {
    checkUserExists,
    signIn,
    signInWithTokens,
    signInWithSocial,
    branchdata,
    signOut,
    signUp,
    setNewPassword,
    refetchProfile,
    finishOnboarding,
    openWebFromApp,
    getOpenAppFromWebUrl,
    setFirstLoad,
    legacyPricing,
    ...state,
  };
}

const getTokensFromQueryString = () => {
  try {
    let queryString = new URLSearchParams(window.location.search);
    const idToken = queryString.get("idToken");
    const refreshToken = queryString.get("refreshToken");
    const accessToken = queryString.get("accessToken");
    const username = queryString.get("username");
    return { idToken, refreshToken, accessToken, username };
  } catch (err) {
    return {};
  }
};

export function ProvideAuth({ navigation, children }) {
  const tokens = getTokensFromQueryString(); // ensure they dont change as react-navigation redirects

  const auth = useProvideAuth(navigation, tokens);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
