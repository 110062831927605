import { useFormik } from "formik";
import React, { useImperativeHandle, useMemo, useState } from "react";
import { Toast } from "native-base";
import { sendPinnedMessage } from "@/components/http/events";
import { GuestbookMessageSchema } from "@/components/screens/Schemas";
import AppInput from "@/components/shared/AppInput";
import FormSubtitle from "@/components/shared/FormSubtitle";
import OnboardingButton from "@/components/shared/OnboardingButton";

const GuestbookPinnedMessageForm = ({ event }, ref) => {
  const initialValues = {
    message: "",
    notifyGuests: false,
  };

  const f = useFormik({
    initialValues,
    validationSchema: GuestbookMessageSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        await sendPinnedMessage(event, values.message);
        actions.resetForm(initialValues);
        actions.setSubmitting(false);
      } catch (err) {
        actions.setSubmitting(false);
        Toast.show({
          text: String(err),
          position: "top",
          type: "danger",
          duration: 5000,
        });
      }
    },
  });

  useImperativeHandle(ref, () => ({
    isReadyToSave: () => {
      return !!f.dirty && !f.isSubmitting;
    },
  }));

  const updateMessage = useMemo(() => f.handleChange("message"), []);

  return (
    <>
      <FormSubtitle>Pinned Message</FormSubtitle>
      <AppInput
        textarea
        multiline
        rowSpan={3}
        onChangeText={updateMessage}
        onBlur={f.handleBlur("message")}
        value={f.values.message}
        placeholder="Type your message for the guests here"
        onSubmitOnEnter={f.handleSubmit}
        inputStyle={{
          // backgroundColor: "#ffffff",
          // borderColor: "#ffffff",
          // paddingLeft: 0,
          paddingTop: 10,
        }}
        style={
          {
            // backgroundColor: "#ffffff",
            // borderColor: "#ffffff",
            // marginTop: 32,
          }
        }
        placeholderTextColor="#787878"
        errorMessage={
          f.errors.message && f.touched.message ? f.errors.message : null
        }
      />

      {/* <ListItem style={{ marginLeft: 0, marginRight: 0 }}>
        <Left>
          <Text>Notify all guests by email</Text>
        </Left>
        <Right>
          <AppSwitch
            isOn={!!f.values.notifyGuests}
            onToggle={(value) => f.setFieldValue("notifyGuests", value)}
          />
        </Right>
      </ListItem> */}

      <OnboardingButton onPress={f.handleSubmit} text={"Add Pinned Message"} />
    </>
  );
};

export default React.forwardRef(GuestbookPinnedMessageForm);
