import {
  ActionSheet,
  Body,
  Button,
  CardItem,
  Right,
  Text,
  Toast,
} from "native-base";
import moment from "moment";
import React, { useState } from "react";
import {
  View,
  DeviceEventEmitter,
  Dimensions,
  Image,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";

import { showActionSheet } from "../Utils";
import GlobalStyles, { StyleConstants } from "../globalStyle";
import { formatEventDate, minDesktopWidth } from "../globalUtils";
import { deleteEvent } from "../http/events";
import { CameraIcon, LiveIndicator, TrashCanButton } from "../shared/Buttons";
import { unlockWithCredit } from "../http/events";

import { useBuy } from "../BuyContext";
import { useAuth } from "../AuthContext";

const styles = StyleSheet.create({
  icon: {
    marginLeft: 20,
    marginRight: 4,
    marginTop: 2,
  },
  cardStyles: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    elevation: 0,
    shadowColor: "rgba(255,255,255, .2)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0, //default is 1
    shadowRadius: 0, //default is 1
    shadow: "none",
    marginBottom: 20,
  },
  imageStyles: {
    // flex: 1,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    overflow: "hidden",
  },
  optionIconStyles: {
    position: "absolute",
    right: 5,
    top: 8,
    color: "red",
    borderRadius: 20,
    height: 30,
    width: 32,
    backgroundColor: StyleConstants.defaultBrandGrayLight,
  },
  optionIconIconStyle: {
    justifyContent: "center",
    color: "#fff",
    fontSize: 16,
  },
  bodyStyles: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    alignContent: "flex-start",
  },
  text: {
    color: StyleConstants.defaultBrandGray,
    // fontWeight: 'bold',
    fontFamily: StyleConstants.defaultFont,
    lineHeight: 18,
    padding: 5,
  },
});

const BtnBuyUnlock = ({ text, onPress, loading }) => (
  <Button primary style={{ height: 42 }} onPress={onPress}>
    {loading && <ActivityIndicator style={styles.icon} color="#ffffff" />}
    <Text
      style={{
        paddingRight: 20,
        paddingLeft: 20,
      }}
    >
      {text}
    </Text>
  </Button>
);

const EventCard = React.memo(
  ({ onEventSettingPressed, onGoLivePressed, now, event }) => {
    const {
      event_cover_path,
      title,
      is_published,
      is_test,
      event_datetime_utc,
      event_timezone,
      event_window_start_datetime_utc,
      event_window_end_datetime_utc,
      slug,
      event_status,
      days,
      days_paid,
    } = event;

    const {
      credits,
      refetchProfile,
      subscription_access_expiry_datetime,
      legacyPricing,
    } = useAuth();

    const date = formatEventDate(event_datetime_utc, event_timezone);

    const { width, height } = useWindowDimensions();
    const minWidth = Math.min(width, height);
    const isMobileUX = minDesktopWidth > width;

    const onDeleteEvent = () => {
      if (is_test && !is_published) return; // disallow to delete blocked tests
      showActionSheet(
        `Are you sure you want to delete ${title}?`, // message
        () => {
          // Delete action, insert your code here
          DeviceEventEmitter.emit("event_delete", slug);
          deleteEvent(slug);
        },
        title // item title
      );
    };

    const liveButtonTitle = is_test
      ? "Test"
      : event_status === "upcoming"
      ? "Go Live"
      : "Continue";

    const active = now.isBetween(
      event_window_start_datetime_utc,
      event_window_end_datetime_utc
    );

    const w = isMobileUX ? minWidth - 52 : 380;
    const daysToPay = days - days_paid;
    const needToBuy = !is_published && !is_test;
    const hasEnoughCredits =
      credits >= daysToPay ||
      subscription_access_expiry_datetime > moment().utc().format();
    const live = event_status === "live";
    const needContinue = active && is_published && !live;

    const { purchase } = useBuy();
    // const navigation = useNavigation();

    const [loadingUnlock, setloadingUnlock] = useState(false);

    const productId = legacyPricing ? "specialevent50" : "specialeventnew";

    return (
      <TouchableOpacity onPress={() => onEventSettingPressed(event)}>
        <View style={[styles.cardStyles, { width: w, alignSelf: "center" }]}>
          <CardItem bordered={false} cardBody>
            {/* <Image
            source={{ uri: event_cover_path }}
            style={styles.imageStyles}
          /> */}

            <Image
              style={[
                styles.imageStyles,
                {
                  width: w,
                  height: (w * 720) / 1280,
                },
              ]}
              width={w}
              height={(w * 720) / 1280}
              source={{ uri: event_cover_path }}
            />

            <TrashCanButton onPress={onDeleteEvent} />
          </CardItem>
          <CardItem
            style={{
              paddingLeft: 0,
              paddingTop: 10,
              paddingRight: 0,
              paddingBottom: 10,
            }}
          >
            <Body style={styles.bodyStyles}>
              <Text
                style={[GlobalStyles.textHeavy, { fontSize: 16 }]}
                // ellipsizeMode={"tail"}
                // numberOfLines={1}
                // title={title}
              >
                {title}
              </Text>
              <Text
                style={[GlobalStyles.textStyle, { fontSize: 12 }]}
                ellipsizeMode={"tail"}
                numberOfLines={1}
              >
                {date}
              </Text>
            </Body>
            {(needToBuy || needContinue || live) && (
              <Right style={{ alignSelf: "flex-start" }}>
                {needToBuy && (
                  <BtnBuyUnlock
                    text={hasEnoughCredits ? "Publish" : "Buy Now"}
                    onPress={async () => {
                      if (hasEnoughCredits) {
                        setloadingUnlock(true);
                        try {
                          await unlockWithCredit(slug, days - days_paid);
                          await refetchProfile();
                        } catch (err) {
                          Toast.show({
                            text: "Could not unlock the event",
                            position: "bottom",
                            type: "danger",
                          });
                        }
                        setloadingUnlock(false);
                      } else {
                        purchase({
                          productId,
                          slug,
                          title,
                          quantity: days - days_paid,
                        });
                      }
                    }}
                    loading={loadingUnlock}
                  />
                )}
                {needContinue && (
                  <Button
                    primary
                    style={{ borderRadius: 5, height: 42 }}
                    onPress={() => onGoLivePressed(event)}
                  >
                    <CameraIcon
                      fill="#fff"
                      style={{
                        marginLeft: 20,
                        marginRight: 4,
                        marginTop: 2,
                      }}
                    />
                    <Text
                      style={{
                        paddingRight: 20,
                        paddingLeft: 4,
                        color: "white",
                      }}
                    >
                      {liveButtonTitle}
                    </Text>
                  </Button>
                )}

                {live && <LiveIndicator />}
              </Right>
            )}
          </CardItem>
        </View>
      </TouchableOpacity>
    );
  }
);

export default EventCard;
