import { Text } from "native-base";
import React from "react";
import { Pressable } from "react-native";
import GlobalStyles from "../globalStyle";

export const ForgotPasswordLink = ({ onPress }) => {
  return (
    <Pressable
      onPress={onPress}
      role="link"
      style={GlobalStyles.forgotPasswordLinkContainer}
    >
      {/* <Text multiline={false} style={GlobalStyles.textHeavy}> Already have an account? </Text> */}
      <Text
        multiline={false}
        style={[
          GlobalStyles.textBrandedHeavy,
          { fontWeight: "bold", color: "#2E3F52" },
        ]}
      >
        Forgot password?
      </Text>
    </Pressable>
  );
};
