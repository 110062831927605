import React from "react";
import { ActivityIndicator } from "react-native";

import { StyleConstants } from "../globalStyle";

export const AppIndicatorRounded = ({style, ...props}) => {
  return (

    <ActivityIndicator
      color={'#fff'}
      style={[{
        borderRadius: 20,
        height: 32,
        width: 32,
        backgroundColor: "rgba(0,0,0,0.5)",
      }, style]}
      {...props}
    />
  );
};

const AppActivityIndicator = (props) => {
  return (

      <ActivityIndicator
        animating={true}
        color={StyleConstants.defaultBrandColor}
        {...props}
      />
  );
};

export default AppActivityIndicator;
