import React from "react";
import { Dimensions, Image, Pressable } from "react-native";

import { isWeb, phoneThreshold } from "../globalUtils";
import BrandSvgComponent from "./BrandSvgComponent";

const logo = require("../../assets/img/brand2.png");

const aspectRatio = 157/34;

const AppBrand = ({ style = {} }) => {
  const { width } = Dimensions.get('window');
  
  const logoWidth = width > phoneThreshold ? 189 : 138;
  const logoHeight = logoWidth / aspectRatio;

  return isWeb ? (
    
      <Image
        resizeMode={"contain"}
        source={logo}
        style={{
          width: 115,
          height: 25,
          ...style
        }}
      />
      
    
  ) : (
    
      <Image
        resizeMode={"contain"}
        source={logo}
        style={{
          width: logoWidth,
          height: logoHeight,
          ...style
        }}
      />
    
  );
};

export default AppBrand;
