import React, { useEffect, useState } from "react";

import { GoogleLogin } from "react-google-login";

const SIWG = ({ style, onSuccess = () => {} }) => {
  const responseGoogle = (response) => {
    // console.log(response);
    if (!response.tokenId || !response.profileObj) return;
    onSuccess({
      identityToken: response.tokenId,
      fullName: {
        givenName: response.profileObj.givenName,
        familyName: response.profileObj.familyName,
      },
      email: response.profileObj.email,
    });
  };

  return (
    <GoogleLogin
      clientId="689061127926-imh6j5tga8d86st6vtpsu46773jvcu14.apps.googleusercontent.com"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
      render={(renderProps) => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          style={{
            borderWidth: 1,
            borderRadius: 16,
            backgroundColor: "#fff",
            height: 62,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1em",
            cursor: "pointer",
            borderColor: "black",
            color: "black",
            ...style,
          }}
        >
          <img
            src="/google.svg"
            alt="google login"
            className="icon"
            height={24}
            style={{ marginRight: 5 }}
          ></img>
          Continue with Google
        </button>
      )}
    />
  );
};

export default SIWG;
