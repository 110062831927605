import { Formik, useFormik } from "formik";
import { Container, View, Toast } from "native-base";
import React from "react";
import AppActivityIndicator from "../../shared/AppActivityIndicator";
import { useAuth } from "../../AuthContext";
import AppHeader from "../../shared/AppHeader";
import AppInput from "../../shared/AppInput";
import AppSpacing from "../../shared/AppSpacing";
import AppSubtitle from "../../shared/AppSubtitle";
import AppTitle from "../../shared/AppTitle";
import OnboardingButton from "../../shared/OnboardingButton";
import OnboardingContent from "../../shared/OnboardingContent";
import { NewPasswordSchema } from "../Schemas";

const PasswordReset = ({ navigation, route, ...props }) => {
  const { setNewPassword } = useAuth();

  const f = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: NewPasswordSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        const { password } = values;
        const { params } = route;

        await setNewPassword(params.username, params.c, password);
        navigation.navigate("EventList");
      } catch (err) {
        actions.setSubmitting(false);
        Toast.show({
          text: String(err),
          position: "top",
          type: "danger",
          duration: 5000,
        });
      }
    },
  });
  return (
    <Container>
      <AppHeader hasTitle={false} hasRightButton={false} />
      <OnboardingContent>
        <View style={{ flex: 1 }}>
          <AppSpacing height="8%" />
          <AppTitle text={"Choose a New Password"} />
          <AppSubtitle
            text={
              "Your new password must be different from previous used passwords"
            }
            style={{
              textAlign: "left",
            }}
          />

          <AppSpacing height="10%" />

          {f.isSubmitting && <AppActivityIndicator />}

          {!f.isSubmitting && (
            <>
              <AppInput
                textContentType={"newPassword"}
                secureTextEntry={true}
                onChangeText={f.handleChange("password")}
                onBlur={f.handleBlur("password")}
                value={f.values.password}
                placeholder={"New Password"}
                autoCorrect={false}
                autoCapitalize={"none"}
                onSubmitOnEnter={() => f.handleSubmit()}
              />
              <OnboardingButton
                text={"Set New Password"}
                // onPress={handleSubmit}
                onPress={f.handleSubmit}
                disabled={f.isSubmitting}
                primary
                style={{
                  marginTop: 25,
                  marginBottom: 10,
                }}
              />
            </>
          )}
        </View>
      </OnboardingContent>
    </Container>
  );
};

export default PasswordReset;
