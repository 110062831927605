import { useFormik } from "formik";
import { Left, ListItem, Right, Text, Button, Toast } from "native-base";
import React, { useState } from "react";
import { Image, View, ActivityIndicator } from "react-native";

import AppConfig from "../../AppConfig";
import { resetLogo, updateEmail, updateProfile } from "../http/events";
import { Step1, Step2, Step3 } from "../screens/OnBoarding/SegmentationForm";
import { UpdateProfileSchema } from "../screens/Schemas";
import AppInput from "./AppInput";
import AppSwitch from "./AppSwitch";
import AppTitle from "./AppTitle";
import UploadFile from "./UploadFile";
import AutoSave from "./AutoSave";

export const LogoPreviewAndUploader = ({
  logo_path,
  onDelete = () => {},
  onComplete = () => {},
  path,
  disclaimer = "Logo will show on all event pages. Please upload PNG or JPG file. The file will be trimmed and resized automatically. Horizontal logos work best. If using white on transparent logo, you might need to change the color of the background or the logo in your file.",
}) => {
  const [logoLoading, setLogoLoading] = useState(false);

  return (
    <>
      {logoLoading && (
        <ActivityIndicator
          color={"#F84123"}
          size={"large"}
          style={{ margin: 10 }}
        />
      )}
      {!!logo_path && (
        <>
          <View style={{ alignItems: "center" }}>
            <Image
              source={{ uri: logo_path }}
              resizeMode="contain"
              style={{
                height: 60,
                width: 200,
                resizeMode: "contain",
                marginBottom: 20,
              }}
            />
          </View>
          <Button
            rounded
            primary
            bordered
            block
            onPress={async () => {
              setLogoLoading(true);
              onDelete();
              setLogoLoading(false);
            }}
            style={[
              {
                height: 62,
                marginBottom: 10,
              },
            ]}
          >
            <Text>Remove Logo</Text>
          </Button>
        </>
      )}
      <UploadFile
        buttonTitle={logo_path ? "Change Logo" : "Upload Logo"}
        path={path}
        dark
        bordered
        bucket={AppConfig.REACT_APP_LOGO_BUCKET}
        disclaimer={disclaimer}
        onStart={() => setLogoLoading(true)}
        onCancel={() => setLogoLoading(false)}
        onComplete={async (res) => {
          const logo_path = res?.url
            ?.replace(`eventliveprod-logos`, `eventliveprod-logos-resized`)
            .replace(/\.[^/.]+$/, ".png");

          const checkLogoAccessible = async () => {
            try {
              const response = await fetch(logo_path, {
                method: "HEAD",
              });
              console.log(response);
              if (response.ok) {
                onComplete(logo_path);
                setLogoLoading(false);
              } else {
                setTimeout(checkLogoAccessible, 1000); // Check again after 1 second
              }
            } catch (error) {
              console.error("Fetch error:", error);
              setTimeout(checkLogoAccessible, 1000); // Check again after 1 second
            }
          };

          checkLogoAccessible();
        }}
      />
    </>
  );
};

const UpdateProfileForm = ({
  refetchProfile,
  profile,
  logo_path,
  broadcaster,
}) => {
  const f = useFormik({
    initialValues: {
      ...profile,
      first_name: profile.first_name || "",
      last_name: profile.last_name || "",
    },
    validationSchema: UpdateProfileSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        const { email, ...res } = values;
        await updateProfile(res);

        // IF EMAIL CHANGED
        if (email !== profile.email) {
          await updateEmail({ email });
        }
        if (refetchProfile) {
          await refetchProfile();
        }
      } catch (err) {
        actions.setSubmitting(false);
        Toast.show({
          text: "Could not change email or save profile",
          position: "bottom",
          type: "error",
        });
      }
    },
  });

  return (
    <>
      <AutoSave {...f} />
      <AppTitle text={`Profile`} style={{ marginTop: 40, marginBottom: 20 }} />

      <AppInput
        textContentType="givenName"
        onChangeText={f.handleChange("first_name")}
        onBlur={f.handleBlur("first_name")}
        value={f.values.first_name}
        placeholder="Your First Name *"
        onSubmitOnEnter={() => f.handleSubmit()}
        errorMessage={f.errors.first_name ? f.errors.first_name : null}
      />

      <AppInput
        textContentType="familyName"
        onChangeText={f.handleChange("last_name")}
        onBlur={f.handleBlur("last_name")}
        value={f.values.last_name}
        placeholder="Your Last Name"
        onSubmitOnEnter={() => f.handleSubmit()}
        errorMessage={f.errors.last_name ? f.errors.last_name : null}
      />

      <AppInput
        onChangeText={f.handleChange("email")}
        onBlur={f.handleBlur("email")}
        value={f.values.email}
        placeholder="Email *"
        errorMessage={f.errors.email && f.touched.email ? f.errors.email : null}
        autoCapitalize="none"
      />

      <Step1
        title="Account Type"
        f={f}
        individualLabel="Individual"
        businessLabel="Business"
      />

      {f.values.account_type === "business" && (
        <>
          <Step2 title="What best describes you?" f={f} />

          <Step3 title="Event page branding" f={f} />

          <View style={{ marginVertical: 40 }}>
            {!f.values.no_branding && (
              <LogoPreviewAndUploader
                path={broadcaster}
                logo_path={logo_path}
                onDelete={async () => {
                  await resetLogo(broadcaster);
                  await refetchProfile();
                }}
                onComplete={async (logo) => {
                  await refetchProfile();
                }}
              />
            )}

            <ListItem style={{ marginLeft: 0, marginBottom: 20 }}>
              <Left>
                <Text>Remove all branding from stream pages</Text>
              </Left>
              <Right>
                <AppSwitch
                  isOn={!!f.values.no_branding}
                  onToggle={(value) => f.setFieldValue("no_branding", value)}
                />
              </Right>
            </ListItem>
          </View>
        </>
      )}
    </>
  );
};

export default UpdateProfileForm;
