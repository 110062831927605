import { Button, Text } from "native-base";
import React from "react";
import { ActivityIndicator, Image, Pressable, StyleSheet } from "react-native";
import Svg, {
  Circle,
  Defs,
  G,
  LinearGradient,
  Mask,
  Path,
  Rect,
  Stop,
  Use,
} from "react-native-svg";

import GlobalStyles, { StyleConstants } from "../globalStyle";
import { isWeb } from "../globalUtils";
import { Ionicons } from "@expo/vector-icons";

export function TrashCanIcon(props) {
  return (
    <Svg
      width={14}
      height={15}
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G
        stroke="#FFF"
        strokeWidth={1.3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M1 3.64h11.88M11.56 3.64v9.24a1.32 1.32 0 01-1.32 1.32h-6.6a1.32 1.32 0 01-1.32-1.32V3.64m1.98 0V2.32C4.3 1.591 4.891 1 5.62 1h2.64c.729 0 1.32.591 1.32 1.32v1.32M5.62 6.94v3.96M8.26 6.94v3.96" />
      </G>
    </Svg>
  );
}

export function GoogleIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 -4 60 60"
      {...props}
    >
      <Path
        fill="#FFC107"
        d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"
      />
      <Path
        fill="#FF3D00"
        d="m6.306 14.691 6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691z"
      />
      <Path
        fill="#4CAF50"
        d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"
      />
      <Path
        fill="#1976D2"
        d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"
      />
    </Svg>
  );
}

export const Profile = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="-2 -2 20 20"
    fill="#3F5B7A"
    className="bi bi-person-fill"
    {...props}
  >
    <Path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
  </Svg>
);

export function LiveIndicator(props) {
  return (
    <Svg
      height={35}
      viewBox="10 24 90 90"
      width={50}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      {...props}
    >
      <Path d="M6.5 44.096h115v39.808H6.5z" fill="#fb4a59" />
      <G fill="#f2f2f2">
        <Path d="M58.131 71.343v3.648H42.868V53.009h3.968v18.334zM61.428 53.009H65.4v21.982h-3.972zM91.186 53.009L82 74.991h-4.445l-9.184-21.982h4.416l6.976 16.671 6.976-16.671zM98.1 56.657v5.312h11.359v3.647H98.1v5.728h12.543v3.648H94.1V53.009h16.543v3.648z" />
        <Circle cx={24.192} cy={64} r={6} />
      </G>
    </Svg>
  );
}

export const TrashCanButton = ({
  style,
  textStyle,
  iconStyle,
  text,
  icon,
  disabled,
  primary,
  onPress,
  ...extraProps
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={[
        {
          position: "absolute",
          right: 5,
          top: 8,
          borderRadius: 20,
          height: 30,
          width: 32,
          backgroundColor: "#AEAEB2",
          justifyContent: "center",
          alignItems: "center",
        },
        style,
      ]}
      {...extraProps}
    >
      <TrashCanIcon />
    </Pressable>
  );
};

export function UploadVideoIcon(props) {
  return (
    <Svg
      width={53}
      height={46}
      viewBox="0 0 53 46"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <LinearGradient
          x1="50%"
          y1="13.073%"
          x2="50%"
          y2="159.157%"
          id="prefix__b"
        >
          <Stop stopColor="#FAFAFA" stopOpacity={0} offset="0%" />
          <Stop stopOpacity={0.062} offset="100%" />
        </LinearGradient>
        <Rect id="prefix__a" x={0} y={0} width={53} height={53} rx={6.9} />
      </Defs>
      <G transform="translate(0 -7)" fill="none" fillRule="evenodd">
        <Mask id="prefix__c" fill="#fff">
          <Use xlinkHref="#prefix__a" />
        </Mask>
        <Use fill="url(#prefix__b)" xlinkHref="#prefix__a" />
        <Path
          d="M26.86 14.376l7.337 7.65a.5.5 0 01-.358.847l-3.154.022a.5.5 0 00-.496.5V33.5a.5.5 0 01-.5.5H23.31a.5.5 0 01-.5-.5V23.399a.5.5 0 00-.496-.5l-3.156-.025a.5.5 0 01-.357-.847l7.337-7.65a.5.5 0 01.722 0z"
          fill="#797A82"
          mask="url(#prefix__c)"
        />
        <Rect
          fill="#929298"
          mask="url(#prefix__c)"
          x={15}
          y={37}
          width={23}
          height={3}
          rx={0.5}
        />
      </G>
    </Svg>
  );
}

export function ChoosePhotoIcon(props) {
  return (
    <Svg
      width={54}
      height={46}
      viewBox="0 0 54 46"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <LinearGradient
          x1="50%"
          y1="14.37%"
          x2="50%"
          y2="115.302%"
          id="prefix__b"
        >
          <Stop stopColor="#FAFAFA" stopOpacity={0} offset="0%" />
          <Stop stopOpacity={0.065} offset="100%" />
        </LinearGradient>
        <Rect
          id="prefix__a"
          x={0}
          y={0.345}
          width={53.13}
          height={53.13}
          rx={6.9}
        />
      </Defs>
      <G transform="translate(0 -8)" fill="none" fillRule="evenodd">
        <Mask id="prefix__c" fill="#fff">
          <Use xlinkHref="#prefix__a" />
        </Mask>
        <Use fill="url(#prefix__b)" xlinkHref="#prefix__a" />
        <Circle
          fill="#797A82"
          fillRule="nonzero"
          mask="url(#prefix__c)"
          cx={14.959}
          cy={21.851}
          r={4.14}
        />
        <Path
          d="M48.747 53.724h-23.46l4.546-5.647.327-.407 5.924-7.36c.212-.227.527-.367.867-.384.34-.017.672.09.914.294.03.029.059.06.085.09l10.797 13.414z"
          fillOpacity={0.8}
          fill="#797A82"
          fillRule="nonzero"
          mask="url(#prefix__c)"
        />
        <Path
          d="M40.324 53.724H5.953a.858.858 0 01-.129-.008l16.257-22.98c.202-.292.58-.472.99-.472.411 0 .79.18.991.472l10.91 15.421.523.738 4.83 6.83z"
          fill="#797A82"
          fillRule="nonzero"
          mask="url(#prefix__c)"
        />
      </G>
    </Svg>
  );
}

function BackIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={22}
      viewBox="0 0 12 22"
      {...props}
    >
      <Path
        fill="none"
        stroke="#F84123"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={20}
        strokeWidth={2}
        d="M10.72 20.44v0L1 10.72v0L10.72 1v0"
      />
    </Svg>
  );
}

export const CalendarIcon = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={12}
      viewBox="0 0 14 12"
      {...props}
    >
      <Path
        fill="#fff"
        d="M10.85 8h1.1V6.857h-1.1zm0-1.704h1.1V5.143h-1.1zm0-1.725h1.1V3.43h-1.1zm-8.8 5.143h1.1V8.571h-1.1zm2.2 0h1.1V8.571h-1.1zm2.2 0h1.1V8.571h-1.1zM2.05 8h1.1V6.857h-1.1zm2.2 0h1.1V6.857h-1.1zm-2.2-1.704h1.1V5.143h-1.1zm2.2 0h1.1V5.143h-1.1zm2.2 0h1.1V5.143h-1.1zm0-1.725h1.1V3.43h-1.1zM8.65 8h1.1V6.857h-1.1zm0-1.704h1.1V5.143h-1.1zm0-1.725h1.1V3.43h-1.1zM6.45 8h1.1V6.857h-1.1zM.95 3.886a1.6 1.6 0 011.6-1.6h8.9a1.6 1.6 0 011.6 1.6v5.371a1.6 1.6 0 01-1.6 1.6h-8.9a1.6 1.6 0 01-1.6-1.6zM0 10.4A1.6 1.6 0 001.6 12h10.8a1.6 1.6 0 001.6-1.6V1.6A1.6 1.6 0 0012.4 0H1.6A1.6 1.6 0 000 1.6z"
      />
    </Svg>
  );
};

export const CameraIcon = ({ fill = "#f6b6a7", ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={10}
    viewBox="0 0 16 10"
    {...props}
  >
    <Path
      fill={fill}
      d="M0 1.277C0 .572.572 0 1.277 0h9.18c.705 0 1.276.572 1.276 1.277V3.2L15.361.479a.4.4 0 01.639.32v8.003a.4.4 0 01-.639.319L11.733 6.4v1.923c0 .705-.571 1.277-1.276 1.277h-9.18A1.277 1.277 0 010 8.323z"
    />
  </Svg>
);

export const DeleteGuestIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <Path
      fill="#F84123"
      d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z"
    />
    <Path fill="#fff" d="M5 12a1 1 0 011-1h12a1 1 0 010 2H6a1 1 0 01-1-1z" />
  </Svg>
);

export const CloseIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    viewBox="0 0 14 14"
    {...props}
  >
    <Path
      fill="none"
      stroke="#787878"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={20}
      strokeWidth={1.5}
      d="M13 1L1 13M1 1l12 12"
    />
  </Svg>
);

export const VideoUploadIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={30}
    viewBox="0 0 39 30"
    {...props}
  >
    <Path
      fill="#F84123"
      d="M32.352 25.522h3.989v1.791h-3.989zm-16.84-14.569a.88.88 0 011.33-.755l7.154 4.27a.88.88 0 010 1.511l-7.154 4.271a.88.88 0 01-1.33-.755zm16.84-8.266h3.989v1.79h-3.989zM20.386 25.522h3.979v1.791h-3.979zm0-22.835h3.979v1.79h-3.979zM8.42 25.522h3.99v1.791H8.42zm0-22.835h3.99v1.79H8.42zm18.17 22.835h3.99v1.791h-3.99zm0-22.835h3.99v1.79h-3.99zM14.626 25.522h3.979v1.791h-3.979zm0-22.835h3.979v1.79h-3.979zM2.659 25.522h3.989v1.791H2.659zm0-22.835h3.989v1.79H2.659zM0 27.8A2.2 2.2 0 002.2 30h34.6a2.2 2.2 0 002.2-2.2V2.2A2.2 2.2 0 0036.8 0H2.2A2.2 2.2 0 000 2.2z"
    />
  </Svg>
);

export const LargeUnlockButton = ({
  style,
  onPress,
  icon,
  loading = false,
  text = "Unlock",
}) => (
  <Button
    rounded
    dark
    block
    style={[
      {
        // marginLeft: 9,
        // marginRight: 9,
        paddingLeft: 41,
        paddingRight: 41,
        // marginTop: 25,
        marginBottom: 0,
        height: 62,
      },
      style,
    ]}
    primary
    onPress={onPress}
    disabled={!!loading}
  >
    {icon && (
      <Ionicons
        name={icon}
        style={{ marginRight: 5 }}
        size={24}
        color="white"
      />
    )}
    <Text
      style={{
        padding: 0,
        paddingRight: 9,
        fontSize: 16,
        fontFamily: StyleConstants.defaultFont,
      }}
    >
      {text}
    </Text>
    {loading && <ActivityIndicator color="#ffffff" />}
  </Button>
);

export function AppBackButton({ onPress }) {
  return (
    <Button style={{ padding: 0 }} iconLeft transparent onPress={onPress}>
      <BackIcon />
      <Text>Back</Text>
    </Button>
  );
}

export function AppSimpleHeaderButton({ text, onPress }) {
  return (
    <Button style={{ padding: 0 }} iconLeft transparent onPress={onPress}>
      <Text>{text}</Text>
    </Button>
  );
}

export function GetAppStore({ style, onPress }) {
  return (
    <Button style={style} transparent onPress={onPress}>
      <Image
        source={require("@/assets/appstore.png")}
        style={{ width: 128, height: 40 }}
      />
    </Button>
  );
}

export function GetPlayStore({ style, onPress }) {
  return (
    <Button style={style} transparent onPress={onPress}>
      <Image
        source={require("../../assets/playstore.png")}
        style={{ width: 135, height: 40 }}
      />
    </Button>
  );
}
