import { useFormik } from "formik";
import { View, Platform, useWindowDimensions } from "react-native";
import React, { useState } from "react";
import { Toast, Text } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useAuth } from "@/components/AuthContext";
import SIWA from "@/components/shared/SIWA";
import SIWG from "@/components/shared/SIWG";
import AppInput from "@/components/shared/AppInput";
import { ForgotPasswordLink } from "@/components/shared/ForgotPasswordLink";
import AppTitle from "@/components/shared/AppTitle";
import OnboardingButton from "@/components/shared/OnboardingButton";
import SignUpContainer from "@/components/shared/SignUpContainer";
import { SignUpPrivacy } from "@/components/shared/SignUpPrivacy";
import * as Yup from "yup";
import { useFocusEffect } from "@react-navigation/native";
import {
  collectMetadataFromBranchOrURL,
  minDesktopWidth,
} from "@/components/globalUtils";
import { LoadingScreen } from "@/components/LoadingScreen";
import { getEventByAlias } from "@/components/http/events";

const SignUpSignIn = ({ navigation, route }) => {
  const {
    signIn,
    signUp,
    checkUserExists,
    userEmail,
    signInWithSocial,
    branchdata,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [passwordEntryShown, setpasswordEntryShown] = useState(false);
  const [userExists, setuserExists] = useState(false);
  const [clientMetadata, setclientMetadata] = useState({});

  const [pageTitle, setPageTitle] = useState(
    isMobileUX ? "Welcome to EventLive" : "Sign in to EventLive"
  );

  useFocusEffect(
    React.useCallback(() => {
      (async () => {
        const metadata = await collectMetadataFromBranchOrURL(branchdata);
        let fullMetadata = metadata;

        const storedValues = await AsyncStorage.getItem("onboardingData");
        if (storedValues) {
          const parsed = JSON.parse(storedValues);
          fullMetadata = { ...metadata, ...parsed, onboarding_done: true };
          if (parsed.title && parsed.account_type !== "business") {
            setPageTitle(`Schedule ‘${parsed.title}’ and share the link`);
          }

          if (parsed.account_type === "business") {
            setPageTitle(
              `Set up your account to try all the features and go live!`
            );
          }

          if (parsed.skipEventCreation) {
            setPageTitle(`Try EventLive Streaming`);
          }
        }

        setclientMetadata(fullMetadata);
      })();
    }, [])
  );

  // If email and password just sign in right away
  // route?.params?.email
  // route?.params?.password

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    ...(passwordEntryShown
      ? { password: Yup.string().min(6, "Too Short!").required("Required") }
      : {}),
  });

  const f = useFormik({
    initialValues: {
      email: route?.params?.email || userEmail || "",
      password: route?.params?.password || "",
    },
    validationSchema: SignInSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      if (!passwordEntryShown) {
        const ex = await checkUserExists(values.email);
        setuserExists(ex);
        setpasswordEntryShown(true);
      } else {
        try {
          if (userExists) {
            await signIn(values);
          } else {
            const profile = await signUp(values, clientMetadata);
            // Go to paywall
            if (clientMetadata.skipEventCreation) {
              navigation.navigate("Test");
            } else {
              if (clientMetadata.alias) {
                const event = await getEventByAlias(clientMetadata.alias);
                if (event && event.slug) {
                  navigation.navigate("PayWall", {
                    slug: event.slug,
                    event,
                  });
                }
              }
            }
          }
        } catch (err) {
          actions.setSubmitting(false);
          actions.setStatus({ errorMsg: err.message });

          Toast.show({
            text: err.message,
            position: "top",
            type: "danger",
            duration: 5000,
          });
        }
      }
    },
  });

  const socialSignIn = async (response) => {
    try {
      setLoading(true);
      const profile = await signInWithSocial(response, clientMetadata);
      // Go to paywall
      if (clientMetadata.skipEventCreation) {
        navigation.navigate("Test");
      } else {
        if (clientMetadata.alias) {
          const event = await getEventByAlias(clientMetadata.alias);
          if (event && event.slug) {
            navigation.navigate("PayWall", {
              slug: event.slug,
              event,
            });
          }
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;
  const submitOrLoading = f.isSubmitting || loading;

  return (
    <SignUpContainer navigation={navigation} route={route}>
      <View
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent:
            isMobileUX && passwordEntryShown ? "flex-start" : "center",
        }}
      >
        {submitOrLoading && <LoadingScreen />}

        {!submitOrLoading &&
          (passwordEntryShown ? (
            <>
              <AppTitle
                text={
                  userExists
                    ? "Welcome back to EventLive"
                    : "Create a password for EventLive"
                }
                style={{ marginTop: 40, marginBottom: 20 }}
              />

              <Text style={{ marginBottom: 10 }}>
                {f.values.email}{" "}
                <Text
                  style={{ textDecorationLine: "underline" }}
                  onPress={() => {
                    setpasswordEntryShown(false);
                  }}
                >
                  Not your email?
                </Text>
              </Text>

              <AppInput
                textContentType={"password"}
                secureTextEntry={true}
                onChangeText={f.handleChange("password")}
                onBlur={f.handleBlur("password")}
                value={f.values.password}
                placeholder={userExists ? "Your Password" : "Choose Password"}
                autoCorrect={false}
                spellCheck={false}
                autoCapitalize={"none"}
                errorMessage={
                  f.errors.password && f.touched.password
                    ? f.errors.password
                    : null
                }
                returnKeyType="go"
                returnKeyLabel="Sign In"
                enablesReturnKeyAutomatically={true}
                onSubmitEditing={f.handleSubmit}
                autoFocus
              />

              {userExists && (
                <ForgotPasswordLink
                  onPress={() => {
                    navigation.navigate("ForgotPassword", {
                      email: f.values.email,
                    });
                  }}
                />
              )}

              <OnboardingButton
                style={{
                  marginTop: 20,
                  marginBottom: 0,
                }}
                primary
                onPress={f.handleSubmit}
                disabled={f.isSubmitting}
                text={"Continue"}
              />
            </>
          ) : (
            <>
              <AppTitle
                text={pageTitle}
                style={{ marginTop: 40, marginBottom: 20 }}
              />

              {Platform.OS !== "android" && (
                <View style={{ marginVertical: 20 }}>
                  <SIWA onSuccess={socialSignIn} full={true} />
                </View>
              )}

              <View style={{ marginBottom: 20 }}>
                <SIWG onSuccess={socialSignIn} />
              </View>

              <View style={{ paddingVertical: 10, marginBottom: 20 }}>
                <Text style={{ textAlign: "center" }}>or</Text>
              </View>
              <View
                style={{
                  flexDirection: isMobileUX ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <AppInput
                  style={{
                    flex: isMobileUX ? undefined : 2,
                    margin: 0,
                    marginRight: isMobileUX ? undefined : 5,
                    height: 62,
                  }}
                  name="username"
                  textContentType="username"
                  keyboardType="email-address"
                  onChangeText={f.handleChange("email")}
                  onBlur={f.handleBlur("email")}
                  value={f.values.email}
                  placeholder="Your Email"
                  autoCorrect={true}
                  spellCheck={false}
                  autoCapitalize="none"
                  autoCompleteType="email"
                  returnKeyType="go"
                  returnKeyLabel="Sign In"
                  enablesReturnKeyAutomatically={true}
                  onSubmitEditing={f.handleSubmit}
                />
                <OnboardingButton
                  style={{
                    flex: isMobileUX ? undefined : 1,
                    marginLeft: isMobileUX ? undefined : 5,
                    alignSelf: "stretch",
                    marginTop: isMobileUX ? 10 : 0,
                    marginBottom: 0,
                    paddingHorizontal: 20,
                  }}
                  primary
                  onPress={f.handleSubmit}
                  disabled={f.isSubmitting}
                  text={"Continue"}
                />
              </View>
              <SignUpPrivacy />
            </>
          ))}
      </View>
    </SignUpContainer>
  );
};

export default SignUpSignIn;
