import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import { Button, Container, List, Text, Icon } from "native-base";
import * as PapaParse from "papaparse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Image, View } from "react-native";
import AppActivityIndicator from "../../shared/AppActivityIndicator";
import { isWeb } from "../../globalUtils";
import { fetchGuests, inviteGuests } from "../../http/events";
import AppHeader from "../../shared/AppHeader";
import DashboardContent from "../../shared/DashboardContent";
import GuestListItem from "../../shared/GuestListItem";
import OnboardingButton from "../../shared/OnboardingButton";

const GuestImport = ({ route, navigation }) => {
  const { slug } = route.params;
  const [guests, setguests] = useState([]);
  const [loading, setloading] = useState(true);
  const [event, setEvent] = useState({ slug });

  useEffect(() => {
    const fetchAsync = async () => {
      try {
        const myEvent = await fetchGuests(slug);
        setEvent(myEvent);
        setloading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAsync();
  }, [slug]);

  const selectDocument = useCallback(async () => {
    setloading(true);
    const res = await DocumentPicker.getDocumentAsync({
      // .csv Normal mime not working on android
      type: !isWeb ? "text/*" : "text/csv",
      multiple: false,
      copyToCacheDirectory: true,
    });
    const { assets } = res;
    console.log(assets);
    const uri = assets[0]?.uri;

    if (!uri) {
      setloading(false);
      return;
    }

    // On web the data comes prefixed with => 'data:application/octet-stream;base64,' which need to be removed
    let content;
    if (isWeb) {
      const base64Content = uri.split(",")[1];
      if (uri.startsWith("data:text/csv")) {
        content = decodeURIComponent(escape(window.atob(base64Content)));
      } else {
        content = atob(base64Content);
      }
    } else {
      content = await FileSystem.readAsStringAsync(uri);
    }

    PapaParse.parse(content, {
      header: true,
      encoding: "utf-8",
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/[^a-z]/g, ""),
      error: (error, file) => {
        console.log(` error => ${error} file => ${file}`);
      },
      complete: ({ data }, file) => {
        console.log(data);
        const emails = data.map((g) => g.email);

        setguests([
          ...guests,
          ...data
            .map((guest) => {
              return {
                name:
                  guest.first_name || guest.last_name
                    ? `${guest.first_name} ${guest.last_name || ""}`
                    : guest.name,
                email: guest.email,
              };
            }) // Dedup and remove all without emails:
            .filter((g, pos) => {
              return !!g.email && emails.indexOf(g.email) === pos;
            }),
        ]);
      },
    });

    setloading(false);
  }, []);

  const deleteGuest = (i) => {
    setguests(guests.filter((e, index) => index !== i));
  };

  const inviteAll = async () => {
    try {
      setloading(true);
      await inviteGuests(event, guests);
      navigation.navigate("GuestList", { slug });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <AppHeader title="CSV Import" hasRightButton={false} />
      <DashboardContent>
        {loading && <AppActivityIndicator />}
        {!loading && (
          <View style={{ flex: 1, flexDirection: "column" }}>
            {!guests.length && (
              <View style={{ marginHorizontal: 44 }}>
                <Image
                  // resizeMode={"contain"}
                  source={require("../../../assets/guestImport.png")}
                  width="242"
                  height="207"
                  style={{
                    width: 242,
                    height: 207,
                    marginVertical: 116,
                    alignSelf: "center",
                  }}
                />
                <Button
                  rounded
                  primary
                  block
                  style={[
                    {
                      height: 48,
                      borderRadius: 5,
                    },
                  ]}
                  onPress={selectDocument}
                >
                  <Icon name="document" style={{ color: "white" }} />
                  <Text style={{ padding: 0, paddingRight: 9, fontSize: 16 }}>
                    Select File
                  </Text>
                </Button>

                <Text style={{ textAlign: "center", marginTop: 10 }}>
                  Format: CSV. Required column: Email. Optional columns: Name,
                  First Name, Last Name
                </Text>
              </View>
            )}
            {!!guests.length && (
              <>
                <List style={{ flexGrow: 1 }}>
                  {guests.map((guest, i) => (
                    <GuestListItem
                      guest={guest}
                      key={guest.id || guest.email}
                      onDelete={() => deleteGuest(i)}
                    />
                  ))}
                </List>

                <OnboardingButton
                  dark
                  onPress={inviteAll}
                  text={`Invite All (${guests.length})`}
                  style={{ margin: 10 }}
                />
              </>
            )}
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default GuestImport;
