import React from "react";
// import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { View, Platform, Pressable } from "react-native";
import { Toast, Icon, Text } from "native-base";
import AppInput from "../shared/AppInput";
import { TextLink } from "./TextLink";

const MultuDaySelector = ({ f, questionStyle }) => {
  return (
    <View style={{ height: 60 }}>
      {f.values.days <= 1 && (
        <>
          <TextLink
            text={"Multi-Day event?"}
            onPress={() => {
              f.setFieldValue("days", 2, true);
            }}
            textStyle={{
              paddingVertical: 10,
              textAlign: "center",
              width: "100%",
              ...questionStyle,
            }}
          />
        </>
      )}
      {(f.values.days > 1 || f.errors.days) && (
        <AppInput
          onBlur={f.handleBlur("days")}
          value={String(f.values.days)}
          onChangeText={f.handleChange("days")}
          label="Days"
          errorMessage={f.errors.days && f.touched.days ? f.errors.days : null}
          rightComponent={
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Pressable
                onPress={() => {
                  f.setFieldValue("days", Math.max(1, f.values.days - 1), true);
                }}
              >
                <Icon
                  active
                  name={"remove-outline"}
                  style={{ color: "#8B8B8D" }}
                />
              </Pressable>
              <Pressable
                onPress={() => {
                  f.setFieldValue("days", Math.min(7, f.values.days + 1), true);
                }}
              >
                <Icon active name={"ios-add"} style={{ color: "#8B8B8D" }} />
              </Pressable>
            </View>
          }
        />
      )}
    </View>
  );
};

export default MultuDaySelector;
