// import { yupToFormErrors } from "formik";
import * as Yup from "yup";

import { checkAliasAvailable } from "../http/events";
import { roles, eventTypes } from "@/components/globalUtils";

export const validateAlias = async (value) => {
  if (value) {
    return await checkAliasAvailable(value);
  } else {
    return true;
  }
};

export const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
});

export const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your full name"),
  // firstname: Yup.string()
  //   .min(1, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  // lastname: Yup.string()
  //   .min(1, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter a valid email"),
  password: Yup.string()
    .min(6, "Password is too short")
    .required("Password is required"),
});

export const SegmentationSchema = Yup.object().shape({
  account_type: Yup.string().oneOf(["individual", "business"]),
  broadcaster_role: Yup.string().oneOf([
    "videographer",
    "photographer",
    "funeralhome",
    "eventplanner",
    "other",
  ]),
  role_other: Yup.string(),
  company_name: Yup.string(),
  company_url: Yup.string(),
});

export const UpdateProfileSchema = Yup.object().shape({
  email: Yup.string("Please enter your email")
    .email("Invalid email")
    .required("Required"),
  first_name: Yup.string("Please enter first name")
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your first name"),
  last_name: Yup.string("Please enter your last name")
    .min(1, "Too Short!")
    .max(50, "Too Long!"),
  account_type: Yup.string().oneOf(["individual", "business"]),
  broadcaster_role: Yup.string()
    .oneOf([
      "videographer",
      "photographer",
      "funeralhome",
      "eventplanner",
      "other",
      null,
    ])
    .nullable(),
  role_other: Yup.string().nullable(),
  company_name: Yup.string().nullable(),
  company_url: Yup.string().nullable(),
  no_branding: Yup.bool().nullable(),
});

export const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export const NewPasswordSchema = Yup.object().shape({
  password: Yup.string().min(6, "Too Short!").required("Required"),
});

export const EventShape = {
  title: Yup.string().required("Title is Required"),

  event_location: Yup.string(),
  event_type: Yup.string().oneOf(eventTypes.map((r) => r.value)),
  guest_message: Yup.string(),
};

export const EventDateTimeDaysLinkShape = {
  event_datetime: Yup.date().required("Required"),
  event_timezone: Yup.string().required("Timezone needs to be detected"),
  days: Yup.number()
    .typeError("Please enter number of days")
    .integer("Please enter number of days")
    .min(1, "At least 1 day")
    .max(7, "7 days max"),
  // event_date: Yup.string().required("Date is Required"),
  // event_time: Yup.string().required("Time is Required"),
  alias: Yup.string()
    .required("Please choose your custom event link")
    .nullable()
    .test("checkAvailability", "Sorry, Already Taken", validateAlias)
    .matches(
      /^[\d\w-_]+$/,
      "Only letters, numbers, dashes and underscore allowed."
    ),
};

export const CreateEventSchema = Yup.object().shape({
  ...EventShape,
  ...EventDateTimeDaysLinkShape,
});

export const LinkSchema = Yup.object().shape({
  url: Yup.string().required("Link URL is required"),
  title: Yup.string().required("Title is required"),
  file_upload: Yup.bool().nullable(),
});

export const EventSchema = Yup.object().shape({
  ...EventShape,
  links: Yup.array().of(LinkSchema),
});

export const StripeSchema = Yup.object().shape({
  stripe_price_id: Yup.string()
    // .required("Provide Stripe Price Id")
    .matches(
      /^price_[a-zA-Z0-9]+$/,
      "Invalid format. It should be in the format of price_(letters_and_digits)"
    )
    .nullable(),
  stripe_price_id_test: Yup.string()
    .matches(
      /^price_[a-zA-Z0-9]+$/,
      "Invalid format. It should be in the format of price_(letters_and_digits)"
    )
    .nullable(),
});

export const EventDateTimeSchema = Yup.object().shape({
  event_datetime: Yup.date().required("Required"),
  event_timezone: Yup.string().required("Timezone needs to be detected"),
  days: Yup.number()
    .typeError("Please enter number of days")
    .integer("Please enter number of days")
    .min(1, "At least 1 day")
    .max(7, "7 days max"),
});

export const EmbedSchema = Yup.object().shape({
  embedlink: Yup.string().url(),
  //https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict/64868316
});

export const AliasSchema = Yup.object().shape({
  alias: Yup.string()
    .required("Required")
    .nullable()
    .test("checkAvailability", "Sorry, Already Taken", async (value) => {
      if (value) {
        return await checkAliasAvailable(value);
      } else {
        return true;
      }
    })
    .matches(
      /^[\d\w-_]+$/,
      "Only letters, numbers, dashes and underscore allowed."
    ),
});

export const EventClipSchema = Yup.object().shape({
  clipFrom: Yup.string().matches(
    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
    "Please use HH:MM:SS format"
  ),
  clipTo: Yup.string().matches(
    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
    "Please use HH:MM:SS format"
  ),
});

export const PrivacySchema = Yup.object().shape({
  event_privacy: Yup.string(),
  guestbook_enabled: Yup.bool(),
  event_password: Yup.string().nullable(),
  download_enabled: Yup.bool(),
  prefer_chat: Yup.bool(),
  email_pref: Yup.string(),
  hide_viewer_count: Yup.bool(),
});

export const BrandingSchema = Yup.object().shape({
  description: Yup.string(),
  logo_path: Yup.string(),
  company_name: Yup.string(),
  company_url: Yup.string(),
  custom_date_display: Yup.string(),
});

export const GuestInviteSchema = Yup.object().shape({
  name: Yup.string().required("Please add Guest's name"),
  email: Yup.string()
    .email("Not a valid Email")
    .required("Add guest's email to invite"),
});

export const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string().required(),
});

export const GuestbookMessageSchema = Yup.object().shape({
  message: Yup.string().required("Enter the message"),
  notifyGuests: Yup.bool(),
});
