import React from "react";
import { Image, View } from "react-native";

import { Container } from "native-base";
import { useAuth } from "../../AuthContext";

import AppHeader from "../../shared/AppHeader";
import AppSpacing from "../../shared/AppSpacing";
import AppSubtitle from "../../shared/AppSubtitle";
import AppTitle from "../../shared/AppTitle";
import OnboardingContent from "../../shared/OnboardingContent";
import { AppBackButton } from "../../shared/Buttons";

const ForgotPasswordLinkSent = ({ navigation }) => {
  const { isSignedOut } = useAuth();

  return (
    <Container>
      <AppHeader hasTitle={false} hasRightButton={false} 
        leftButtonComponent={
          <AppBackButton
            onPress={() => {
              return isSignedOut ? navigation.navigate("SignIn") :navigation.navigate("Profile");
            }}
          />
        }
      />
      <OnboardingContent>
        <View style={{ flex: 1 }}>
          <>
            <AppSpacing height="10%" />

            <Image
              source={require("../../../assets/img/email_open.png")}
              resizeMode={"contain"}
              style={{
                width: "100%",
                height: 140,
                minHeight: 120,
                alignContent: "center",
              }}
            />

            <AppSpacing height="8%" />
            <View
              style={{
                alignSelf: "center",
                alignItems: "center",
                // justifyContent: 'space-around',
                // alignContent: 'space-around',
                width: "70%",
              }}
            >
              <AppTitle h3 text={"Check your mail"} />
              <AppSubtitle
                text={
                  "We’ve sent you an email with a link to set a new password"
                }
              />
            </View>

            <AppSpacing height="10%" />
          </>
        </View>
      </OnboardingContent>
    </Container>
  );
};

export default ForgotPasswordLinkSent;
