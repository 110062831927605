import moment from "moment-timezone";

import { API, graphqlOperation } from "aws-amplify";

import sentry from "../../sentry";
import analytics from "../../analytics";
import { Platform } from "react-native";

export const eventFragment = `
  id
  slug
  title
  alias
  event_datetime_utc
  event_window_start_datetime_utc
  event_window_end_datetime_utc
  event_location
  event_timezone
  event_cover_path
  event_status
  event_privacy
  event_type
  is_published
  stream_key
  is_test
  stream_id
  broadcaster
  event_privacy
  guestbook_enabled
  prefer_chat
  event_password
  download_enabled
  vod_converted
  video_uploaded
  email_pref
  vod_filename
  liveViewers
  company_name
  company_url
  logo_path
  description
  custom_date_display
  embedlink
  days
  days_paid
  live_only
  stripe_connected_account_id
  stripe_price_id
  stripe_price_id_test
  hide_viewer_count
  clipFrom
  clipTo
  links {
    title
    url
    file_upload
  }
`;

export const guestsFragment = `
  guests {
    id
    name 
    email
    unsubscribed
    was_live
    was_past
    was_upcoming
    source
  }
`;

export const eventsQuery = `
  query myEvents {
    myEvents {
      ${eventFragment}
    }
  }
`;

export const eventsBareQuery = `
  query myEvents {
    myEvents {
      id
      is_test
      is_published
    }
  }
`;

export const profileFragment = `
  username
  credits
  subscription_status
  subscription_access_expiry_datetime
  subscription_plan
  first_name
  last_name
  email
  logo_path
  company_name
  no_branding
  company_url
  account_type
  registered_datetime
  registered_from
  account_type
  custom_domain
  broadcaster_role
  role_other
  onboarding_done
  stripeCustomerId
  prefer_multiple_tests
  legacy_pricing
  ga
  gclid
  fbp
  fbc
  pmf_filled
  events {
    id
    is_test
    is_published
  }
`;

export const myProfileQuery = `
  query {
    broadcasterProfile {
      ${profileFragment}
    }
  }
`;

export const myProfileQueryAffiliate = `
  query {
    broadcasterProfile {
      ${profileFragment}
      affiliate {
        link
        visitors
        leads
        conversions
        commission_unpaid
        commission_due
        commission_paid
        commission_total
        referrals {
          conversion_state
          name
          visits
        }
      }
    }
  }
`;

export const myEventQuery = `
  query MyEvent($slug: String!) {
    
    myEvent(slug: $slug) {
      ${eventFragment}
    }
  }
`;

export const subscriptionEventUpdated = `
  subscription eventUpdated($broadcaster: String! $slug: String!) {
    eventUpdated(broadcaster: $broadcaster, slug: $slug) {
      ${eventFragment}
      ${guestsFragment}
    }
  }
`;

export const subscriptionAnyEventUpdated = `
  subscription eventUpdated($broadcaster: String!) {
    eventUpdated(broadcaster: $broadcaster) {
      ${eventFragment}
    }
  }
`;

export const createTestEventMutation = `
  mutation CreateTestEvent($broadcaster: String!, $details: TestEventInput!, $force_new_test: Boolean) {
    createTestEvent(
      broadcaster: $broadcaster,
      details: $details,
      force_new_test: $force_new_test
    ) {
       ${eventFragment}
    }
  }
`;

export const createEventMutation = `
  mutation CreateEvent($details: EventInput!) {
    createEvent(
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

export const deleteAccountMutation = `
  mutation DeleteAccount {
    deleteAccount {
      deleted
    }
  }
`;

export const resetTestMutation = `
  mutation ResetTest($slug: String!) {
    resetEvent(
      slug: $slug
    ) {
      ${eventFragment}
    }
  }
`;

export const updateEventDateTimeMutation = `
  mutation UpdateEventDateTime($slug: String!, $details: EventDateTimeInput!) {
    updateEventDateTime(
      slug: $slug
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

export const updateEventMetaMutation = `
  mutation UpdateEventMeta($slug: String!, $details: EventMetaInput!) {
    updateEventMeta(
      slug: $slug
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

export const updateEventStripeOptionsMutation = `
  mutation UpdateEventStripeOptions($slug: String!, $details: EventStripeInput!) {
    updateEventStripeOptions(
      slug: $slug
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

export const updateEventEmbedOptionsMutation = `
  mutation UpdateEventEmbedOptions($slug: String!, $details: EventEmbedInput!) {
    updateEventEmbedOptions(
      slug: $slug
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

export const deleteEventMutation = `
  mutation DeleteEvent($slug: String!) {
    deleteEvent(slug: $slug) {
      ${eventFragment}
    }
  }
`;

export const deleteEventGuestMutation = `
  mutation DeleteGuestById($id: String!) {
    deleteGuestById(guest_id: $id) {
      id
    }
  }
`;

export const updateEventAliasMutation = `
  mutation ($slug: String!, $alias: String!) {
    updateEventAlias(
      slug: $slug,
      alias: $alias
    ) {
      ${eventFragment}
    }
  }
`;

export const updateEventClipMutation = `
  mutation ($slug: String!, $clipFrom: Int, $clipTo: Int) {
    updateEventClip(
      slug: $slug,
      clipFrom: $clipFrom,
      clipTo: $clipTo
    ) {
      ${eventFragment}
    }
  }
`;

const updateEventPrivacyMutation = `
  mutation UpdateEventPrivacy($slug: String!, $details: EventPrivacyInput!) {
    updateEventPrivacy(
      slug: $slug,
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

const updateEventBrandingMutation = `
  mutation UpdateEventBranding($slug: String!, $details: EventBrandingInput!) {
    updateEventBranding(
      slug: $slug,
      details: $details
    ) {
      ${eventFragment}
    }
  }
`;

export const updateEventUploadStatusMutation = `
  mutation updateEventUploadStatus($slug: String!, $video_uploaded: Boolean!, $vod_filename: String, $notify_guests: Boolean, $vod_converted: Boolean) {
    updateEventUploadStatus(
      slug: $slug,
      video_uploaded: $video_uploaded,
      vod_filename: $vod_filename,
      notify_guests: $notify_guests,
      vod_converted: $vod_converted
    ) {
      ${eventFragment}
    }
  }
`;

export const publishEventMutation = `
  mutation PublishEvent($slug: String!, $purchaseReceipt: String, $quantity: Int) {
    publishEvent(
      slug: $slug,
      purchaseReceipt: $purchaseReceipt,
      quantity: $quantity
    ) {
      ${eventFragment}
    }
  }
`;

export const inviteGuestsMutation = `
  mutation registerGuests($event_id: String!, $guests: [GuestInput], $sendInvite: Boolean) {
    registerGuests(
      event_id: $event_id,
      guests: $guests,
      sendInvite: $sendInvite
    ) {
      ${guestsFragment}
      ${eventFragment}
    }
  }
`;

export const guestsQuery = `
  query event($slug: String!) {
    myEvent(slug: $slug) {        
      ${guestsFragment}
      event_status
      event_privacy
      stream_key
      title
      guestbook_enabled
      is_published
      id
      slug
      alias
      broadcaster
    }
  }
`;

export const commentsQuery = `
  query event($slug: String!) {
    myEvent(slug: $slug) {  
      guests {
        id
        name 
        email
      }
      id
      broadcaster
      slug
      prefer_chat
      guestbook_messages {
        id
        message_datetime_utc
        message
        image_filename
        pinned
        guest {
          id
          name
          email
        }
      }
    }
  }
`;

export const deleteCommentMutation = `
  mutation DeleteComment($event_id: String!, $id: String!) {
    deleteGuestbookComment(
      event_id: $event_id,
      id: $id
    )
  }
`;

export const sendPinnedMessageMutation = `
  mutation sendPinnedMessage($event_id: String!, $message: String!) {
    sendPinnedMessage(
      event_id: $event_id,
      message: $message
    ) {
      id
      event_id
      message
      message_datetime_utc
      pinned
      guest {
        id
        name
      }
    }
  }
`;

export const guestbookSubscription = `
  subscription getmessages($event_id: String!) {
    messageSent(event_id: $event_id) {
      id
      event_id
      message
      message_datetime_utc
      image_filename
      pinned
      guest {
        id
        name
      }
    }
  }
`;

const updateEmailMutation = `
  mutation UpdateEmail($email: String!) {
    updateEmail(
      email: $email
    ) {
      email
    }
  }
`;

const updateBroadcasterProfileMutation = `
  mutation UpdateBroadcasterProfile($details: BroadcasterProfileInput!) {
    updateBroadcasterProfile(
      details: $details
    ) {
      username
    }
  }
`;

const resetLogoMutation = `mutation UpdateBroadcasterProfile($broadcaster: String!) {
  updateBroadcasterLogo(broadcaster: $broadcaster, logo_path: "") {
    username
    logo_path
  }
}`;

const updateEventCoverMutation = `
  mutation UpdateEventCoverPath($broadcaster: String!, $slug: String!, $event_cover_path: String!) {
    updateEventCoverPath(broadcaster: $broadcaster, slug: $slug, event_cover_path: $event_cover_path) {
      ${eventFragment}
    }
  }
`;

// TODO: UNSECURE
const creditBroadcasterMutation = `
  mutation creditBroadcaster($broadcaster: String!, $credits: Int!) {
    creditBroadcaster(broadcaster: $broadcaster, credits: $credits) {
      username
      email
    }
  }`;

export const unlockWithCredit = async (slug, quantity) => {
  try {
    await API.graphql(
      graphqlOperation(publishEventMutation, {
        slug,
        quantity,
      })
    );
  } catch (err) {
    console.error(err);
  }
};

export const creditBroadcaster = async (broadcaster, purchase) => {
  let quantity = 1;
  let revenue = 50;
  switch (purchase.productId) {
    case "package_5":
      quantity = 5;
      revenue = 200;
      break;
    case "package_10":
      quantity = 10;
      revenue = 350;
      break;
    case "package_25":
      quantity = 25;
      revenue = 750;
      break;
  }

  try {
    await API.graphql(
      graphqlOperation(creditBroadcasterMutation, {
        broadcaster,
        credits: quantity,
      })
    );
    // TODO: move to serverside or RevenueCat
    await analytics.track("Purchase", {
      plarform: Platform.OS,
      revenue,
      source: "appstore",
      numEvents: quantity,
      transaction_id: purchase.transactionId,
    });
  } catch (err) {
    console.error(err);
  }
};

const cleanEmpty = (obj) => {
  const clone = { ...obj };
  Object.keys(clone).forEach((key) => (clone[key] = obj[key] || null));
  return clone;
};

export const updateProfile = async (details) => {
  try {
    await API.graphql(
      graphqlOperation(updateBroadcasterProfileMutation, {
        details: cleanEmpty({ broadcaster_role: null, ...details }),
      })
    );
  } catch (err) {
    console.error(err);
  }
};

// TODO: Security risk, make a new mutation
export const resetLogo = async (broadcaster) => {
  try {
    await API.graphql(graphqlOperation(resetLogoMutation, { broadcaster }));
  } catch (err) {
    console.error(err);
  }
};

export const publishEvent = async (slug, purchase, currency, price, coupon) => {
  const { transactionReceipt } = purchase;
  try {
    const {
      data: { publishEvent },
    } = await API.graphql(
      graphqlOperation(publishEventMutation, {
        slug,
        purchaseReceipt: transactionReceipt,
      })
    );

    console.log(publishEvent);
    return publishEvent;
  } catch (err) {
    console.error(err);
  }
};

const executeGraphQL = async (query, variables) => {
  try {
    const { data } = await API.graphql(graphqlOperation(query, variables));
    return data;
  } catch (res) {
    const e = res.errors ? res.errors : null;
    console.log(e);
    console.log("in query", query);
    console.log("variables", variables);

    sentry.withScope((scope) => {
      scope.setExtra("response", res);
      scope.setExtra("variables", variables);
      scope.setExtra("query", query);
      sentry.captureException(e);
    });

    throw new Error(
      "Could not load data. Please check your internet connection. Ensure the timezone, date and time on your device are correct."
    );
  }
};

export const createTest = async (broadcaster, force_new_test = false) => {
  const { createTestEvent } = await executeGraphQL(createTestEventMutation, {
    broadcaster,
    details: {
      title: "Test Event",
      event_timezone: moment.tz.guess(),
    },
    force_new_test,
  });

  return createTestEvent;
};

export const fetchEvents = async () => {
  const { myEvents } = await executeGraphQL(eventsQuery);
  return myEvents;
};

export const fetchNumberOfEvents = async () => {
  const {
    data: { myEvents },
  } = await API.graphql(graphqlOperation(eventsBareQuery));

  return {
    publishedEvents: myEvents.filter((e) => !e.is_test && e.is_published)
      .length,
    unpublishedEvents: myEvents.filter((e) => !e.is_test && !e.is_published)
      .length,
    testEvents: myEvents.filter((e) => e.is_test).length,
  };
};

export const fetchEvent = async (slug) => {
  const { data } = await API.graphql(graphqlOperation(myEventQuery, { slug }));
  return data;
};

export const fetchEventByStreamKey = async (stream_key) => {
  const {
    data: { event },
  } = await API.graphql(
    graphqlOperation(
      `
      query($stream_key: String!) {
        event: event_by_stream_key(stream_key: $stream_key) {
          ${eventFragment}
        }
      }
    `,
      { stream_key }
    )
  );

  return event;
};

export const deleteEvent = async (slug) => {
  await API.graphql(graphqlOperation(deleteEventMutation, { slug }));
  return true;
};

export const deleteGuestById = async (id) => {
  await API.graphql(graphqlOperation(deleteEventGuestMutation, { id }));
  return true;
};

export const fetchProfile = async () => {
  const {
    data: { broadcasterProfile },
  } = await API.graphql(graphqlOperation(myProfileQuery));
  return broadcasterProfile;
};

export const fetchAffiliate = async () => {
  const {
    data: { broadcasterProfile },
  } = await API.graphql(graphqlOperation(myProfileQueryAffiliate));
  return broadcasterProfile;
};

export const subscribeToEventUpdates = (broadcaster, slug, onUpdate) => {
  const subscription = API.graphql(
    graphqlOperation(subscriptionEventUpdated, {
      broadcaster,
      slug,
    })
  ).subscribe({
    next: ({ value }) => {
      onUpdate(value.data.eventUpdated);
    },
    error: (err) => console.log("error caught", err),
  });

  return () => {
    subscription.unsubscribe();
  };
};

//TODO: fix, this crashes on android in airplane mode
export const subscribeToEventsUpdates = (broadcaster, onUpdate) => {
  const subscription = API.graphql(
    graphqlOperation(subscriptionAnyEventUpdated, {
      broadcaster,
    })
  ).subscribe({
    next: ({ value }) => {
      onUpdate(value.data.eventUpdated);
    },
    error: (err) => console.log("error caught", err),
  });

  return () => {
    subscription.unsubscribe();
  };
};

export const createEvent = async ({
  title,
  event_datetime,
  event_timezone,
  event_type,
  // event_privacy,
  event_location,
  alias,
  days,
  // guestbook_enabled,
  // event_password,
  // download_enabled,
}) => {
  const event_date = moment(event_datetime).format("YYYY-MM-DD");
  const event_time = moment(event_datetime).format("HH:mm");

  const date = moment.tz(`${event_date} ${event_time}`, event_timezone);

  const event_datetime_utc = date.utc().format();

  const {
    errors,
    data: { createEvent: result },
  } = await API.graphql(
    graphqlOperation(createEventMutation, {
      details: {
        title,
        event_date,
        event_time,
        event_datetime_utc,
        event_timezone,
        event_location,
        guestbook_enabled: true,
        event_type,
        alias: alias.toLowerCase(),
        days,
      },
    })
  );

  return result;
};

export const deleteAccount = async () => {
  const res = await API.graphql(graphqlOperation(deleteAccountMutation, {}));
  return res;
};

export const resetEvent = async (slug) => {
  const {
    errors,
    data: { resetEvent: result },
  } = await API.graphql(
    graphqlOperation(resetTestMutation, {
      slug,
    })
  );

  return result;
};

export const updateEventDetails = async (event) => {
  const { slug, title, event_location, event_type, links } = event;

  const {
    data: { updateEventMeta },
  } = await API.graphql(
    graphqlOperation(updateEventMetaMutation, {
      slug,
      details: {
        title,
        event_location,
        links,
        event_type,
      },
    })
  );

  return {
    ...event,
    ...updateEventMeta,
  };
};

export const updateEventStripeDetails = async (slug, details) => {
  const {
    data: { updateEventStripeOptions },
  } = await API.graphql(
    graphqlOperation(updateEventStripeOptionsMutation, {
      slug,
      details,
    })
  );

  return {
    ...updateEventStripeOptions,
  };
};

export const updateEventDateTimeDetails = async (event) => {
  const { slug, event_timezone, event_datetime, days = 1 } = event;

  const event_date = moment(event_datetime).format("YYYY-MM-DD");
  const event_time = moment(event_datetime).format("HH:mm");

  const {
    data: { updateEventDateTime },
  } = await API.graphql(
    graphqlOperation(updateEventDateTimeMutation, {
      slug,
      details: {
        event_timezone,
        event_date,
        event_time,
        days,
      },
    })
  );

  return {
    ...event,
    ...updateEventDateTime,
  };
};

export const updateEventEmbedOptions = async ({ details, slug }) => {
  const {
    errors,
    data: { updateEventEmbedOptions: result },
  } = await API.graphql(
    graphqlOperation(updateEventEmbedOptionsMutation, {
      slug,
      details,
    })
  );

  return result;
};

export const updateEventAlias = async ({ alias, slug }) => {
  if (!alias) throw new Error("Link required");

  const {
    errors,
    data: { updateEventAlias: result },
  } = await API.graphql(
    graphqlOperation(updateEventAliasMutation, {
      slug,
      alias: alias.toLowerCase(),
    })
  );

  return result;
};

export const updateEventClip = async ({ slug, clipFrom, clipTo }) => {
  const {
    errors,
    data: { updateEventClip: result },
  } = await API.graphql(
    graphqlOperation(updateEventClipMutation, {
      slug,
      clipFrom,
      clipTo,
    })
  );

  return result;
};

export const updateEventPrivacy = async ({
  event_privacy,
  guestbook_enabled,
  event_password,
  download_enabled,
  prefer_chat,
  hide_viewer_count,
  slug,
  email_pref,
  live_only,
}) => {
  const details = {
    event_privacy,
    guestbook_enabled,
    event_password,
    download_enabled,
    prefer_chat,
    hide_viewer_count,
    email_pref,
    live_only,
  };

  const {
    errors,
    data: { updateEventPrivacy: result },
  } = await API.graphql(
    graphqlOperation(updateEventPrivacyMutation, {
      slug,
      details,
    })
  );

  if (errors) {
    throw new Error("Could not update privacy settings");
  }

  return result;
};

export const updateEventBranding = async ({
  slug,
  description,
  logo_path,
  company_name,
  company_url,
  custom_date_display,
}) => {
  const details = {
    description,
    logo_path,
    company_name,
    company_url,
    custom_date_display,
  };

  const {
    errors,
    data: { updateEventBranding: result },
  } = await API.graphql(
    graphqlOperation(updateEventBrandingMutation, {
      slug,
      details,
    })
  );

  if (errors) {
    throw new Error("Could not update branding settings");
  }

  return result;
};

export const fetchGuests = async (slug) => {
  const {
    data: { myEvent },
  } = await API.graphql(graphqlOperation(guestsQuery, { slug }));
  return myEvent;
};

export const checkAliasAvailable = async (alias) => {
  const {
    data: { event },
  } = await API.graphql(
    graphqlOperation(
      `
      query($alias: String!) {
        event: event_by_alias(alias: $alias) {
          id
        }
      }
    `,
      { alias }
    )
  );

  return !event;
};

export const getEventByAlias = async (alias) => {
  const {
    data: { event },
  } = await API.graphql(
    graphqlOperation(
      `
      query($alias: String!) {
        event: event_by_alias(alias: $alias) {
          ${eventFragment}
        }
      }
    `,
      { alias }
    )
  );

  return event;
};

export const inviteGuests = async (event, guests) => {
  const {
    data: { registerGuests },
    errors,
  } = await API.graphql(
    graphqlOperation(inviteGuestsMutation, {
      event_id: event.id,
      guests,
      sendInvite: true,
    })
  );

  return registerGuests;
};

export const fetchGuestbook = async (slug) => {
  const {
    data: { myEvent },
  } = await API.graphql(graphqlOperation(commentsQuery, { slug }));

  return myEvent;
};

export const deleteComment = async (event, id) => {
  const { errors, data: result } = await API.graphql(
    graphqlOperation(deleteCommentMutation, { event_id: event.id, id })
  );

  return result;
};

export const sendPinnedMessage = async (event, message) => {
  await API.graphql(
    graphqlOperation(sendPinnedMessageMutation, { event_id: event.id, message })
  );
};

export const resetThumbnail = async (event) => {
  const { broadcaster, slug } = event;
  await API.graphql(
    graphqlOperation(updateEventCoverMutation, {
      broadcaster,
      slug,
      event_cover_path:
        "https://eventliveprod-posters.s3.amazonaws.com/event-live-default.jpg",
    })
  );
};

export const resetVideo = async (slug) => {
  return await API.graphql(
    graphqlOperation(updateEventUploadStatusMutation, {
      slug,
      vod_filename: null,
      video_uploaded: false,
      notify_guests: false,
      vod_converted: false,
    })
  );
};

export const convertVideo = async (
  slug,
  vod_filename,
  notify_guests = false
) => {
  return await API.graphql(
    graphqlOperation(updateEventUploadStatusMutation, {
      slug,
      vod_filename,
      video_uploaded: true,
      notify_guests,
      vod_converted: false,
    })
  );
};

export const updateEmail = async ({ email }) => {
  const {
    errors,
    data: { updateEmail },
  } = await API.graphql(
    graphqlOperation(updateEmailMutation, { email: email.toLowerCase() })
  );

  if (errors) {
    throw new Error("Could not change email");
  }
  return updateEmail;
};
