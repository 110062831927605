import moment from "moment-timezone";
import React, { useState } from "react";
import { Dimensions, useWindowDimensions } from "react-native";
import { FlatGrid } from "react-native-super-grid";

import { isWeb, minDesktopWidth, phoneThreshold } from "../globalUtils";
import EventCard from "./EventCard";

const AppEventGrid = ({ events = [], now = moment(), navigation, gridRef }) => {
  // const elements = sectionedEvents(events, now);
  const onEventSettingPressed = (event, params = {}) => {
    navigation.push("EventEdit", {
      slug: event.slug,
      event,
      key: event.slug,
      ...params,
    });
  };
  const onGoLivePressed = async (event, params = {}) => {
    navigation.push("EventEdit", {
      slug: event.slug,
      event,
      key: event.slug,
      ...params,
    });
  };
  const { width } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > width;

  const margin = width > phoneThreshold ? 0 : 24;
  const itemDimension = isMobileUX ? Dimensions.get("window").width - 48 : 380;

  return (
    <FlatGrid
      ref={gridRef}
      showsVerticalScrollIndicator={true}
      showsHorizontalScrollIndicator={false}
      itemDimension={itemDimension}
      fixed
      data={events}
      spacing={10}
      itemContainerStyle={{ justifyContent: "flex-start" }}
      style={{
        marginTop: isWeb ? 20 : 0,
        paddingTop: isWeb ? 0 : 6,
        marginLeft: isWeb ? margin : null,
        marginRight: isWeb ? margin : null,
        marginBottom: 0,

        minHeight: "60%", // with the static container, there is an artifact when loading
        flex: 1,
      }}
      initialNumToRender={12}
      renderItem={(item) => (
        <EventCard
          onEventSettingPressed={onEventSettingPressed}
          onGoLivePressed={onGoLivePressed}
          now={now}
          key={item.item.stream_key}
          event={item.item}
        />
      )}
    />
  );
};

export default AppEventGrid;
