import { ActionSheet } from "native-base";
import { Platform } from "react-native";

export const confirmAction = (title, confirmCTA, onConfirm, onCancel) => {
  if (Platform.OS === "web") {
    const confirmed = window.confirm(title);
    if (confirmed) {
      onConfirm();
    }
  } else {
    ActionSheet.show(
      {
        options:
          Platform.OS === "android"
            ? [
                {
                  text: confirmCTA,
                },
                {
                  text: "Cancel",
                },
              ]
            : [confirmCTA, "Cancel"],
        cancelButtonIndex: 1,
        destructiveButtonIndex: 0,
        title,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          onConfirm && onConfirm();
        }
        if (buttonIndex === 1) {
          onCancel && onCancel();
        }
      }
    );
  }
};
