import moment from "moment-timezone";
import { Text, Toast } from "native-base";
import React, { useEffect, useState } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";

import { API, graphqlOperation } from "aws-amplify";

import AppActivityIndicator from "./AppActivityIndicator";
import { showActionSheet } from "../Utils";

import {
  deleteComment,
  fetchGuestbook,
  guestbookSubscription,
} from "../http/events";

import { TrashCanButton } from "./Buttons";

import GuestbookPinnedMessageForm from "./GuestbookPinnedMessageForm";

const styles = StyleSheet.create({
  threeDots: {
    position: "absolute",
    right: 0,
    top: 0,
    paddingHorizontal: 20,
  },
  messagesContainer: {
    paddingVertical: 15,
    borderTopColor: "rgba(4, 13, 18, 0.15)",
    borderTopWidth: 1,
    marginTop: 10,
  },
  guestName: {
    fontWeight: "bold",
  },
  guestbookMessage: {
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 14,
    backgroundColor: "#f5f5f5",
    marginBottom: 10,
    padding: 20,
  },
});

const RESIZED_BUCKET =
  "https://eventlive-guest-upload-resized.s3.amazonaws.com";

const resizedImageUrl = (image_filename) => {
  const split = image_filename.split(".");
  split.pop();
  const name = split.join("");
  return `${RESIZED_BUCKET}/${name}.jpg`;
};

const GuestBook = ({ event: initialEvent }) => {
  const [loading, setloading] = useState(true);
  const { slug } = initialEvent;

  const [event, setEvent] = useState(initialEvent);

  const event_id = event.id;

  const fetchAsync = async () => {
    try {
      const myEvent = await fetchGuestbook(slug);
      if (!myEvent) {
        throw new Error("Not found");
      }
      setEvent(myEvent);
      setloading(false);
    } catch (err) {
      console.error(err);
      // Show Banner not found and redirect to LIST
      Toast.show({
        text: "Could not load the event",
        position: "bottom",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    fetchAsync();
  }, [slug]);

  const onMessageDelete = (id) => () => {
    showActionSheet(
      "Delete this message?",
      async () => {
        setEvent({
          ...event,
          guestbook_messages: event.guestbook_messages.filter(
            (m) => m.id !== id
          ),
        });
        // Delete comment
        await deleteComment(event, id);
      },
      "Delete?"
    );
  };

  useEffect(() => {
    if (event_id) {
      const subscription = API.graphql(
        graphqlOperation(guestbookSubscription, { event_id })
      ).subscribe({
        next: (newMessage) => {
          fetchAsync();
        },
        error: (err) => console.log("error caught", err),
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [event_id]);

  const messages = (event.guestbook_messages || []).sort((a, b) => {
    return a.message_datetime_utc > b.message_datetime_utc ? -1 : 1;
  });

  const renderMessage = (item) => (
    <View style={styles.guestbookMessage} key={item.id}>
      <Text style={styles.guestName}>
        {item.pinned && `You pinned`}
        {!item.pinned &&
          `${item.guest && item.guest.name} ${
            item.guest && item.guest.email && `(${item.guest.email})`
          }`}
      </Text>
      <Text>{moment(item.message_datetime_utc).fromNow()}</Text>
      <Text>{item.message}</Text>
      {item.image_filename && (
        <Image
          resizeMode="contain"
          source={{ uri: resizedImageUrl(item.image_filename) }}
          style={{ width: "100%", maxWidth: 300, height: 300 }}
        />
      )}

      <TrashCanButton style={{}} onPress={onMessageDelete(item.id)} />
    </View>
  );

  return (
    <ScrollView
      style={{
        flex: 1,
        flexDirection: "column",
        width: "100%",
        alignSelf: "center",
      }}
    >
      <GuestbookPinnedMessageForm event={event} />

      <View style={styles.messagesContainer}>
        {messages.filter((a) => a.pinned).map(renderMessage)}
        {messages.filter((a) => !a.pinned).map(renderMessage)}
      </View>
    </ScrollView>
  );
};

export default GuestBook;
