import React, { useRef, useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Toast } from "native-base";
import { useWindowDimensions } from "react-native";
import { isWeb, minDesktopWidth } from "../globalUtils";

const AutoSave = ({
  debounceMs = 1000,
  submitForm,
  isSubmitting,
  errors,
  values,
}) => {
  const [lastSaved, setLastSaved] = useState(null);
  const didMountRef = useRef(false);
  const { width: responsiveWidth } = useWindowDimensions();
  const isMobileUX = minDesktopWidth > responsiveWidth;

  const debouncedSubmit = useDebouncedCallback(async () => {
    if (Object.keys(errors).length === 0) {
      try {
        const r = await submitForm();
        setLastSaved(new Date().toISOString());
        Toast.show({
          text: "Saved Automatically",
          position: isMobileUX ? "top" : "top-right",
          type: "success",
          style: { marginTop: 30 },
        });
      } catch (err) {
        console.error(err);
        Toast.show({
          text: `Error while saving, ${err.toString()}`,
          position: isMobileUX ? "top" : "top-right",
          type: "danger",
          duration: 5000,
        });
      }
    } else {
      console.log("errors in auto-save", errors);
    }
  }, debounceMs);

  useDeepCompareEffect(() => {
    if (didMountRef.current) {
      debouncedSubmit();
    }
    didMountRef.current = true;
  }, [debouncedSubmit, values]);

  useEffect(() => {
    if (isSubmitting) {
      Toast.show({
        text: "Saving...",
        position: isMobileUX ? "top" : "top-right",
        type: "success",
        style: { marginTop: 30 },
      });
    }
  }, [isSubmitting, isMobileUX]);

  return null;
};

export default AutoSave;
