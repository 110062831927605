import { useFormik } from "formik";
import { Container, View, Toast } from "native-base";
import React from "react";
import { useAuth } from "@/components/AuthContext";
import AppActivityIndicator from "../../shared/AppActivityIndicator";
import { Auth } from "aws-amplify";
import AsyncStorage from "@react-native-async-storage/async-storage";

import AppBrand from "@/components/shared/AppBrand";
import AppHeader from "@/components/shared/AppHeader";
import AppInput from "@/components/shared/AppInput";
import AppSpacing from "@/components/shared/AppSpacing";
import AppSubtitle from "@/components/shared/AppSubtitle";
import { AppBackButton } from "@/components/shared/Buttons";

import AppTitle from "@/components/shared/AppTitle";
import OnboardingButton from "@/components/shared/OnboardingButton";
import OnboardingContent from "@/components/shared/OnboardingContent";
import { EmailSchema } from "../Schemas";

const ForgotPassword = ({ navigation, route }) => {
  const { isSignedOut } = useAuth();

  const f = useFormik({
    initialValues: {
      email: route?.params?.email || "",
    },
    validationSchema: EmailSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);

      const { email } = values;

      Auth.forgotPassword(email.toLowerCase())
        .then((v) => {
          console.log(v);
          console.log("Navigating to checkemail");

          AsyncStorage.setItem("userToken", "123456789").finally(() => {
            navigation.navigate("ForgotPasswordLinkSent");
          });
        })
        .catch((err) => {
          actions.setSubmitting(false);
          actions.setStatus({ errorMsg: err.message });

          Toast.show({
            text: err.message,
            position: "top",
            type: "danger",
            duration: 5000,
          });
        });
    },
  });

  return (
    <Container>
      <AppHeader
        hasTitle={false}
        hasRightButton={false}
        leftButtonComponent={
          <AppBackButton
            onPress={() => {
              return isSignedOut
                ? navigation.navigate("SignUpSignIn")
                : navigation.navigate("Profile");
            }}
          />
        }
      />
      <OnboardingContent>
        <AppBrand />
        <View style={{ flex: 1 }}>
          <AppSpacing height="8%" />
          <AppTitle text={"Password Restore"} />
          <AppSubtitle
            text={
              "Enter the email associated with your account and we’ll send an email with instructions to restore your password"
            }
            style={{
              textAlign: "left",
            }}
          />

          <AppSpacing height="10%" />

          {f.isSubmitting && <AppActivityIndicator />}

          {!f.isSubmitting && (
            <>
              <AppInput
                autoFocus
                textContentType="emailAddress"
                keyboardType="email-address"
                onChangeText={f.handleChange("email")}
                onBlur={f.handleBlur("email")}
                value={f.values.email}
                placeholder="Email"
                autoCorrect={true}
                spellCheck={false}
                onSubmitOnEnter={() => f.handleSubmit()}
                errorMessage={
                  f.errors.email && f.touched.email ? f.errors.email : null
                }
                autoCapitalize="none"
              />

              <AppSpacing height="1%" />

              <OnboardingButton
                text={"Send email"}
                onPress={f.handleSubmit}
                disabled={f.isSubmitting}
                type="submit"
                primary
                style={{
                  marginTop: 25,
                  marginBottom: 10,
                }}
              />
            </>
          )}
        </View>
      </OnboardingContent>
    </Container>
  );
};

export default ForgotPassword;
