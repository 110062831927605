import { Text } from "native-base";
import React from "react";
import {StyleSheet} from "react-native";

import { isWeb } from "../globalUtils";

const styles = StyleSheet.create({
  disclaimer: {
    color: "#999999",
    fontSize: 13,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "center",
    letterSpacing: -0.36,
    lineHeight: isWeb ? "normal" : null,
  },
});

const TextDisclaimer = ({ children, style }) => (
  <Text style={[styles.disclaimer, style]}>{children}</Text>
);

export default TextDisclaimer;