import * as Clipboard from "expo-clipboard";
import { Formik, Form } from "formik";
import { Left, ListItem, Right, Text, Toast } from "native-base";
import React, { useEffect } from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { updateEventPrivacy } from "../http/events";
import { PrivacySchema } from "../screens/Schemas";
import AppInput from "./AppInput";
import { TextLink } from "./TextLink";
import AppRadio from "./AppRadio";
import AppSwitch from "./AppSwitch";
import AutoSave from "./AutoSave";
import FormSubtitle from "./FormSubtitle";
import OnboardingButton from "../shared/OnboardingButton";
import { confirmAction } from "./confirmAction";

const EventPrivacyForm = ({ event }) => {
  const {
    slug,
    event_privacy,
    guestbook_enabled,
    event_password,
    download_enabled,
    stream_key,
    prefer_chat,
    is_test,
    email_pref,
    live_only,
    hide_viewer_count,
    is_published,
  } = event;

  const navigation = useNavigation();

  return (
    <Formik
      initialValues={{
        event_privacy,
        guestbook_enabled,
        event_password,
        download_enabled,
        prefer_chat: prefer_chat || false,
        live_only: live_only || false,
        hide_viewer_count: hide_viewer_count || false,
        email_pref: email_pref || "guestbookreminder",
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await updateEventPrivacy({ slug, ...values });
        } catch (err) {
          throw new Error("Could not update privacy settings");
        }
        actions.setSubmitting(false);
      }}
      validationSchema={PrivacySchema}
    >
      {(f) => (
        <>
          <AutoSave {...f} />
          {!is_test && (
            <>
              <ListItem style={{ flexDirection: "column", marginLeft: 0 }}>
                <AppRadio
                  selected={f.values.event_privacy === "unlisted"}
                  onSelect={() => f.setFieldValue("event_privacy", "unlisted")}
                  label="Anyone with the link can watch"
                />

                <AppRadio
                  selected={f.values.event_privacy === "password_protected"}
                  onSelect={() =>
                    f.setFieldValue("event_privacy", "password_protected")
                  }
                  label="Password protected"
                />

                {f.values.event_privacy === "password_protected" && (
                  <AppInput
                    style={{ width: "100%", marginBottom: 23 }}
                    onChangeText={f.handleChange("event_password")}
                    onBlur={f.handleBlur("event_password")}
                    value={f.values.event_password}
                    placeholder="Password"
                    onSubmitOnEnter={() => f.handleSubmit()}
                    errorMessage={
                      f.errors.event_password && f.touched.event_password
                        ? f.errors.event_password
                        : null
                    }
                  />
                )}

                <AppRadio
                  selected={f.values.event_privacy === "invite_only"}
                  onSelect={() =>
                    f.setFieldValue("event_privacy", "invite_only")
                  }
                  label="Invite-Only"
                />
                {f.values.event_privacy === "invite_only" && (
                  <View style={{}}>
                    <Text>
                      Only the guests invited by email can join. One device per
                      email at a time. (MUST be on the{" "}
                      <TextLink
                        text={"Guest List"}
                        onPress={() => {
                          navigation.navigate("GuestList", { slug });
                        }}
                      />
                      )
                    </Text>
                  </View>
                )}
              </ListItem>
            </>
          )}

          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text style={{ paddingLeft: 5 }}>
                Require guests to enter their name and email to attend
              </Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={
                  f.values.email_pref === "askall" ||
                  f.values.event_privacy === "invite_only"
                }
                disabled={f.values.event_privacy === "invite_only"}
                onToggle={(value) => {
                  if (value) f.setFieldValue("email_pref", "askall");
                  else f.setFieldValue("email_pref", "guestbookreminder");
                }}
              />
            </Right>
          </ListItem>

          <FormSubtitle style={{ marginTop: 20 }}>Messaging</FormSubtitle>

          <ListItem style={{ flexDirection: "column", marginLeft: 0 }}>
            <AppRadio
              selected={!!f.values.guestbook_enabled && !f.values.prefer_chat}
              onSelect={() => {
                f.setFieldValue("guestbook_enabled", true);
                f.setFieldValue("prefer_chat", false);
              }}
              label="Guestbook"
            />
            <AppRadio
              selected={!!f.values.guestbook_enabled && !!f.values.prefer_chat}
              onSelect={() => {
                f.setFieldValue("guestbook_enabled", true);
                f.setFieldValue("prefer_chat", true);
              }}
              label="Chat"
            />
            <AppRadio
              selected={!f.values.guestbook_enabled}
              onSelect={() => {
                f.setFieldValue("guestbook_enabled", false);
              }}
              label="Disable messaging"
            />
          </ListItem>

          <FormSubtitle style={{ marginTop: 20 }}>
            Replay and Download
          </FormSubtitle>

          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text style={{ paddingLeft: 5 }}>
                Live-Only (no replay, no rewind)
              </Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={!!f.values.live_only}
                onToggle={(value) => {
                  f.setFieldValue("live_only", !!value);
                }}
              />
            </Right>
          </ListItem>

          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text style={{ paddingLeft: 5 }}>
                Show download link to guests
              </Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={!!f.values.download_enabled}
                onToggle={(value) => f.setFieldValue("download_enabled", value)}
              />
            </Right>
          </ListItem>

          <ListItem style={{ marginLeft: 0 }}>
            <Left>
              <Text style={{ paddingLeft: 5 }}>Hide viewer counter</Text>
            </Left>
            <Right>
              <AppSwitch
                isOn={!!f.values.hide_viewer_count}
                onToggle={(value) =>
                  f.setFieldValue("hide_viewer_count", value)
                }
              />
            </Right>
          </ListItem>

          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
          >
            {is_published && (
              <OnboardingButton
                onPress={() => {
                  if (f.values.event_privacy === "unpublished") {
                    f.setFieldValue("event_privacy", "unlisted");
                  } else {
                    confirmAction(
                      "Are you sure? Event will not be accessible to guests",
                      "Unpublish Event",
                      () => f.setFieldValue("event_privacy", "unpublished")
                    );
                  }
                }}
                text={
                  f.values.event_privacy === "unpublished"
                    ? "Publish"
                    : "Un-publish"
                }
                style={{ width: 200, flex: 1, marginLeft: 10 }}
              />
            )}
          </View>
        </>
      )}
    </Formik>
  );
};

export default EventPrivacyForm;
