import { isBrowser } from "react-device-detect";
import { Dimensions, Platform, StyleSheet } from "react-native";

import { IsAppleUniverse, IsWebFull } from "./globalUtils";

const brandColor = "#F84123"; // "#f4553b"; // "#f46a54"; //  "#E94A24";
const buttonGray = "#F2F2F2";
const buttonGrayLight = "#aeaeb2";
const buttonGrayText = "#8B8B8D";

//title: avenir 85 heavy  // "Avenir Heavy"  Regular: Avenir
//font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;

const defaultFont =
  Platform.OS === "ios"
    ? "System"
    : IsAppleUniverse
    ? "-apple-system, BlinkMacSystemFont"
    : "Nunito_regular";
const defaultTitleFont =
  Platform.OS === "ios"
    ? "System"
    : IsAppleUniverse
    ? "-apple-system, BlinkMacSystemFont"
    : "Nunito_bold";

const iconColor = "#F2F2F2";
const iconColorDark = "#8B8B8D";

const contentSidePadding = 10;
const inputLeftPadding = 15;
const inputIconMargin = 6;
const containerMinWidth = 360;
const eventCarRowAmount = 3.0;

export const StyleConstants = {
  defaultBrandColor: brandColor,
  defaultBrandGray: buttonGrayText,
  defaultBrandGrayLight: buttonGrayLight,
  defaultFont: defaultFont,
  defaultButtonFont: defaultFont,
  defaultTitleFont: defaultTitleFont,
  defaultIconColor: iconColor,
  defaultIconColorDark: iconColorDark,
};

const inputIcon = {
  marginRight: inputIconMargin,
  // paddingLeft: 16,
  paddingRight: 16,
  color: iconColorDark,
};

const appItem = {
  marginTop: 5,
  marginBottom: 5,
  marginLeft: 0,
  marginRight: 0,
  // borderColor: brandColor
};

const appInput = {
  // borderColor: 'transparent'
  margin: Platform.OS === "ios" ? 2 : 0,
  fontSize: 17,
};

//TODO: Add this in the native base theme code
if (Platform.OS === "web") {
  appInput.outlineColor = "transparent";
  appInput.outlineWidth = 0;
}

const appContent = {
  paddingLeft: contentSidePadding,
  paddingRight: contentSidePadding,
};

//Event grid specific params
const appContentEventScreen = {};

if (IsWebFull) {
  const { width, height } = Dimensions.get("screen");
  console.log("DImensions", width, height);
  // appContent.paddingLeft =  isBrowser   ? '20%': 0;
  // appContent.paddingRight = isBrowser? '20%': 0;
  appContent.minWidth = isBrowser ? containerMinWidth : null;
  appContent.width = isBrowser ? "40%" : null;
  appContent.maxWidth = 580;
  appContent.alignSelf = "center";

  appContentEventScreen.minWidth = isBrowser ? containerMinWidth : null;
  appContentEventScreen.width = isBrowser ? "55%" : null;
  appContentEventScreen.maxWidth = containerMinWidth * eventCarRowAmount;
  appContentEventScreen.alignSelf = "center";
}

const GlobalStyles = StyleSheet.create({
  content: {
    flex: 1,
    alignContent: "space-around",
    flexGrow: 1,
    ...appContent,
  },
  contentEventScreen: {
    flex: 1,
    alignContent: "space-around",
    flexGrow: 1,
    ...appContentEventScreen,
  },
  iconStyle: {
    color: brandColor,
  },
  iconFocus: {
    ...inputIcon,
  },
  icon: {
    ...inputIcon,
  },
  buttonTextStyle: {
    fontFamily: defaultFont,
    color: brandColor,
  },
  textStyle: {
    color: "black",
    fontFamily: defaultFont,
  },
  textSmall: {
    fontSize: 12,
    textAlign: "center",
  },
  textBranded: {
    color: brandColor,
  },
  textBrandedHeavy: {
    color: brandColor,
    fontFamily: defaultTitleFont,
  },
  textHeavy: {
    fontFamily: defaultTitleFont,
    fontWeight: "800",
  },
  actionButtonStyle: {
    borderRadius: 9,
    margin: 15,
  },
  actionButtonTextStyle: {
    color: "white",
    fontFamily: defaultFont,
    fontSize: 16,
  },

  grayActionButton: {
    backgroundColor: buttonGray,
    borderRadius: 9,
    margin: 10,
  },

  grayActionButtonText: {
    color: buttonGrayText,
    padding: 20,
    fontFamily: defaultFont,
  },

  grayActionIcon: {
    backgroundColor: buttonGray,
    borderRadius: 9,
    margin: 10,
  },

  grayActionIconText: {
    color: buttonGrayText,
    // padding: 20,
  },

  container: {
    flex: 1,
  },
  appIconButton: {
    marginHorizontal: 4,
    width: 46,
    // height: 44,
    justifyContent: "center",
    color: "red",
  },
  appIconButtonIcon: {
    // padding: 0,
    // width: 44,
    // height: 44,
    justifyContent: "center",
    color: "black",
    fontSize: 26,
  },

  appItem: {
    backgroundColor: "#fff",
    borderColor: "#b7b7b7",
    borderWidth: 0.5,
    ...appItem,
  },
  appItemFocused: {
    backgroundColor: "#ffffff",
    ...appItem,
    borderColor: "#949594",
  },
  appInput: {
    // backgroundColor: "#f2f2f2",
    paddingLeft: inputLeftPadding,
    paddingRight: 0,
    ...appInput,
  },
  appInputFocused: {
    // backgroundColor: "#ffffff",
    paddingLeft: inputLeftPadding,
    paddingRight: 0,
    ...appInput,
  },
  appInputErrorMessage: {
    // backgroundColor:'#f2f2f2',
    // paddingLeft: inputLeftPadding,
    // paddingRight: 0,
    textAlign: "left",
    marginBottom: 4,
    color: "red",
    fontSize: 12,
    lineHeight: 14,
    height: 12,
  },
  disclaimerContainer: {
    marginTop: 15,
    marginBottom: 10,
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  forgotPasswordLinkContainer: {
    marginTop: 10,
    marginBottom: 10,
    // flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default GlobalStyles;
