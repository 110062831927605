import React from "react";

// import { Picker } from "@react-native-picker/picker";
import { Item, Label, Picker } from "native-base";

import GlobalStyles from "../globalStyle";
import { Platform } from "react-native";

const AppPicker = React.memo(
  ({ values, label, itemProps = [], ...pickerProps }) => (
    <Item
      error
      rounded
      fixedLabel
      accessible={false}
      style={[GlobalStyles.appItem]}
      inlineLabel
      {...itemProps}
    >
      <Label style={{ marginLeft: 15, flex: 1, flexGrow: 1, color: "#000" }}>
        {label}
      </Label>
      <Picker
        mode={Platform.OS === "android" ? "dialog": "dropdown"}
        style={[
          GlobalStyles.appInput,
          {
            height: 50,
            borderWidth: 0,
            borderRadius: 14,
            backgroundColor: "transparent",
            marginRight: 16,
            flexGrow: 0,
            width: "60%",
            textAlign: "right",
            textAlignLast: "right",
            WebkitAppearance: "none",
          },
        ]}
        // selectedValue={this.state.selected}
        // onValueChange={this.onValueChange.bind(this)}
        {...pickerProps}
      >
        {values.map(({ value, name }) => (
          <Picker.Item label={name} value={value} key={value} />
        ))}
      </Picker>
    </Item>
  )
);

export default AppPicker;
