import React, { useEffect, useRef } from "react";
import { ActivityIndicator, Animated, View } from "react-native";
import { StyleConstants } from "@/components/globalStyle";
import { Review } from "@/components/shared/PayWall";
import reviews from "@/components/reviews.json";

export const LoadingScreen = () => {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const timer = setTimeout(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start();
    }, 500);
    return () => clearTimeout(timer);
  }, [fadeAnim]);

  const currentReview = reviews[Math.floor(Math.random() * reviews.length)];

  return (
    <View
      style={{
        flex: 1,
        margin: 30,
        alignItems: "center",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <ActivityIndicator
        color={StyleConstants.defaultBrandColor}
        size={"large"}
      />

      <Animated.View style={{ opacity: fadeAnim }}>
        <Review {...currentReview} />
      </Animated.View>
    </View>
  );
};
