let branch, BranchEvent;

import { Platform, Alert } from "react-native";

if (Platform.OS !== "web") {
    branch = require("react-native-branch").default;
    BranchEvent = require("react-native-branch").BranchEvent;
} else {
    branch = require("branch-sdk");
}

branch.initSessionTtl = 10000;

export const initBranch = async () => {
  if (Platform.OS === "web") { 
    await new Promise((resolve, reject) => {
        branch.init(
        "key_live_jlJxdDWKXKSgKXpBRnv3uhkpyrpjZfim",
        function (err, data) {
            if (err) {
              reject(err);
            } else {
              resolve(data?.data_parsed || {});
            }
        }
        );
    }); 
  } else {
    const params = await branch.getFirstReferringParams(); 

    // Alert.alert("first params", JSON.stringify(params));
    let lastParams = await branch.getLatestReferringParams() 
    // Alert.alert("last params", JSON.stringify(lastParams));
    return params;
  }
}

// export const makeLink = async (data) => {
//     const linkData = { data };


//     if (Platform.OS === "web") { 
//         const link = await new Promise((resolve, reject) => {
//             branch.link(linkData, function (err, link) {
//                 if (err) {
//                 reject(err);
//                 } else {
//                 resolve(link);
//                 }
//             });
//         });
//         return link;
//     } else {
//         //TODO
//     }
// }

// export const createStreamForLink = async ({ stream_key, title }) => {
//   if (Platform.OS !== "web") {
//     let branchUniversalObject = await branch.createBranchUniversalObject(
//       `/streamFor?streamKey=${stream_key}`,
//       {
//         locallyIndex: true,
//         title,
//         contentDescription: "Go Live for the event",
//       }
//     );

//     let shareOptions = {
//       messageHeader: "Check this out",
//       messageBody: "No really, check this out!",
//     };
//     let linkProperties = { feature: "share", channel: "RNApp" };
//     let controlParams = {
//       $desktop_url: "http://example.com/home",
//       $ios_url: "http://example.com/ios",
//     };
//     let { channel, completed, error } =
//       await branchUniversalObject.showShareSheet(
//         shareOptions,
//         linkProperties,
//         controlParams
//       );
//   } else {
//   }
// };

export default branch;
// export BranchEvent;
