import { FieldArray, FormikProvider, Formik, Form } from "formik";
import moment from "moment-timezone";
import React from "react";
import { Platform, View } from "react-native";

import AppConfig from "../../AppConfig";
import { timezones } from "../globalUtils";
import { updateEventDetails } from "../http/events";
import { EventSchema } from "../screens/Schemas";
import AppDatePicker from "../shared/AppDatePicker";
import AppInput from "../shared/AppInput";
import { TextLink } from "./TextLink";
import FormSubtitle from "../shared/FormSubtitle";
import OnboardingButton from "../shared/OnboardingButton";
import AppPicker from "./AppPicker";
import UploadFile from "./UploadFile";
import AutoSave from "./AutoSave";

const EventDetailsForm = ({ event, prefer_multiple_tests }) => {
  const {
    slug,
    title,
    event_type,
    event_location,
    event_datetime_utc,
    event_timezone,
    links,
    is_test,
    broadcaster,
  } = event;

  //Convert back to local date & time for editing:
  const event_datetime = new Date(
    moment
      .tz(new Date(event_datetime_utc), event_timezone)
      .format("YYYY-MM-DDTHH:mm:00")
  );

  // const updateTimeZone = useMemo(() => f.handleChange("event_timezone"), []);
  const type =
    Platform.OS === "web"
      ? "image/*, application/msword, application/pdf, text/*, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      : "*/*";

  return (
    <Formik
      initialValues={{
        title,
        event_type: event_type || "wedding",
        event_datetime,
        event_timezone,
        event_location: event_location || "",
        links: links || [],
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await updateEventDetails(
            { slug, ...values },
            values.event_datetime.toString() !== event_datetime.toString() ||
              values.event_timezone !== event_timezone
          );
          actions.setSubmitting(false);
        } catch (err) {
          console.error(err);
          actions.setSubmitting(false);
        }
      }}
      validationSchema={EventSchema}
    >
      {(f) => (
        <>
          <View style={{ marginBottom: 40 }}>
            <AutoSave {...f} />
            <AppInput
              onChangeText={f.handleChange("title")}
              onBlur={f.handleBlur("title")}
              value={f.values.title}
              placeholder="Event Title *"
              disabled={!!is_test && !prefer_multiple_tests}
              onSubmitOnEnter={() => f.handleSubmit()}
              errorMessage={
                f.errors.title && f.touched.title ? f.errors.title : null
              }
            />

            <AppInput
              onChangeText={f.handleChange("event_location")}
              onBlur={f.handleBlur("event_location")}
              value={f.values.event_location}
              placeholder="Location"
              onSubmitOnEnter={() => f.handleSubmit()}
              errorMessage={
                f.errors.event_location && f.touched.event_location
                  ? f.errors.event_location
                  : null
              }
            />

            <FormSubtitle style={{ marginTop: 40, marginBottom: 20 }}>
              Add links or upload attachments to your event page. Link to
              registry, website, PayPal, Cash App, Venmo. Upload PDF or Word
              documents.
            </FormSubtitle>

            <FormikProvider value={f}>
              <FieldArray
                name="links"
                render={(arrayHelpers) => (
                  <>
                    {f.values.links &&
                      f.values.links.map((link, index) => {
                        return (
                          <View key={index}>
                            <AppInput
                              onChangeText={f.handleChange(
                                `links.${index}.title`
                              )}
                              onBlur={f.handleBlur(`links.${index}.title`)}
                              value={link.title}
                              placeholder={
                                link.file_upload
                                  ? "* File Title (e.g. Event Program)"
                                  : "* Link Description (e.g. Our Registry)"
                              }
                              errorMessage={
                                f.errors.links?.[index]?.title || null
                              }
                              innerRef={(i) => console.log(i)}
                            />

                            <AppInput
                              onChangeText={f.handleChange(
                                `links.${index}.url`
                              )}
                              onBlur={f.handleBlur(`links.${index}.url`)}
                              value={link.url}
                              placeholder="* Link (e.g. zola.com/our_registry)"
                              autoCapitalize="none"
                              disabled={!!link.file_upload}
                              iconRightName={
                                link.file_upload
                                  ? "document-outline"
                                  : "link-outline"
                              }
                              errorMessage={
                                f.errors.links?.[index]?.url || null
                              }
                            />

                            <TextLink
                              text={
                                link.file_upload ? "Remove File" : "Remove Link"
                              }
                              onPress={() => arrayHelpers.remove(index)}
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                marginBottom: 15,
                              }}
                            />
                          </View>
                        );
                      })}

                    <OnboardingButton
                      dark
                      bordered
                      icon="ios-link"
                      onPress={() =>
                        arrayHelpers.push({
                          url: "",
                          title: "",
                          file_upload: false,
                        })
                      }
                      text={
                        f.values.links?.length
                          ? "Add another Link"
                          : "Add a Link"
                      }
                    />

                    <UploadFile
                      type={type}
                      buttonTitle={"Upload a Document"}
                      path={`${broadcaster}/${slug}`}
                      bucket={AppConfig.REACT_APP_FILEUPLOAD_BUCKET}
                      dark
                      bordered
                      style={{ marginBottom: 40 }}
                      onComplete={async ({ url, name }) => {
                        arrayHelpers.push({
                          url,
                          title: name,
                          file_upload: true,
                        });
                        // Focus on new empty file title field
                      }}
                    />
                  </>
                )}
              />
            </FormikProvider>
          </View>
        </>
      )}
    </Formik>
  );
};
export default EventDetailsForm;
