import { FieldArray, FormikProvider, Formik, Form } from "formik";
import moment from "moment-timezone";
import React from "react";
import { Platform, View } from "react-native";
import { Toast } from "native-base";
import { timezones } from "../globalUtils";
import { updateEventDateTimeDetails } from "../http/events";
import { EventDateTimeSchema } from "../screens/Schemas";
import AppDatePicker from "../shared/AppDatePicker";
import OnboardingButton from "../shared/OnboardingButton";
import AppPicker from "./AppPicker";
import MultiDaySelector from "./MultiDaySelector";

const EventDateTimeForm = ({ event }) => {
  const {
    slug,
    event_datetime_utc,
    event_timezone,
    broadcaster,
    days = 1,
  } = event;

  //Convert back to local date & time for editing:
  const event_datetime = new Date(
    moment
      .tz(new Date(event_datetime_utc), event_timezone)
      .format("YYYY-MM-DDTHH:mm:00")
  );

  return (
    <Formik
      initialValues={{
        event_datetime,
        event_timezone,
        days,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          const updated = await updateEventDateTimeDetails({ slug, ...values });
          actions.resetForm({ values: updated });
          Toast.show({
            text: "Re-scheduled successfully",
            position: "top",
            type: "success",
            style: { marginTop: 30 },
          });
        } catch (err) {
          console.error(err);
          actions.setSubmitting(false);
          Toast.show({
            text: String(err),
            position: "top",
            type: "danger",
            style: { marginTop: 30 },
            duration: 5000,
          });
        }
      }}
      validationSchema={EventDateTimeSchema}
    >
      {(f) => (
        <>
          <View style={{ marginBottom: 40 }}>
            <AppDatePicker
              selected={f.values.event_datetime}
              selectedValue={f.values.event_datetime}
              onChange={(value) => {
                f.setFieldValue("event_datetime", value);
              }}
              popperPlacement="top"
              onBlur={f.handleBlur("event_datetime")}
              placeholderText="Event Date and Time *"
              errorMessage={
                f.errors.event_datetime
                  ? "Please provide event's local Date and Time"
                  : null
              }
            />
            <AppPicker
              label="Timezone"
              values={timezones}
              selectedValue={f.values.event_timezone}
              onValueChange={f.handleChange("event_timezone")}
            />

            <MultiDaySelector f={f} />

            {f.dirty && (
              <OnboardingButton
                disabled={f.isSubmitting}
                onPress={() => {
                  f.submitForm();
                }}
                text={
                  f.touched.event_datetime || f.touched.event_timezone
                    ? "Re-schedule"
                    : "Update Days"
                }
              />
            )}
          </View>
        </>
      )}
    </Formik>
  );
};
export default EventDateTimeForm;
