import { Content } from "native-base";
import React from "react";
import { KeyboardAvoidingView } from "react-native";
// import { isWeb, minDesktopWidth } from "../../globalUtils";

const OnboardingContent = ({ children, wide = false }) => {
  // const isMobileUX = minDesktopWidth > responsiveWidth;

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={{ flex: 1 }}
      keyboardVerticalOffset={-260}
    >
      <Content
        padder
        contentContainerStyle={{
          alignContent: "space-around",
          flexGrow: 1,
          flex: 1,
          alignSelf: "center",
          justifyContent: "center",
          maxWidth: wide ? 660 : 440, //1294
          width: "100%",
          padding: 20,
        }}
        style={{
          flex: 1,
        }}
      >
        {children}
      </Content>
    </KeyboardAvoidingView>
  );
};

export default OnboardingContent;
