// DO NOT REMOVE:
import { Amplify, API, graphqlOperation } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { AppState, DeviceEventEmitter } from "react-native";

import { fetchEvent, subscriptionEventUpdated } from "../http/events";

const useSubscribeToEventUpdates = (initialEvent = null, fetchFirst = true) => {
  const broadcaster = initialEvent ? initialEvent.broadcaster : null;
  const slug = initialEvent ? initialEvent.slug : null;
  const [refreshEvent, setrefreshEvent] = useState(false);
  const [event, setEvent] = useState(initialEvent);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(fetchFirst);

  const eventRef = useRef(event);
  useEffect(() => {
    eventRef.current = event;
  }, [event, slug]);

  useEffect(() => {
    let subscription;

    const refetch = async (silent = false) => {
      try {
        if (!silent) setloading(true);
        const { myEvent } = await fetchEvent(slug);

        if (!myEvent) {
          setError(true);
          throw new Error("Could not load");
        }
        setEvent(myEvent);
        if (!silent) setloading(false);
      } catch (err) {
        if (!silent) setloading(false);
        return {};
      }
    };

    const subscribe = (silent = false) => {
      if (!broadcaster || !slug) {
        return;
      }

      if (subscription) {
        subscription.unsubscribe();
        refetch(silent);
      }

      subscription = API.graphql(
        graphqlOperation(subscriptionEventUpdated, { broadcaster, slug })
      ).subscribe({
        next: (eventUpdated) => {
          setEvent({
            ...eventRef.current,
            ...eventUpdated.value.data.eventUpdated,
          });
        },
        error: (err) => {
          console.log("Unsubscribed.."); // what is the error in the subscription?
          //  subscribe(); /????  with Timeout
          setTimeout(subscribe, 10000); // SILENT here
        },
      });
    };

    if (broadcaster && slug) {
      if (fetchFirst || (eventRef.current && eventRef.current.slug != slug)) {
        refetch();
      }
      subscribe();
    }

    const handleStateChange = (newState) => {
      if (newState === "active") {
        subscribe(true);
      }
    };

    const changeListener = AppState.addEventListener(
      "change",
      handleStateChange
    );

    const sub = DeviceEventEmitter.addListener("refresh_event", () => {
      setrefreshEvent(!refreshEvent);
    });

    return () => {
      // unsubscribe
      if (subscription) {
        subscription.unsubscribe();
      }
      if (changeListener) {
        changeListener.remove();
      }

      sub.remove();
    };
  }, [broadcaster, slug, refreshEvent]);

  return { event, loading, error };
};

export default useSubscribeToEventUpdates;
