
import React from "react";
import EventDetailsForm from "./EventDetailsForm";
import EventDateTimeForm from "./EventDateTimeForm";

const EventDetails = 
({
  event,
  prefer_multiple_tests,
}) => {    
    return (
        <>
            {!event.is_test && 
                <EventDateTimeForm event={event} prefer_multiple_tests={prefer_multiple_tests} />
            }
            <EventDetailsForm event={event} prefer_multiple_tests={prefer_multiple_tests} />
        </>
    )
};

export default EventDetails;