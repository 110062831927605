import * as Sharing from "expo-sharing";
import React, { useState, createContext, useContext } from "react";
import { isWindows } from "react-device-detect";
import { Platform, PlatformColor, Share } from "react-native";

import { isWeb } from "../globalUtils";
import SocialIconsModal from "../shared/SocialIconsModal";
import analytics from "../../analytics";

const shareContext = createContext();

function useProvideShare() {
  const [socialIconsVisible, setsocialIconsVisible] = useState(false);
  const [currentLink, setcurrentLink] = useState({});

  const sharePress = async ({ title, url }) => {
    const shareSubject = `You're invited to watch ${title}`;

    setcurrentLink({
      url,
      title,
      shareSubject,
    });

    // Analytics track
    analytics.track("Share Link", {
      url,
      title,
    });

    if (isWeb && (isWindows || !(await Sharing.isAvailableAsync()))) {
      setsocialIconsVisible(true);
    } else {
      try {
        if (Platform.OS === "ios") {
          await Share.share(
            {
              title: url,
              url,
            },
            {
              subject: shareSubject,
              excludedActivityTypes: [
                "com.apple.mobilenotes.SharingExtension",
                "com.apple.reminders.RemindersEditorExtension",
                "UIActivityTypeAddToReadingList",
              ],
            }
          );
        } else {
          // Android
          await Share.share(
            {
              title: shareSubject,
              message: url,
            },
            {
              dialogTitle: "Share your event link",
            }
          );
        }
      } catch (error) {
        // alert(error.message);
      }
    }
  };

  return {
    socialIconsVisible,
    setsocialIconsVisible,
    sharePress,
    currentLink,
  };
}

export function ProvideShare({ children }) {
  const share = useProvideShare();
  const { socialIconsVisible, setsocialIconsVisible, currentLink } = share;

  return (
    <shareContext.Provider value={share}>
      {children}

      {Platform.OS === "web" && (
        <SocialIconsModal
          visible={socialIconsVisible}
          {...currentLink}
          onRequestClose={() => {
            setsocialIconsVisible(false);
          }}
        />
      )}
    </shareContext.Provider>
  );
}

export const useShare = () => {
  return useContext(shareContext);
};
