import React from "react";
import { View } from "react-native";

const AppSpacing = ({ width, height, maxHeight }) => {
  return (
    <View
      style={{
        width,
        height,
        maxHeight,
        // backgroundColor: 'red'
      }}
    />
  );
};

export default AppSpacing;
