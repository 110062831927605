import { Text } from "native-base";
import React from "react";
import { StyleSheet } from "react-native";

import { isWeb } from "../globalUtils";

const styles = StyleSheet.create({
  formSubtitle: {
    color: "#2e3f52",
    fontSize: 14,
    fontWeight: "400",
    fontStyle: "normal",
    textAlign: "left",
    letterSpacing: isWeb ? "normal" : null,
    lineHeight: isWeb ? "normal" : null,
    marginTop: 15,
    marginBottom: 5,
    paddingHorizontal: 4,
    display: isWeb ? "block" : null,
  },
});

const FormSubtitle = ({ style, children }) => {
  return (
    <Text note style={[styles.formSubtitle, style]}>
      {children}
    </Text>
  );
};

export default FormSubtitle;
