import { ActionSheet, Container, Text, Content } from "native-base";
import React, { useRef, useState } from "react";
import { KeyboardAvoidingView, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";

import { useAuth } from "@/components/AuthContext";
import AppHeader from "@/components/shared/AppHeader";
import AppTitle from "@/components/shared/AppTitle";
import CreateEventForm from "@/components/shared/CreateEventForm";
import TextDisclaimer from "@/components/shared/TextDisclaimer";
import { confirmAction } from "@/components/shared/confirmAction";

const CreateEvent = ({ navigation, route }) => {
  const form = useRef();
  const { first } = route.params || {};

  const { broadcaster, credits, account_type } = useAuth();

  const onBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("EventList");
    }
  };

  const creditsText = `You have ${credits || 0} event credit${
    credits !== 1 ? "s" : ""
  }`;

  const onBackPressed = () => {
    if (form.current.isReadyToSave()) {
      confirmAction("Continue Creating Event?", "Continue", () => {}, onBack);
      // ActionSheet.show(
      //   {
      //     options: ["Continue", "Discard"],
      //     cancelButtonIndex: 1,
      //     destructiveButtonIndex: 0,
      //     title: "Continue Creating Event?",
      //   },
      //   async (buttonIndex) => {
      //     if (buttonIndex === 0) {
      //     }
      //     if (buttonIndex === 1) {
      //       onBack();
      //     }
      //   }
      // );
    } else onBack();
  };

  return (
    <Container>
      <AppHeader
        // title="Schedule"
        leftButtonComponent={
          <TouchableOpacity activeOpacity={1} onPress={onBackPressed}>
            <Ionicons name="ios-close-outline" size={30} color="#F84123" />
          </TouchableOpacity>
        }
      />
      <KeyboardAvoidingView
        behavior="padding"
        style={{ flex: 1 }}
        keyboardVerticalOffset={-80}
      >
        <Content
          padder
          contentContainerStyle={{
            alignContent: "space-around",
            flexGrow: 1,
            flex: 1,
            alignSelf: "center",
            justifyContent: "center",
            maxWidth: 800,
            width: "100%",
            padding: 20,
          }}
          style={{
            flex: 1,
          }}
        >
          <AppTitle
            text={first ? "Schedule Your First Event" : "Schedule Your Event"}
            style={{ textAlign: "center" }}
          />

          <TextDisclaimer style={{ marginBottom: 20 }}>
            You can change the details later
          </TextDisclaimer>

          {!!credits && (
            <>
              <Text style={{ marginBottom: 10 }}>{creditsText}</Text>
            </>
          )}

          <CreateEventForm ref={form} />
        </Content>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default CreateEvent;
