import { Container, View, Text } from "native-base";
import { ImageBackground } from "react-native";
import React from "react";
import OnboardingContent from "./OnboardingContent";

const SignUpContainer = ({ children }) => {
  return (
    <Container>
      <ImageBackground
        resizeMode={"cover"}
        source={{
          uri: "/hero-gradient.webp",
        }}
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
        imageStyle={{ borderRadius: 15 }}
      >
        <OnboardingContent wide>{children}</OnboardingContent>
      </ImageBackground>
    </Container>
  );
};

export default SignUpContainer;
