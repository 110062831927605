import * as Sentry from "sentry-expo";

Sentry.init({
  dsn:
    "https://c5b44dbbb8b74a8886de4e2a8de01c5f@o636155.ingest.sentry.io/5755934",
  enableInExpoDevelopment: true,
  debug: false,
  normalizeDepth: 7,
});

export default Sentry.Browser;
