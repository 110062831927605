import { Container, Button, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import AppActivityIndicator from "../../shared/AppActivityIndicator";
import { isWeb } from "../../globalUtils";
import useKeypress from "../../hooks/useKeypress";
import { fetchGuests } from "../../http/events";
import AddGuestForm from "../../shared/AddGuestForm";
import AppHeader, { onBackPressed } from "../../shared/AppHeader";
import AppSubtitle from "../../shared/AppSubtitle";
import AppTitle from "../../shared/AppTitle";
import { AppSimpleHeaderButton } from "../../shared/Buttons";
import DashboardContent from "../../shared/DashboardContent";
import TextDisclaimer from "../../shared/TextDisclaimer";

const GuestAdd = ({ navigation, route }) => {
  const [loading, setloading] = useState(true);
  const { slug } = route.params;

  const [event, setEvent] = useState({ slug });

  useEffect(() => {
    const fetchAsync = async () => {
      try {
        const myEvent = await fetchGuests(slug);
        setEvent(myEvent);
        setloading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAsync();
  }, [slug]);

  if (isWeb) useKeypress("Escape", () => onBackPressed(navigation, route));

  return (
    <Container>
      <AppHeader
        leftButtonComponent={
          <AppSimpleHeaderButton
            onPress={() => onBackPressed(navigation, route)}
            text={"Cancel"}
          />
        }
        renderRightButton={() =>
          event.stream_key && (
            <Button
              transparent
              onPress={() => {
                if (event.is_published) {
                  navigation.navigate("GuestImport", {
                    slug,
                  });
                } else {
                  navigation.navigate("PayWall", {
                    event,
                    slug,
                  });
                }
              }}
            >
              <Text>Import</Text>
            </Button>
          )
        }
      />
      <DashboardContent>
        {loading && <AppActivityIndicator />}
        {!loading && (
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              paddingHorizontal: 24,
              maxWidth: 800,
              width: "100%",
              alignSelf: "center",
            }}
          >
            <AppTitle
              text={"Invite a Guest"}
              style={{
                textAlign: "center",
              }}
            />
            <AppSubtitle
              text={"Inviting will turn on reminders for the guest"}
              style={{
                textAlign: "center",
              }}
            />
            <AddGuestForm event={event} />

            {/*   
            <TextDisclaimer>
              Tip: You can also invite by sharing your event link
            </TextDisclaimer> */}
          </View>
        )}
      </DashboardContent>
    </Container>
  );
};

export default GuestAdd;
