import React, { useEffect } from "react";

import { useAuth } from "../AuthContext";

const ChatWidget = () => {
  const { broadcaster, name, email } = useAuth();
  if (!window.$crisp) {
    window.$crisp = [];
  }
  useEffect(() => {
    if (broadcaster) {
      window.$crisp?.push(["set", "user:nickname", [name]]);
      window.$crisp?.push(["set", "user:email", email]);
      window.$crisp?.push([
        "set",
        "session:data",
        [[["username", broadcaster]]],
      ]);
      // window.$crisp?.push(["do", "chat:show"]);
    } else {
      // setTimeout(() => {
      //   window.$crisp?.push(["do", "chat:hide"]);
      // }, 1000);
    }
  }, [broadcaster]);

  return null;
};

export default ChatWidget;
