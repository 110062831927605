import { useFormik } from "formik";
import { Container, Text, View, Toast, Button, Icon } from "native-base";
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Animated, Platform, Image, BackHandler } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDebouncedCallback } from "use-debounce";
import ProgressBar from "react-native-progress/Bar";
import analytics from "@/analytics";
import * as Yup from "yup";
import { roles, eventTypes } from "@/components/globalUtils";
import { updateProfile } from "@/components/http/events";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import { timezones, makeSlug } from "@/components/globalUtils";
import { Review } from "@/components/shared/PayWall";
import AppDatePicker from "@/components/shared/AppDatePicker";
import AppPicker from "@/components/shared/AppPicker";
import moment from "moment-timezone";
import { TextLink } from "@/components/shared/TextLink";
import { validateAlias } from "@/components/screens/Schemas";
import * as WebBrowser from "expo-web-browser";

import MultiDaySelector from "@/components/shared/MultiDaySelector";

import AppInput from "@/components/shared/AppInput";
import AppRadio from "@/components/shared/AppRadio";
import AppTitle from "@/components/shared/AppTitle";
import OnboardingButton from "@/components/shared/OnboardingButton";
import SignUpContainer from "@/components/shared/SignUpContainer";
import AppBrand from "../shared/AppBrand";

function checkTitleForTestIntent(title) {
  return /test|prueba/i.test(title) && !/greatest|contest/i.test(title);
}

const images_phone_to_web = {
  funeral: require("@/assets/phone-to-web-funeral.png"),
  birthday: require("@/assets/phone-to-web-birthday.png"),
  wedding: require("@/assets/phone-to-web-wedding.png"),
  school: require("@/assets/phone-to-web-school.png"),
  sport: require("@/assets/phone-to-web-sport.png"),
  other: require("@/assets/phone-to-web-other.png"),
};
const images_make_it_your_own = {
  funeral: require("@/assets/make-it-your-own-funeral.png"),
  birthday: require("@/assets/make-it-your-own-birthday.png"),
  wedding: require("@/assets/make-it-your-own-wedding.png"),
  school: require("@/assets/make-it-your-own-school.png"),
  sport: require("@/assets/make-it-your-own-sport.png"),
  other: require("@/assets/make-it-your-own-other.png"),
};
const images_replay_download = {
  funeral: require("@/assets/replay-download-funeral.png"),
  birthday: require("@/assets/replay-download-birthday.png"),
  wedding: require("@/assets/replay-download-wedding.png"),
  school: require("@/assets/replay-download-school.png"),
  sport: require("@/assets/replay-download-sport.png"),
  other: require("@/assets/replay-download-other.png"),
};

const contentByEventType = {
  wedding: {
    tip1: {
      title: `Congratulations! 🎉`,
      text: `Your wedding day is just a click away for your remote guests. It’s almost
      as perfect as being there`,
    },
    tip2: {
      title: `Make it your own!`,
      text: "",
    },
    tip3: {
      title: `Replay and Download`,
      text: `Your video is automatically saved in FULL HD resolution. Download any time after and enjoy the replay for years to come.`,
    },
    review1: {
      text: `Compared to the $500 we had been quoted from a live streamer who just sets up on camera, this app was far better and so much cheaper. Highly recommend.`,
      author: `Bride review on the App Store`,
      date: "Aug 2, 2022",
    },
  },
  other: {
    tip1: {
      title: `Let's get started`,
      text: `Your event is just a click away for your remote guests. It’s almost as perfect as being there`,
    },
    tip2: {
      title: `Customize everything`,
      text: "",
    },
    tip3: {
      title: `Replay and Download`,
      text: `Your video is automatically saved in FULL HD resolution. Download any time after and enjoy the replay for years to come.`,
    },
    review1: {
      text: `So simple and easy to use that I would recommend it for any of your live event needs. Weddings, ceremonies of any type, even a conference. This is a simple solution that lets you share the event happening in person with people anywhere. Setup and testing makes it easy to know what you need to do on the day of your event and their team is always standing by to help! Such a fantastic product - thank you!`,
      author: `b_rowe3 on the App Store`,
      date: "Oct 17, 2021",
    },
  },

  funeral: {
    tip1: {
      title: `Our Condolences`,
      text: `Give your family and friends the opportunity to say goodbye.`,
      // image: require(`@/assets/phone-to-web-funeral.png`),
    },
    tip2: {
      title: `Personalize the Experience`,
      text: "Share memories, photos, and messages to make the service more personal.",
    },
    tip3: {
      title: `Replay and Download`,
      text: `The service is automatically saved in FULL HD resolution. Download any time after and revisit the memories whenever you wish.`,
    },
    review1: {
      text: `This worked flawlessly for livestream a celebration of life for my daughter. We had over 100 people on the day and many have watched on replay. It was easy to set up and I loved the fact that I could test it ahead of the actual event to build confidence that it would work.`,
      author: `Kipan, review on the AppStore`,
      date: "Dec 7, 2023",
    },
  },
  birthday: {
    tip1: {
      title: `Happy Birthday! 🎂`,
      text: `Celebrate your special day with friends and family, no matter where they are.`,
    },
    tip2: {
      title: `Make it a Blast!`,
      text: "Add your personal touch to the celebration with custom details",
    },
    tip3: {
      title: `Replay and Download`,
      text: `Your video is automatically saved in FULL HD resolution. Download any time after and relive the moments whenever you want.`,
    },
    review1: {
      author: `BklynMom2 review on the App Store`,
      date: `Sep 21, 2021`,
      text: `Best $ spent on a Bar Mitzvah! It was such a relief to be able to send out a custom link in an invitation and not have to worry about people connecting via an app or having to sign in somewhere. We streamed for 70 minutes to 36 viewers with no outages and then guests who couldn’t attend live were able to watch later. I easily downloaded the 1gb video and guestbook messages for posterity. This is definitely the way to go for $50.`,
    },
  },
  sport: {
    tip1: {
      title: `Game On! 🏀`,
      text: `Bring the excitement of the game to fans everywhere.`,
    },
    tip2: {
      title: `Personalize the experience`,
      text: "",
    },
    tip3: {
      title: `Replay and Download`,
      text: `The game is automatically saved in FULL HD resolution. Download any time after and relive the best moments whenever you want.`,
    },
    review1: {
      text: ` Greet affordable easy to use option for streaming an event`,
      author: `TheKozo on the App Store`,
      date: "Feb 27, 2023",
    },
  },
  school: {
    tip1: {
      title: `Let’s get started!  🎒`,
      text: `Go live in 5 minutes, and share it with those who matter.`,
    },
    tip2: {
      title: `Personalize the Experience`,
      text: "Add links, upload documents, write a welcome message and much more to customize the event",
    },
    tip3: {
      title: `Replay and Download`,
      text: `The video is automatically saved in FULL HD resolution. Download any time after to revisit whenever you want.`,
    },

    review1: {
      text: `Just started using EventLive about a week ago and it has been incredible! It’s so easy to use and full of features that is lacking in many other apps in this category. The price per live-stream is also very affordable. I evaluated 5 other live-stream platforms and this is by far the best.`,
      author: `K. Stockham on the App Store`,
      date: "Feb 15, 2022",
    },
  },
};

export const OnboardingSchema = Yup.object().shape({
  account_type: Yup.string().oneOf(["individual", "business", "watch"]),
  broadcaster_role: Yup.string().oneOf(roles.map((r) => r.value)),
  event_type: Yup.string().oneOf(eventTypes.map((r) => r.value)),
  role_other: Yup.string(),
  company_name: Yup.string(),
  company_url: Yup.string(),
  title: Yup.string(),
  event_datetime_utc: Yup.date(),
  event_timezone: Yup.string(),
  days: Yup.number()
    .typeError("Please enter number of days")
    .integer("Please enter number of days")
    .min(1, "At least 1 day")
    .max(7, "7 days max"),
  alias: Yup.string().nullable(),
  // .test("checkAvailability", "Sorry, Already Taken", validateAlias)
  // .matches(
  //   /^[\d\w-_]+$/,
  //   "Only letters, numbers, dashes and underscore allowed."
  // ),
});

export const Start = ({
  title = "Your all\u2011in\u2011one event live streaming app", // welcome to
}) => {
  return (
    <>
      <AppTitle
        text={title}
        style={{
          marginTop: 60,
          marginBottom: 20,
          textAlign: "center",
          fontSize: 48,
        }}
      />

      <Text style={{ textAlign: "center", fontSize: 18, color: "#2e3f52" }}>
        24,000+ events streamed
      </Text>
      <Text style={{ textAlign: "center", padding: 5 }}>
        ⭐️⭐️⭐️⭐️⭐️ 500+
      </Text>
    </>
  );
};
export const AccountTypeForm = ({
  f,
  title,
  individualLabel = "A personal event",
  businessLabel = "Events for my business",
}) => {
  return (
    <>
      {title && (
        <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />
      )}

      <AppRadio
        highlight
        selected={f.values.account_type === "individual"}
        onSelect={() => f.setFieldValue("account_type", "individual")}
        label={individualLabel}
      />

      <AppRadio
        highlight
        selected={f.values.account_type === "business"}
        onSelect={() => f.setFieldValue("account_type", "business")}
        label={businessLabel}
      />

      <AppRadio
        highlight
        selected={f.values.account_type === "watch"}
        onSelect={() => f.setFieldValue("account_type", "watch")}
        label={"I want to attend an event"}
      />
    </>
  );
};

export const BusinessRoleForm = ({
  f,
  title = "Which role describes you best?",
}) => (
  <>
    <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />

    {roles.map(({ value, label }) => (
      <AppRadio
        highlight
        key={value}
        selected={f.values.broadcaster_role === value}
        onSelect={() => f.setFieldValue("broadcaster_role", value)}
        label={label}
      />
    ))}
    {/* 
    {f.values.broadcaster_role === "other" && (
      <AppInput
        style={{ width: "100%", marginBottom: 23 }}
        onChangeText={f.handleChange("role_other")}
        onBlur={f.handleBlur("role_other")}
        value={f.values.role_other}
        placeholder="Please Specify"
        onSubmitOnEnter={() => f.handleSubmit()}
        errorMessage={
          f.errors.role_other && f.touched.role_other
            ? f.errors.role_other
            : null
        }
      />
    )} */}
  </>
);

export const EventTypeForm = ({
  f,
  title = "What kind of event would you like to stream?",
}) => (
  <>
    <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />

    {eventTypes.map(({ value, label, icon }) => (
      <AppRadio
        highlight
        key={value}
        selected={f.values.event_type === value}
        onSelect={() => f.setFieldValue("event_type", value)}
        label={label}
        icon={icon}
      />
    ))}
  </>
);

export const InfoSlide = ({ title, image, text, imageHeight = 240 }) => (
  <>
    <AppTitle
      text={title}
      style={{ marginBottom: 0, textAlign: "center", fontSize: 36 }}
    />
    <Image
      source={image}
      resizeMode="contain"
      style={{
        width: "100%",
        height: imageHeight,
        marginTop: 10,
      }}
    />
    <Text
      style={{
        textAlign: "center",
        marginTop: 20,
        fontSize: 18,
        lineHeight: 26,
      }}
    >
      {text}
    </Text>
  </>
);

export const EventTitleForm = ({
  f,
  title = "Title of Your Event",
  emptytitle,
  navigation,
}) => {
  return (
    <>
      <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />

      <AppInput
        onChangeText={(v) => {
          f.setFieldValue("alias", makeSlug(v), false);

          f.handleChange("title")(v);
        }}
        onBlur={f.handleBlur("title")}
        value={f.values.title}
        autoFocus
        placeholder="Event Title *"
        onSubmitOnEnter={() => f.handleSubmit()}
        returnKeyType="next"
        returnKeyLabel="Continue"
        enablesReturnKeyAutomatically={true}
        onSubmitEditing={f.handleSubmit}
        errorMessage={f.errors.title && f.touched.title ? f.errors.title : null}
      />

      {(!f.values.title || checkTitleForTestIntent(f.values.title)) && (
        <View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            {checkTitleForTestIntent(f.values.title) ? (
              <Text>We've noticed "test" in your event title.</Text>
            ) : null}

            <Text>Don't have an event scheduled yet? No problem.</Text>
            <TextLink
              text="Try live streaming now, Free"
              style={{ marginTop: 10 }}
              onPress={async () => {
                await AsyncStorage.setItem(
                  "onboardingData",
                  JSON.stringify({ ...f.values, skipEventCreation: true })
                );
                navigation.push("SignUpSignIn", { skipEventCreation: true });
              }}
            />
          </View>
        </View>
      )}
    </>
  );
};

export const EventDateTimeForm = ({ f, title = "Date and Time" }) => {
  const updateTimeZone = useMemo(() => f.handleChange("event_timezone"), []);
  const [customizeTZ, setcustomizeTZ] = useState(false);
  return (
    <>
      <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />

      <Text style={{ marginBottom: 20 }}>
        Schedule your event now for any date in the future! You can go live 1
        hour before this time.
      </Text>

      <AppDatePicker
        //moment( ).toDate()  in selected and selectedValue used to work for WEB
        selected={moment(f.values.event_datetime_utc).toDate()}
        selectedValue={moment(f.values.event_datetime_utc).toDate()}
        onChange={(value) => {
          f.setFieldValue("event_datetime_utc", moment(value).toISOString());
        }}
        popperPlacement="top"
        onBlur={f.handleBlur("event_datetime_utc")}
        placeholderText="Event Date and Time *"
        errorMessage={
          f.errors.event_datetime_utc && f.touched.event_datetime_utc
            ? "Please provide event's local Date and Time"
            : null
        }
      />

      {!customizeTZ && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            marginVertical: 10,
          }}
        >
          <Text>Timezone: {f.values.event_timezone} </Text>
          <TextLink
            text={"Change"}
            onPress={() => {
              setcustomizeTZ(true);
            }}
            textStyle={{}}
          />
        </View>
      )}

      {customizeTZ && (
        <AppPicker
          label="Timezone"
          placeholder="Time Zone"
          values={timezones}
          selectedValue={f.values.event_timezone}
          onValueChange={updateTimeZone}
        />
      )}

      <MultiDaySelector f={f} questionStyle={{ textAlign: "right" }} />
    </>
  );
};

export const EventLinkForm = ({ f, title = "Your Event Page Link" }) => {
  const [linkStatus, setLinkStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const aliasPattern = /^[\d\w-_]+$/;
  const firstCheck = useRef(true);

  const checkAvailability = async () => {
    let isAvailable = false;
    setLoading(true);

    if (!aliasPattern.test(f.values.alias)) {
      setLinkStatus(false);
    } else {
      isAvailable = await validateAlias(f.values.alias);
      if (!isAvailable && firstCheck.current) {
        f.values.alias += Math.floor(1000 + Math.random() * 9000);
        isAvailable = true;
        firstCheck.current = false;
      }
      setLinkStatus(isAvailable);
    }
    setLoading(false);
    return isAvailable;
  };

  const debouncedCheckLinkAvailability = useDebouncedCallback(
    checkAvailability,
    500
  );

  useEffect(() => {
    if (f.values.alias) {
      debouncedCheckLinkAvailability();
    }
  }, [f.values.alias]);

  const i = useRef(null);

  return (
    <>
      <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />
      {/* One click is all they need to watch – wherever they are. */}
      <Text style={{ marginBottom: 20 }}>
        Your event link is all you need to invite the guests. Text it, email it,
        print on your invitations.
      </Text>
      <AppInput
        onChangeText={f.handleChange("alias")}
        onBlur={f.handleBlur("alias")}
        value={f.values.alias}
        placeholder={`your-event-link`}
        label="evt.live/"
        labelStyle={{
          textAlign: "right",
          paddingRight: 0,
          fontWeight: "bold",
          paddingTop: Platform.OS === "web" ? 0 : 3,
          color: "#3f5b7a",
        }}
        inputStyle={{ paddingLeft: 0 }}
        autoCapitalize="none"
        iconRightName={"create"}
        // onIconRightAction={() => i.current.focus()}
        // ref={(input) => {
        //   console.log("REF", input);
        //   i.current = input;
        // }}
      />
      <View
        style={{
          textAlign: "center",
          padding: 40,
          borderRadius: 16,
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center", // Added this line to center the content
          backgroundColor: loading
            ? undefined
            : linkStatus
            ? "rgba(0,255,0,0.4)"
            : "rgba(255,0,0,0.4)",
        }}
      >
        {loading ? (
          <AppActivityIndicator />
        ) : linkStatus ? (
          <Text>Your link is available!</Text>
        ) : !aliasPattern.test(f.values.alias) ? (
          <Text>
            Only english letters, numbers, dashes and underscore allowed.
          </Text>
        ) : (
          <Text>Sorry, this link is taken. Try adding a few characters</Text>
        )}
      </View>
    </>
  );
};

export const BusinessPersonalizeForm = ({
  f,
  title = "Personalize your event pages",
}) => (
  <>
    {title && (
      <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />
    )}

    <AppInput
      onChangeText={f.handleChange("company_name")}
      onBlur={f.handleBlur("company_name")}
      value={f.values.company_name}
      placeholder="Company Name"
      onSubmitOnEnter={() => f.handleSubmit()}
      errorMessage={
        f.errors.company_name && f.touched.company_name
          ? f.errors.company_name
          : null
      }
    />

    <AppInput
      onChangeText={f.handleChange("company_url")}
      onBlur={f.handleBlur("company_url")}
      value={f.values.company_url}
      placeholder="Website (optional)"
      onSubmitOnEnter={() => f.handleSubmit()}
      errorMessage={
        f.errors.company_url && f.touched.company_url
          ? f.errors.company_url
          : null
      }
    />

    <Text style={{ marginTop: 20 }}>
      You can upload your logo in profile settings
    </Text>
  </>
);

export const CreatingEventSlide = ({ f, after, text, author, date }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      after();
    }, 5000);
    return () => clearTimeout(timer);
  }, [f, after]);

  return (
    <>
      <AppTitle
        text={`Creating ‘${f.values.title}’ event`}
        style={{ marginTop: 40, marginBottom: 20 }}
      />
      <AppActivityIndicator />
      {text && author && (
        <Review
          rating={5}
          // title="Awesome!"
          text={text}
          author={author}
          date={date}
        />
      )}
    </>
  );
};

const OnboardingNew = ({ navigation, route }) => {
  const {
    first_name,
    account_type,
    company_name,
    company_url,
    broadcaster_role,
    event_type,
    step,
    // finishOnboarding,
  } = route?.params || {};

  const setStep = (s, params) => {
    if (Platform.OS === "web") {
      // navigation.setParams({ step: s, ...params });
      navigation.navigate("OnboardingNew", { step: s, ...params });
    } else {
      navigation.setParams({ step: s, ...params });
    }
    // navigation.navigate("OnboardingNew", { step: s, ...params });
    // } else {
    //   navigation.push("OnboardingNew", { step: s, ...params });
    // }
  };

  const goBack = useCallback(() => {
    switch (step) {
      case "account_type":
        return setStep("start");
      case "event_type":
        return setStep("account_type");
      case "event_link":
        return setStep("event_datetime");
      case "event_datetime":
        return setStep("event_title");
      case "event_title":
        return setStep("b2c_intro1");
      case "event_link":
        return setStep("event_datetime");
      case "b2c_intro1":
        return setStep("event_type");
      case "b2c_intro3":
        return setStep("event_datetime");
      case "business_role":
        return setStep("account_type");
      case "b2c_intro_download":
        return setStep("event_link");
      case "b2c_intro3":
        return setStep("b2c_intro_download");
      case "business_personalize":
        return setStep("business_role");
      case "creating_event":
        return setStep("b2c_intro_download");
    }
  }, [step]);

  const [initialValues, setInitialValues] = useState({
    account_type: "individual",
    company_name: "",
    company_url: "",
    event_type: "wedding",
    broadcaster_role: "",
    event_timezone: moment.tz.guess(),
    event_datetime_utc: moment(new Date()).toISOString().split(".")[0] + "Z", //new Date(),
    title: "",
    days: 1,
    role_other: "",
    event_name: "",
    alias: "",
  });

  useEffect(() => {
    const fetchInitialValues = async () => {
      const storedValues = await AsyncStorage.getItem("onboardingData");
      if (storedValues) {
        setInitialValues(JSON.parse(storedValues));
      }
    };
    fetchInitialValues();
  }, []);

  useEffect(() => {
    const backAction = () => {
      goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, [step]);

  const f = useFormik({
    initialValues: initialValues,
    validationSchema: OnboardingSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      // Trim whitespace in title
      values.title = values.title.trim();
      // Convert alias to lowercase
      values.alias = values.alias.toLowerCase();

      if (
        step === "event_datetime" &&
        new Date(values.event_datetime_utc).setHours(0, 0, 0, 0) <
          new Date().setHours(0, 0, 0, 0)
      ) {
        actions.setSubmitting(false);
        actions.setStatus({ errorMsg: "Date is in the past" });
        Toast.show({
          text: "The chosen event date is in the past. Please select today, or a future date.",
          position: "top",
          type: "danger",
          duration: 5000,
        });
        return;
      }

      if (step === "event_link") {
        const aliasPattern = /^[\d\w-_]+$/;
        if (!f.values.alias || !aliasPattern.test(f.values.alias)) {
          actions.setSubmitting(false);
          actions.setStatus({ errorMsg: "Invalid link format" });
          Toast.show({
            text: "Link must be non-empty and can only contain letters, numbers, dashes and underscores. Please choose another one.",
            position: "top",
            type: "danger",
            duration: 5000,
          });
          return;
        }

        const isAvailable = await validateAlias(f.values.alias);
        if (!isAvailable) {
          actions.setSubmitting(false);
          actions.setStatus({ errorMsg: "Link is taken" });
          Toast.show({
            text: "Sorry, this link is taken. Please choose another one.",
            position: "top",
            type: "danger",
            duration: 5000,
          });
          return;
        }
      }

      // console.log(step, JSON.stringify(values));
      actions.setSubmitting(true);
      await AsyncStorage.setItem("onboardingData", JSON.stringify(values));

      if (values.account_type) {
        analytics.identifyAnon(values);
      }

      await next(values);

      try {
        // FINAL ACTION:
        //   await updateProfile(values);
        //   await finishOnboarding(values);
        //   navigation.navigate("LetsSchedule");
      } catch (err) {
        actions.setSubmitting(false);
        actions.setStatus({ errorMsg: err.message });

        Toast.show({
          text: err.message,
          position: "top",
          type: "danger",
          duration: 5000,
        });
      }
    },
  });

  const next = async (values) => {
    switch (step) {
      case "account_type": {
        switch (values.account_type) {
          case "business":
            return setStep("business_role");
          case "individual":
            return setStep("event_type");
          case "watch": {
            analytics.track("Watch Event Intent", {});
            if (Platform.OS !== "web") {
              await WebBrowser.openBrowserAsync("https://evt.live");
            } else {
              window.open("https://evt.live", "_blank");
            }
          }
        }
      }
      case "event_type":
        return setStep("b2c_intro1");
      case "b2c_intro1":
        return setStep("event_title");
      case "event_title":
        if (!values.title || checkTitleForTestIntent(values.title)) {
          return setStep("event_title", { emptytitle: true });
        } else {
          return setStep("event_datetime");
        }

      case "event_datetime":
        return setStep("b2c_intro3");
      case "b2c_intro3":
        return setStep("event_link");
      case "business_role":
        return setStep("business_personalize");
      case "event_link":
        return setStep("b2c_intro_download");
      case "b2c_intro_download":
        return setStep("creating_event");
      case "business_personalize":
      case "creating_event":
        setStep("b2c_intro_download");
        return navigation.navigate("SignUpSignIn");
      default:
        return setStep("account_type");
    }
  };

  const renderStep = (f) => {
    const event_type = f.values["event_type"] || "other";

    switch (step) {
      default:
      case "start":
        return <Start />;

      case "account_type":
        return (
          <AccountTypeForm
            f={f}
            title={`What would you like to live stream${
              first_name ? `, ` + first_name : ``
            }?`}
          />
        );
      case "business_role":
        return <BusinessRoleForm f={f} />;
      case "event_type":
        return <EventTypeForm f={f} />;
      case "b2c_intro1":
        return (
          <InfoSlide
            title={contentByEventType[event_type]?.tip1?.title}
            image={images_phone_to_web[event_type]}
            text={contentByEventType[event_type]?.tip1?.text}
          />
        );
      case "b2c_intro3":
        return (
          <InfoSlide
            title={contentByEventType[event_type]?.tip2?.title}
            image={images_make_it_your_own[event_type]}
            text={contentByEventType[event_type]?.tip2?.text}
            imageHeight={320}
          />
        );
      case "b2c_intro_download":
        return (
          <InfoSlide
            title={contentByEventType[event_type]?.tip3?.title}
            image={images_replay_download[event_type]}
            text={contentByEventType[event_type]?.tip3?.text}
          />
        );
      case "event_title":
        return (
          <EventTitleForm f={f} {...route?.params} navigation={navigation} />
        );
      case "event_link":
        return <EventLinkForm f={f} />;
      case "event_datetime":
        return <EventDateTimeForm f={f} />;
      case "business_personalize":
        return <BusinessPersonalizeForm f={f} />;
      case "creating_event":
        return (
          <CreatingEventSlide
            f={f}
            after={next}
            text={contentByEventType[event_type]?.review1?.text}
            author={contentByEventType[event_type]?.review1?.author}
            date={contentByEventType[event_type]?.review1?.date}
          />
        );
    }
  };

  const progressForStep = {
    account_type: 0.15,
    event_type: 0.36,
    b2c_intro1: 0.38,
    event_title: 0.57,
    event_datetime: 0.74,
    b2c_intro3: 0.78,
    event_link: 0.8,
    b2c_intro_download: 0.95,
    creating_event: 0.99,

    business_role: 0.75,

    business_personalize: 0.95,
  };

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();
  }, [step]);

  return (
    <Container
      onTouchStart={(e) => {
        if (Platform.OS === "ios") {
          this.touchX = e.nativeEvent.pageX;
        }
      }}
      onTouchEnd={(e) => {
        if (Platform.OS === "ios" && e.nativeEvent.pageX - this.touchX > 70)
          goBack();
      }}
    >
      <SignUpContainer>
        <View
          style={
            {
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center",
            }
          }
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            {/* {Platform.OS !== "web" && step && (
              <Button
                transparent
                onPress={goBack}
                style={{ flex: 0, padding: 0, margin: 0 }}
              >
                <Icon
                  name="arrow-back"
                  style={{ flex: 0, padding: 0, margin: 0 }}
                />
              </Button>
            )} */}
            <AppBrand style={{ marginBottom: 20 }} />
          </View>

          <ProgressBar
            progress={progressForStep[step] || 0.1}
            color="#4ec8ff"
            // style={{ flex: 1 }}
            width={null}
          />
        </View>
        <View
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Animated.View style={{ opacity: fadeAnim }}>
            {renderStep(f)}
          </Animated.View>
        </View>
        {!["creating_event"].includes(step) && (
          <OnboardingButton
            onPress={f.handleSubmit}
            disabled={f.isSubmitting}
            text={"Continue"}
          />
        )}
        {!step && (
          <TextLink
            text="I already have an account"
            onPress={() => {
              navigation.navigate("SignUpSignIn");
            }}
            textStyle={{ margin: 20, textAlign: "center" }}
          />
        )}
      </SignUpContainer>
    </Container>
  );
};

export default OnboardingNew;
