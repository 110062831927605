import { useFormik } from "formik";
import { Container, Text, View, Toast } from "native-base";
import React, { useEffect, useState } from "react";

import { useAuth } from "@/components/AuthContext";
import { roles } from "@/components/globalUtils";
import { updateProfile } from "@/components/http/events";
import AppActivityIndicator from "@/components/shared/AppActivityIndicator";
import AppBrand from "@/components/shared/AppBrand";
import AppInput from "@/components/shared/AppInput";
import AppRadio from "@/components/shared/AppRadio";
import AppTitle from "@/components/shared/AppTitle";
import OnboardingButton from "@/components/shared/OnboardingButton";
import OnboardingContent from "@/components/shared/OnboardingContent";
import { SegmentationSchema } from "@/components/screens/Schemas";

export const Step1 = ({
  f,
  title,
  individualLabel = "A personal event",
  businessLabel = "Events for my business",
}) => {
  return (
    <>
      {title && (
        <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />
      )}

      <AppRadio
        selected={f.values.account_type === "individual"}
        onSelect={() => f.setFieldValue("account_type", "individual")}
        label={individualLabel}
      />

      <AppRadio
        selected={f.values.account_type === "business"}
        onSelect={() => f.setFieldValue("account_type", "business")}
        label={businessLabel}
      />
    </>
  );
};

export const Step2 = ({
  f,
  title = "Sounds great! Which role describes you best?",
}) => (
  <>
    <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />

    {roles.map(({ value, label }) => (
      <AppRadio
        key={value}
        selected={f.values.broadcaster_role === value}
        onSelect={() => f.setFieldValue("broadcaster_role", value)}
        label={label}
      />
    ))}

    {f.values.broadcaster_role === "other" && (
      <AppInput
        style={{ width: "100%", marginBottom: 23 }}
        onChangeText={f.handleChange("role_other")}
        onBlur={f.handleBlur("role_other")}
        value={f.values.role_other}
        placeholder="Please Specify"
        onSubmitOnEnter={() => f.handleSubmit()}
        errorMessage={
          f.errors.role_other && f.touched.role_other
            ? f.errors.role_other
            : null
        }
      />
    )}
  </>
);

export const Step3 = ({ f, title = "Personalize your event pages" }) => (
  <>
    {title && (
      <AppTitle text={title} style={{ marginTop: 40, marginBottom: 20 }} />
    )}

    <AppInput
      onChangeText={f.handleChange("company_name")}
      onBlur={f.handleBlur("company_name")}
      value={f.values.company_name}
      placeholder="Company Name"
      onSubmitOnEnter={() => f.handleSubmit()}
      errorMessage={
        f.errors.company_name && f.touched.company_name
          ? f.errors.company_name
          : null
      }
    />

    <AppInput
      onChangeText={f.handleChange("company_url")}
      onBlur={f.handleBlur("company_url")}
      value={f.values.company_url}
      placeholder="Website (optional)"
      onSubmitOnEnter={() => f.handleSubmit()}
      errorMessage={
        f.errors.company_url && f.touched.company_url
          ? f.errors.company_url
          : null
      }
    />

    <Text style={{ marginTop: 20 }}>
      You can upload your logo in profile settings
    </Text>
  </>
);

const Form = ({
  first_name,
  account_type,
  company_name,
  company_url,
  broadcaster_role,
  finishOnboarding,
  navigation,
}) => {
  let firstStep = 0;

  if (account_type === "business") {
    if (broadcaster_role) {
      firstStep = 2;
    } else {
      firstStep = 1;
    }
  }
  const [step, setStep] = useState(firstStep);

  const f = useFormik({
    initialValues: {
      account_type: account_type || "individual",
      company_name: company_name || "",
      company_url: company_url || "",
      broadcaster_role: broadcaster_role || "",
      role_other: "",
    },
    validationSchema: SegmentationSchema,
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        if (step === 0 && values.account_type === "business") {
          setStep(1);
        }

        if (step === 1) {
          setStep(2);
        }

        if (step === 2 || values.account_type === "individual") {
          await updateProfile(values);
          await finishOnboarding(values);
          navigation.navigate("LetsSchedule");
        }
      } catch (err) {
        actions.setSubmitting(false);
        actions.setStatus({ errorMsg: err.message });

        Toast.show({
          text: err.message,
          position: "top",
          type: "danger",
          duration: 5000,
        });
      }
    },
  });

  const renderStep = (f) => {
    switch (step) {
      default:
        return (
          <Step1
            f={f}
            title={`What would you like to live stream${
              first_name ? `, ` + first_name : ``
            }?`}
          />
        );
      case 1:
        return <Step2 f={f} />;
      case 2:
        return <Step3 f={f} />;
    }
  };

  return (
    <>
      {renderStep(f)}

      <OnboardingButton
        onPress={f.handleSubmit}
        disabled={f.isSubmitting}
        text={"Next"}
      />
    </>
  );
};

const SegmentationForm = ({ navigation }) => {
  const {
    first_name,
    account_type,
    company_name,
    company_url,
    broadcaster_role,
    finishOnboarding,
  } = useAuth();

  return (
    <Container>
      <OnboardingContent>
        <AppBrand />
        <View>
          {account_type && ( // waiting to load
            <Form
              first_name={first_name}
              account_type={account_type}
              company_name={company_name}
              company_url={company_url}
              broadcaster_role={broadcaster_role}
              finishOnboarding={finishOnboarding}
              navigation={navigation}
            />
          )}
          {!account_type && <AppActivityIndicator />}
        </View>
      </OnboardingContent>
    </Container>
  );
};

export default SegmentationForm;
